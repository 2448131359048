@import '../../../assets/styles/variables';

.scrollable-box-component {
	.scrollable-content{
		width: calc(100% - 1.4em);
		height:  calc(100% - 2em);
		overflow-y: scroll;
		margin: 1em 0.2em 1em 1em;
	}

	&.selected{
		.pulsar-component {
			display: block;
		}
	}

	&.scrollVisible {
		::-webkit-scrollbar {
			visibility: visible;
			height: 3em;
			width: 0.3em;
			background: #122640;
			border: 0.04em solid rgba(0, 0, 0, 0);
			border-radius: 0.3em;
		}
		::-webkit-scrollbar-thumb {
			background: #21426A;
			border: 0.04em solid rgba(0, 0, 0, 0);
			border-radius: 0.3em;
		}

		&.active{
			::-webkit-scrollbar {
				background: #183354;
			}
			::-webkit-scrollbar-thumb {
				background: #295081;
			}
		}
	}
}
