.common-button{
	&.with-epic-games {
		padding: 0.4em 0.4em 0.3em;
		background-color:  rgb(53, 53, 53);
		transform: skew(0);
		width: auto;
		color: white;

        &.inverted {
            background-color: white;
            color: black;
        }

		.content {
			display: inline-flex;
			align-items: center;
			height: auto;
			transform: skew(0);
			font-family: "Helvetica", "Arial", sans-serif;


				.epic-games-image {
					padding-right: 0.4em;
					width: auto;
					height: 1.5em;
				}

			.text-container{
				padding: 0 0.3em;
			}
		}

		&.bounce-animation {
			animation: button-bounce-frames-epic-games 150ms;
		}

		@keyframes button-bounce-frames-epic-games {
			0% { transform: scale(1); }
			50% { transform: scale(0.9); }
			100% { transform: scale(1); }
		}

	}
}


