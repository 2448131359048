@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.tournaments-info-component {
	height: calc(100% - 2em);
	padding: 0 1.1em 0 1.6em;
	display: flex;

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes fadeOutOpacity {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	.content-left {
		position: relative;
		flex-grow: 1;
		.top-container{
			.sub-description{
				width: 24em;
			}
		}

		.bottom-container {
			bottom: 0;
			left: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;

			//position: absolute;
			//width: 100%;

			position: fixed;
			padding-left: 1.6em;
			padding-bottom: 1.6em;
			width: calc(100% - 20em);

			.bottom-left-container {
				.buttons-container {
					display: flex;
					flex-direction: row;
					font-family: 'LatoBold';
					align-items: flex-end;
					.play-button.hidden {
						display: none;
					}
					.common-button {
						height: 3em;
						width: 9em;
						margin-right: 1.3em;

						&:first-of-type {
							margin-left: 0.3em;
						}
					}
					.leaderboard-position-component{
						font-size: 0.75em;
					}
				}
			}

			.trophy-container {
				position: relative;
				display: flex;
				margin-left: 1em;
				width: 50%;
				&-animation {
					position: absolute;
					top: -0.55em;
					left: -4.35em;
					width: 18em;

					animation-delay: 0.5s;
					animation-name: fadeOutOpacity;
					animation-timing-function: ease-in;
					animation-duration: 0.5s;
					animation-fill-mode: forwards;
				}

				.gem-animation {
					top: 0.3em;
					left: 5em;
					position: absolute;
				}

				.gem-animation-image {
					width: 1.5em;
					height: 1.5em;
					animation:
						gems-position-anim-tournaments 0.5s linear,
						gems-fade-anim-tournaments 0.3s linear;
				}

				@keyframes gems-position-anim-tournaments {
					30%{
						transform: translate(0,0);
					}
					100%{
						transform: translateY(0);
					}
				}

				@keyframes gems-fade-anim-tournaments {
					0%  {opacity: 1;}
					70% {opacity: 0;}
				}

				.trophy-icon {
					position: relative;
					background: url("../../../assets/images/global/icon-challenge-trophy-gems.png") no-repeat;
					background-size: contain;
					height: 5.5em;
					width: 8.3em;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-end;
					z-index: 1;

					&.animation {
						opacity: 0;
						animation-delay: 0.5s;
						animation-name: fadeInOpacity;
						animation-timing-function: ease-in;
						animation-duration: 0.3s;
						animation-fill-mode: forwards;
					}

					&-score {
						font-family: 'LatoBold';
						font-size: 1em;
						padding-bottom: 0.85em;
						color: #624C14;
						text-align: center;

						&.animation {
							opacity: 0;
							animation-delay: 0.7s;
							animation-name: fadeInOpacity;
							animation-timing-function: ease-out;
							animation-duration: 0.3s;
							animation-fill-mode: forwards;
						}

						.label {
							color: #AD00FF;
							font-size: 0.8em;
							line-height: 1.5em;
						}
					}
				}
			}
		}
	}

	.content-right {
		position: relative;
		height: 100%;
    	display: flex;
    	align-items: flex-end;
		min-width: 20em;

		.image-container {
			.countdown-component {
				left: auto;
				bottom: auto;
				top:auto;
			}
			.tournament-image {
				width: 20em;
				height: 100%;
				border-top-left-radius: 3em;
			}
			&--clickable {
				cursor: pointer;
			}
		}
	}

	&__description {
		margin-right: 2em;
	}

	&.finished-state {
		.content-left{
			.bottom-container{
				.bottom-left-container{
					.gem {
						display: flex;
						align-items: center;
						color: #DB23AD;
						font-weight: bold;
						font-size: 2.3em;
						margin-bottom: 0.3em;

						img {
							margin-left: -0.25em;
							margin-right: 0.2em;
							width: 1.7em;
							height: 1.7em;
						}
					}

					.leaderboard-position-component {
						.icon{
							width: 3em;
							margin-right: 0.35em;
						}
					}

					.score {
						color: #FFF;
						font-weight: bold;

						.title {
							font-size: 1em;
						}

						.number {
							font-size: 2.5em;
							margin-left: 0.3em;
						}
					}
				}

				.trophy-container {
					flex-direction: column;
					align-items: center;
					width: 50%;

					.badge-container{
						margin-bottom: 0.8em;
						margin-left: 0.4em;

						>img{
							width: 5em;
							height: auto;
						}
					}
				}
			}
		}
	}

	&.finished-spectator-state {
		.content-left{

			.top-container {
				position: relative;
				z-index: 1;
			}
			.bottom-container{
				flex-direction: row;

				.podium-container {
					position: relative;
					width: 100%;
					height: 20em;
					text-align: center;
					margin-right: 1em;
					>img {
						display: block;
						position: absolute;
						right: 3.5em;
						bottom: 0;
						width: 16.5em;
						height: auto;
					}

					.podium-item {
						position: absolute;
						width: 4.8em;
						height: 7.2em;

						.avatar-container {
							margin: 0 auto;
							width: 4.4em;
							height: 4.4em;
							margin-bottom: 0.2em;
							.avatar-image-container{
								border: 0.2em #004082 solid ;
							}
						}

						.name {
							display: block;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							font-weight: bold;
							line-height: 1.1em;
							font-family: "Lato", sans-serif;
							color: #89969F;
						}

						.score{
							font-family: "Lato", sans-serif;
							font-weight: normal;
							color: #6e7881;
						}

						&.rank-1{
							left: 9.9em;
							bottom: 10.2em;
						}
						&.rank-2{
							left: 4.1em;
							bottom: 7.7em;
						}
						&.rank-3{
							left: 15.7em;
							bottom: 5.2em;
						}

						&.twitch{
							.avatar-container {
								.avatar-image-container{
									border: 0.2em #6441A4 solid ;
								}
							}
						}

						&.selected{
							.name{
								color:white;
							}
							.score{
								color:white;
							}

							.avatar-container {
								.avatar-image-container{
									border: 0.2em @font-color-outline-yellow solid ;
								}
							}
						}
					}
				}

				&.global{//add badges only for the global list
					.podium-container {
						.podium-item {
							&:after{
								content: "";
								display: block;
								position: absolute;
								top: -1.4em;
								left: 1.4em;
								width: 2em;
								height: 2em;
							}

							&.rank-1{
								&:after{
									background: url("../../../assets/images/generic-icons/M-tournament_trophy-generic-gold.png");
									background-size: contain;
									background-repeat: no-repeat;
								}
							}
							&.rank-2{
								&:after{
									background: url("../../../assets/images/generic-icons/M-tournament_trophy-generic-silver.png");
									background-size: contain;
									background-repeat: no-repeat;
								}
							}
							&.rank-3{
								&:after{
									background: url("../../../assets/images/generic-icons/M-tournament_trophy-generic-bronze.png");
									background-size: contain;
									background-repeat: no-repeat;
								}
							}
						}
					}

				}
			}
		}
	}

	@keyframes button-bounce-frames-leaderboard {
		0% { transform: scale(1); }
		50% { transform: scale(0.9); }
		100% { transform: scale(1); }
	}
}