.hero-banner {
    position: relative;
    height: 12.5em;

    //Fixes chromium v54(waysun) image issue
    border: 1px solid transparent;

    &.selectable {
        cursor: pointer;  
    }

    &.selected{
		.pulsar-component {
			display: block;
		}
	}
}