@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.generic-container {
    position: relative;
	padding: 1em 0.7em 0.8em;
    min-height: 15em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
	height: 100%;
	width: 100%;

	.title-text {
		text-align: center;
		width: 100%;
		font-family: 'Quantify-Bold';
		letter-spacing: 0.05em;
		font-size: 1.7em;
		color: @font-color-title;
		text-shadow: 0 0 0.6em @font-color-text-shadow;
	}

	.message-text {
		width: 100%;
		text-align: center;
		text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		color: @font-color-title;
		padding: 0.7em 0.7em;
		&.large-text {
			font-size: 1.2em;
		}

		&.medium-text {
			font-size: 1em;
		}

		&.small-text {
			font-size: 0.8em;
		}
	}

	.buttons-container {
		width: 100%;
		padding-top: 0.7em;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

		&.flex-end {
			justify-content: flex-end;
		}
		.common-button {
			padding: 0.5em 1em;
			width: auto;
            margin-right: 1em;
			&.fixedWidth {
				min-width: 10em;
			}
            &:last-child{
                margin-right: 1em;
            }
            &:first-child{
                margin-left: 1em;
            }	
		}
	}
}

.popup-container {
	&:before {
		.popup-background-mixing;
	}
}