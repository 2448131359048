@import '../../assets/styles/variables';

.user-profile-stats {
	display: flex;
	margin-bottom: 1em;
	position: relative;
	transform: translateZ(0);
	z-index: 2;


	.player-profile-container {
		padding-left: 2em;
	}

	.stats-container {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 3em;

		>div {
			text-align: center;
			height: 5em;
			min-width: 8em;

			.title {
				margin-top: 0.5em;
				line-height: 1.5;
				text-align: center;
				text-transform: capitalize;
			}

			.number {
				height: 1.5em;
				color: @font-color-title;
				font-weight: normal;
				font-size: 2em;
				animation: 0.25s slide-up-stats ease-in-out;
			}
		}

		.top-genre {
			.genres {
				position: relative;

				.icon {
					width: 100%;
					max-height: 2em;
					max-width: 2em;
					margin: 0.3em;
				}
			}
		}
	}

	@keyframes slide-up-stats {
		0% {
			opacity: 0;
			position: relative;
			top: 0.5em;
		}
		80% {
			opacity: 1;
		}
		100% {
			position: relative;
			top: 0;
		}
	}

}
