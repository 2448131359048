.payment-past-due-popup {
	.payment-past-due-popup-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	
		p {
			width: 26em;
			margin: 1em 0 0 0;
			color: #A0A0A0;
			text-align: center;
		}
	
		.premium-diamond-icon {
			margin-top: 1em;
			width: 8em;
		}
	}

	.buttons-container {
		padding-right: 3em;
		.common-button {
			width: 12em;
		}
	}
}
