.game-details-similar-component {
	display: block;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	.top-container {
		width: 100%;
		height: 13em;
	}

	.list-container {
		height: 10em;

		.list-inner {
			.tile-game {
				margin: 0.8em;
				height: 8em;
				width: 8em;
			}
		}
	}
}
