.daily-challenges-info {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 14em;
    padding-top: 2.5em;
    padding-left: 2em;
    margin-right: 11em;
    &__animationElement {
        visibility: hidden;
        position: absolute;
        right: -3em;
        bottom: 2em;
    }
    &__img {
        width: 100%;
        height: auto;
        min-height: 11em;
    }
    &__title {
        padding: 0 0.5em;
        font-family: "Lato", sans-serif;
        font-size: 1.4em;
        text-align: center;
        margin-bottom: 0.1em;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__text {
        padding: 0 0.5em;
        color: #979797;
        font-size: 0.8em;
        text-align: center;
    }
    &__image-box {
        position: relative;
        margin-bottom: 0.1em;
        //background: rgba(255,255,255,0.1);
    }
    &__tile {
        background-color: #5A1491;
        padding: 0;
        transform: none;
        font-size: 1em;
        height: 11em;
        margin-bottom: 0.2em;
        .content {
            transform: none;
            font-size: 1em;
        }
    }
    &__play-circle {
        position: absolute;
        background-image: url("../../../assets/images/daily-challenges/play.png");
        background-size: 44%;
        background-repeat: no-repeat;
        background-position: 60% center;
        background-color: rgb(255 255 255 / 0.01);
        backdrop-filter: blur(0.1em);
        width: 5em;
        height: 5em;
        border-radius: 50%;
        top: 50%;
        left: 3.5em;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-shadow: 0.05em 0.05em 0.05em rgba(0,0,0,.1);
        box-shadow: 0.1em 0.1em 1em rgba(0,0,0,.5);
        border: 0 solid transparent;
        .content {
            transform: none;
            font-size: 1em;
        }
        .pulsar-component {
            .radius {
                border-radius: 50% !important;
            }
        }
    }
    &__gems-prize {
        padding: 0.9em 0 0 1.2em;
        position: absolute;
        bottom: 0.5em;
        right: -4em;
        font-family: 'Quantify', sans-serif;
        text-shadow: 0em 0em 0.3em #A6134F;
        height: 5em;
        width: 5em;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../../../assets/images/daily-challenges/icon-gems-prize-challenge-info.png");
    }
    &__gems-text {
        font-size: 1em;
        letter-spacing: 0;
    }
    &__gems-count {
        font-size: 2em;
    }
}