.challenge-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .text-content {
        position: absolute;
        z-index: 102;
        top: 0;
        left: 10%;
        width: 80%;
        opacity: 0;
        font-size: 10em;
        text-align: center;
        font-family: 'Quantify-Bold', sans-serif;
        color: #ffffff;
        
        &.colorLoop {
            animation: color-loop 1s infinite;
        }

        &.endText {
            font-size: 7em;
        }
    }
}

@keyframes color-loop {
    0% { color: #e9405f; }
    50% { color: #8d2192; }
    100% { color: #e9405f; }
}
