@import '../../assets/styles/variables';

.daily-challenges {
    position: relative;
    height: 85%;
    overflow: hidden;
    min-height: 85vh;
    padding-top: 1em;
    overflow-y: visible;

    .safe-area-turned-on & {
        min-height: auto;
    }

    &__title {
        position: absolute;
        top: 0;
        line-height: 1.12;
        margin-bottom: 0;
        margin-left: 1em;
        color: #efefef;
        text-align: left;
        font-size: 2.3em;
        font-family: 'Quantify-Bold', sans-serif;
        letter-spacing: normal;
        -webkit-user-select: none;
        user-select: none;
        z-index: 2;
        text-shadow:
            -0.06em -0.06em 0 #a324f6,
            0.06em -0.06em 0 #a324f6,
            -0.06em 0.06em 0 #a324f6,
            0.06em 0.06em 0 #a324f6;
    }
    &__title-icon {
        height: auto;
        width: 100%;
        max-width: 0.85em;
        position: relative;
        margin-bottom: -0.1em;
        margin-right: 0.1em
    }
    &__main-container {
        position: relative;
        display: flex;
        height: 17.4em;
        background-color: #5A1491;
        //background-size: contain;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/daily-challenges/daily-challenges-bg-image.png");
        &.hidden {
            transform: translateY(-101%);
            pointer-events: none;
            position: static;
        }
    }
    &__for-you-container {
        height: 100%;
        padding: 0 0 1em;
        &.top-container-hidden {
            transform: translateY(-16.5em);
        }

        .list-title {
            padding: 0.1em 0 0.4em 2.8em;
            text-transform: capitalize;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                display: inline-block;
                left: 1.5em;
                width: 1em;
                height: 1em;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
            }

            &.list-score_attack {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/score_attack.png);
                }
            }
            &.list-boss_attack {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/boss_attack.png);
                }
            }
            &.list-boss_rush {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/boss_rush_draw.png);
                }
            }
            &.list-carnage {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/carnage.png);
                }
            }
            &.list-collector {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/collector.png);
                }
            }
            &.list-death_wish {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/deathwish.png);
                }
            }
            &.list-pacifist {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/pacifist.png);
                }
            }
            &.list-pole_position {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/pole_position.png);
                }
            }
            &.list-score_attack {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/score_attack.png);
                }
            }
            &.list-speed_run {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/speed_run.png);
                }
            }
            &.list-survivor {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/survivor.png);
                    background-position: center top;
                }
            }
            &.list-time_attack {
                &:before {
                    background-image: url(../../assets/images/challenge-type-icons/time_attack.png);
                }
            }
        }
        .list-object {
            padding-top: 1em;
            padding-bottom: 1em;
        }
        .list-top_10_challenges .trending-index {
            background-color: #a324f6;
        }
        .list-inner {
            padding: 0.1em 0 0.4em 1.8em;
            .tile {
                margin-right: 1em;
                &:last-child {
                    margin-right: 0;
                }
            }
            .tile-challenge-component {
                height: 7.5em;
                width: 7.5em;
            }
            .content .title {
                font-size: 0.8em;
                text-align: center;
                padding-left: 0;
                opacity: 1 !important;
            }
            .tile-challenge-component-skew .gradient-top-border:after{
                height: 0.75em;
            }
            .play-icon {
                display: none;
            }
            .img-bg {
                img {
                    width: 120%;
                }
                &:after {
                    display: block !important;
                }
            }
            .selected {
                .img-bg {
                    border: 0.3em solid black;
                    border-top: 0.6em solid black;
                }
                .content .title {
                    padding-top: 1em;
                }
                .difficulty {
                    opacity: 1 !important;
                    margin-bottom: 0.2em;
                }
            }
            .t-1 .img-bg {
                border-color: @color-tier-1;
                background-color: @color-tier-1;
            }
            .t-2 .img-bg {
                border-color: @color-tier-2;
                background-color: @color-tier-2;
            }
            .t-3 .img-bg {
                border-color: @color-tier-3;
                background-color: @color-tier-3;
            }
            .t-4 .img-bg {
                border-color: @color-tier-4;
                background-color: @color-tier-4;
            }
            .t-5 .img-bg {
                border-color: @color-tier-5;
                background-color: @color-tier-5;
            }
        }
    }
}
