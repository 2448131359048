.notification {
    overflow: hidden;
    word-wrap: break-word;
	&.challenge-notification {
		overflow-y: scroll;
		width: 100%;
		color: #d7d7d7;

		h2 {
			font-weight: bold;
			color: white;
		}

		.buttons-container {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}

	&.with-avatar {
		padding-right: 4em;
		.avatar-container {
			position: absolute;
			right: 0;
			top: 0;
			width: 3.5em;
			height: 3.5em;
		}
	}

	&.giant-slayer {
		padding-left: 3.5em;
		background: url(../../../assets/images/generic-icons/giant-slayer-glow.png) 0% 50% no-repeat;
		background-size: 3em;
	}
}
