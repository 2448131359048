@import '../../assets/styles/variables';

.how-to-play {
	@keyframes bounce {
		0% { transform: scale(1); }
		50% { transform: scale(1.05); }
		100% { transform: scale(1); }
	}

	height: 100%;
	.safe-area-turned-on & {
		position: relative;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.flex-direction-row {
		display: flex;
		flex-direction: row;
	}
	.content-right {
		height: 100%;
	}
	
	&.ingame-native {
		background: @background;
	}
	&.as-giant {
		box-shadow: inset 0 0 6em 0 #FF343C;
		.webview-ultralight & {
			box-shadow:
				inset 0.5em 0.5em 4em 0 #FF343C,
				inset -0.5em -0.5em 4em 0 #FF343C;
		}
	}
	&.as-slayer {
		box-shadow: inset 0 0 6em 0 #346DFF;
		.webview-ultralight & {
			box-shadow:
				inset 0.5em 0.5em 4em 0 #346DFF,
				inset -0.5em -0.5em 4em 0 #346DFF;
		}
	}

	.content-with-bg-full-screen {
		display: flex;
		flex-direction: column;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		height: 100%;
		overflow-y: scroll;

		>h1{
			margin-bottom: 0.2em;
		}
		.challenge-only {
			position: absolute;
			right: 2.8em;
			width: 25%;
			top: 1em;
			max-height: 60%;
			img {
				width:17em;
			  }

			.challenge-accepted {
				-webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) 1s both;
	        	animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) 1s both;
			}
			.giant-slayer-animation {
				position: relative;
				height: 6em;

				background: url(../../assets/images/generic-icons/giant-slayer.png) 1.5em 60% no-repeat;
				background-size: 3.5em;

				&.puff-in {
					-webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) 1s both;
					animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) 1s both;
				}

				.giant-slayer-text {
					padding: 0.5em 0 0 2em;
					font-family: "Quantify", sans-serif;
					line-height: 0.85em;
					color: #ffffff;
					font-size: 2.5em;
					width: 4em;
					text-shadow: 0 0 0.5em #ffffff;
				}

				.slayer-illus {
					position: absolute;
					bottom: 0;
					right: -2em;
					width: 6em;
					height: 5.5em;
					background: url(../../assets/images/generic-icons/slayer-illus.png) 50% 50% no-repeat;
					background-size: contain;
				}
				.giant-illus {
					position: absolute;
					bottom: 0;
					right: -2em;
					width: 6em;
					height: 5.5em;
					background: url(../../assets/images/generic-icons/giant-illus.png) 50% 50% no-repeat;
					background-size: contain;
				}
			}
			.giant-slayer-information,
			.challenge-information {
				.giant-slayer-title,
				.challenge-title {
					font-size: 1.1em;
					text-align: right;
					text-align: center;
				}
				.giant-slayer-title-inner {
					background: url(../../assets/images/generic-icons/giant-slayer.svg) 0 0 no-repeat;
					background-size: 0.9em;
					display: inline-block;
					padding-left: 1.5em;
				}
				.giant-slayer-text,
				.challenge-text {
					font-size: 0.9em;
					text-align: right;
				}
				-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        	animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530)  both;
				.webview-ultralight & {
					animation: none;
				}
			}
		}
		.tab-menu-component {
			height: calc(100% - 10em);

			.how-to-play-content-container {
				display: flex;
				align-items: center;
				flex: 1;
				position: relative;
				overflow: hidden;
				padding-bottom: 0.5em;
				height: 100%;
				width: 100%;
			}
			.how-to-play-content-container--challenge {
				height: 90%;
				width: 70%;
			}
		}

		.how-to-play-bottom {
			position: fixed;
			bottom: 2rem;
			right: 2rem;
		}

		.state-text-container {
			font-size: 1.7em;
			color: @font-color-title;
			text-shadow: 0 0 0.5em @font-color-text-shadow;
			text-align: right;
			margin-bottom: 0.4em;
		}

		.buttons-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			.common-button {
				width: 10em;
				margin-right: 0.5em;
				&:last-child {
					margin-right: 0;
				}
				.react-bodymovin-container {
					margin-left: 30%;
					width: 40%;
				}
			}
		}
	}

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes puff-in-center {
	0% {
	  -webkit-transform: scale(2);
			  transform: scale(2);
	  -webkit-filter: blur(4px);
			  filter: blur(4px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-filter: blur(0px);
			  filter: blur(0px);
	  opacity: 1;
	}
  }
  @keyframes puff-in-center {
	0% {
	  -webkit-transform: scale(2);
			  transform: scale(2);
	  -webkit-filter: blur(4px);
			  filter: blur(4px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-filter: blur(0px);
			  filter: blur(0px);
	  opacity: 1;
	}
  }
  /**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
	0% {
	  -webkit-filter: blur(12px);
			  filter: blur(12px);
	  opacity: 0;
	}
	100% {
	  -webkit-filter: blur(0px);
			  filter: blur(0px);
	  opacity: 1;
	}
  }
  @keyframes text-focus-in {
	0% {
	  -webkit-filter: blur(12px);
			  filter: blur(12px);
	  opacity: 0;
	}
	100% {
	  -webkit-filter: blur(0px);
			  filter: blur(0px);
	  opacity: 1;
	}
  }
	.how-to-play-remote-warning {
        position: absolute;
		bottom: 2em;
		color: white;
		width: 25%;
    }

	.remap-controller-header {
		display: flex;
		min-height: 1em;
		&-image {
			background: url(../../assets/images/how-to-play/lil_gamepad.svg) 0 center no-repeat;
			background-size: contain;
			width: 1.1em;
			height: 1em;
			margin-left: 0.3em;
		}
	}
	.remap-controller-button {
		.content {
			display: flex;
			justify-content: center;
		};
	}
	.remap-controller-button-image {
		background: url(../../assets/images/how-to-play/lil_gamepad.svg) 0 center no-repeat;
		background-size: contain;
		display: inline-block;
		width: 2em;
		height: 1em;
		margin-left: 0.3em;
	}

	.three-dot-animation {
		display: flex;
		justify-content: center;
		.dot-elastic {
			position: relative;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: #fff;
			color: #fff;
			animation: dot-elastic 1s infinite linear;

			&::before {
				left: -20px;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: #fff;
				color: #fff;
				animation: dot-elastic-before 1s infinite linear;
				content: "";
				display: inline-block;
				position: absolute;
				top: 0;
			}

			&::after {
				left: 20px;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: #fff;
				color: #fff;
				animation: dot-elastic-after 1s infinite linear;
				content: "";
				display: inline-block;
				position: absolute;
				top: 0;
			}
		}
		@keyframes dot-elastic-before {
			0% {
				transform: scale(1, 1);
			}
			25% {
				transform: scale(1, 1.5);
			}
			50% {
				transform: scale(1, 0.67);
			}
			75% {
				transform: scale(1, 1);
			}
			100% {
				transform: scale(1, 1);
			}
		}
		@keyframes dot-elastic {
			0% {
				transform: scale(1, 1);
			}
			25% {
				transform: scale(1, 1);
			}
			50% {
				transform: scale(1, 1.5);
			}
			75% {
				transform: scale(1, 1);
			}
			100% {
				transform: scale(1, 1);
			}
		}
		@keyframes dot-elastic-after {
			0% {
				transform: scale(1, 1);
			}
			25% {
				transform: scale(1, 1);
			}
			50% {
				transform: scale(1, 0.67);
			}
			75% {
				transform: scale(1, 1.5);
			}
			100% {
				transform: scale(1, 1);
			}
		}
	}
}
