@import '../../assets/styles/variables';

.in-game-header {
  position: absolute;
  top: 1em;
  left: 1em;
  right: 1em;
  z-index: 100;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;

  .connection-strength-indicator {
    position: absolute;
    top: 3.2em;
    right: 1.8em;
    width: 1em;
    &__img {
      width: 2em;
      height: auto;
    }
  }

  .avatar-with-circle {
    margin-top: -0.8em;
    position: absolute;
    top: 0;
    right: 0;

    .notification {
      display: none;
    }

    .avatar-svg .circle-stroke {
      stroke: #0059a3;
    }
  
  }

  .buttons {
    opacity: 0;
    transition: opacity 1s;
    display: flex;
    height: 1.25em;

    &.active {
      opacity: 1;
    }
  }

  .menu-button {
    height: 100%;
    margin-right: 10px;
    cursor: pointer;

    &.menu-bounce-animation {
      animation: menu-button-bounce-frames 150ms;
    }

    @keyframes menu-button-bounce-frames {
      0% { transform: scale(1); }
      50% { transform: scale(0.9); }
      100% { transform: scale(1); }
    }
  }

  .header-button {
    height: 100%;
    transform: skew(-10deg);
    font-size: 0.4em;

    text-align: center;
    color: @font-color-button;
    text-shadow: 0.05em 0.05em 0.05em rgba(0,0,0,0.1);
    text-transform: uppercase;

    background: #005BA5;
    box-shadow: 0.1em 0.1em 1em rgba(0,0,0,.5);
    border: 0 solid transparent;
    border-radius: 0.3em;

    cursor: pointer;

    .content {
      transform: skew(10deg);
    }

    &.osk-button {
      margin-left: 10px;
      padding: 0 5px;
    }

    &.header-button-bounce-animation {
      animation: header-button-bounce-frames 150ms;
    }

    @keyframes header-button-bounce-frames {
      0% { transform: scale(1) skew(-10deg); }
      50% { transform: scale(0.9) skew(-10deg); }
      100% { transform: scale(1) skew(-10deg); }
    }
  }

  .scores {
    margin-right: 5em;
    opacity: 0.9;
    border-radius: 0.3em;
    border-bottom: 4em solid #005194;
    border-left: 1.5em solid transparent;
    background-repeat: no-repeat;
    width: 380px;
    padding-right: 3em;
    font-size: 0.5em;

    & > div {
      margin-top: 4em;
    }

    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    position: absolute;
    right: 0;
    top: 0;
    backface-visibility: hidden;

    width: 17.75em;
    height: 3.7em;
    background-size: 16em 4em;
    padding-right: 1em;

    &.giantScores {
      position: relative;
      top: 0;
      right: unset;
      backface-visibility: hidden;

      .score {
        margin-left: 2em;
      }

      .gs-illus {
        left: 0.5em;
      }
    }

    .gs-illus {
      position: absolute;
      top: 0;
      margin-top: 0.5em;
      left: 0;
      width: 4em;
      height: 3em;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      backface-visibility: hidden;
      &.giant {
        background-image: url(../../assets/images/generic-icons/giant-illus.png);
      }
      &.slayer {
        left: 0.5em;
        background-image: url(../../assets/images/generic-icons/slayer-illus.png);
      }
    }

    .score {
      display: inline-block;
      text-align: center;
      color: white;

      .value {
        font-weight: bold;
      }

      p {
        margin-bottom: 0;
        font-family: 'Lato', sans-serif;
      }
    }
  }
}

