@import '../../assets/styles/variables';

.recommendation-item-component {
	font-size: 1.3em;
	color: white;
	text-decoration: underline;
	font-family: "SourceSansPro-Regular";
	font-weight: normal;
	cursor: pointer;

	margin: 0 0.3em;

	&.selected{
		color: @font-color-outline-yellow;
	}
}
