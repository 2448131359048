@import '../../../assets/styles/variables';

.reward-item-component {
	color: @font-color-title;

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;

		&.gem{
			>img {
				display: block;
				width: auto;
				height: 3em;
			}
			.gem-value {
				font-size: 1.2em;
				font-weight: bold;
			}
		}

		&.exp {
			.exp-text{
				font-family: 'Quantify-Bold';
				margin: 0.25em 0;
				font-size: 2em;
				font-weight: bold;
				text-shadow: 0em 0em 0.6em @font-color-text-shadow;
			}
			.exp-value{
				font-size: 1.2em;
				font-weight: bold;
			}
		}

		&.withImage{
			width: 4.1em;
			height: 4.1em;
			overflow: hidden;

			&.with-border{
				border: 0.05em solid #fefefe;
				box-shadow: 0.1em 0.1em 0.7em rgba(0,0,0,0.4);
			}

			>img {
				width: auto;
				height: 4em;
			}
		}
	}
}