.common-button{
	&.favourite {
		padding: 0;
		height: 100%;
		width: 100%;

		.icon-container {
			height: 100%;
			width: 100%;

			.favourite-icon {
				display: block;
				margin: auto;
				height: auto;
				width: 1.7em;
			}
		}
	}
}


