@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.tile-challenge-component-skew {
	position: relative;

	.tile-challenge-game-border {
		position: absolute;
		width: 3.98em;
		height: 3.9em;
		right: -0.53em;
		bottom: -0.8em;
		background-color: #EFD97B;
		border: 0.15em solid #EFD97B;
		border-radius: 0.25em;
		z-index: 0;
	}
	.tile-challenge-game {
		position: absolute;
		width: 3.5em;
		height: 3.5em;
		border-radius: 0.25em;
		z-index: 1;
		right: 0;
		bottom: -0.5em;
		border: 0.15em solid transparent;
		&.selected {
			width: 3.7em;
			height: 3.7em;
			right: -0.4em;
			bottom: -0.7em;
			//border: 0.15em solid #EFD97B !important;
		}
		&__img {
			width: 100%;
			height: auto;
			font-size: 0.7em;
		}
		&.t-1 {
			border-color: @color-tier-1;
			background-color: @color-tier-1;
		}
		&.t-2 {
			border-color: @color-tier-2;
			background-color: @color-tier-2;
		}
		&.t-3 {
			border-color: @color-tier-3;
			background-color: @color-tier-3;
		}
		&.t-4 {
			border-color: @color-tier-4;
			background-color: @color-tier-4;
		}
		&.t-5 {
			border-color: @color-tier-5;
			background-color: @color-tier-5;
		}
	}

	.tile-challenge-component {
		margin: 0.4em;
		padding: 0.5em 0.67em;
		text-align: left;
		overflow: hidden;
		z-index: 1;

		.tile-challenge-animation-container {
			display: none;
		}

		&.gradient-top-border{
			&:after {
				z-index: 2;
				height: 0.25em;
				border-radius: 0.25em 0.25em 0 0;
			}
		}
		&.with-borders {
			border: 0.15em solid transparent;
			border-top: 0.5em solid transparent;
			border-radius: 0.25em;
			&:after {
				display: none;
			}
			&.t-1 {
				border-color: @color-tier-1;
			}
			&.t-2 {
				border-color: @color-tier-2;
			}
			&.t-3 {
				border-color: @color-tier-3;
			}
			&.t-4 {
				border-color: @color-tier-4;
			}
			&.t-5 {
				border-color: @color-tier-5;
			}
			&.selected {
				border: none;
			}
		}

		&.t-1:after {
			background-image: linear-gradient(91deg, #00d9ff, #32c7c2);
		}
		&.t-2:after {
			background-image: linear-gradient(91deg, #a9e03a, #73b112);
		}
		&.t-3:after {
			background-image: linear-gradient(91deg, #ffd829, #ff950a);
		}
		&.t-4:after {
			background-image: linear-gradient(91deg, #fd2872, #ea1e8e);
		}
		&.t-5:after {
			background-image: linear-gradient(91deg, #ae5cff, #663890);
		}

		.img-bg {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			border-radius: 0.15em;

			img {
				position: absolute;
				left: -0.82em;
				height: 104%;
				image-rendering: pixelated;
				image-rendering: crisp-edges;
			}

			&:after {
				display: block;
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(28, 53, 84, 0.6);
			}
		}

		.content {
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			.title {
				opacity: 1;
				font-family: "LatoBold", sans-serif;
				letter-spacing: 0.069em;
				line-height: 1.2em;
				padding-left: 0.4em;
				padding-top: 0.4em;
			}

			.difficulty {
				display: flex;
				justify-content: space-between;
				width: 2.35em;
				height: .56em;

				span {
					display: inline-block;
					width: 18%;
					height: 100%;
					background: @background-solid;
				}

				&.t-1 span.one {
					background: #00d9ff;
				}
				&.t-2 span.one,
				&.t-2 span.two {
					background: #a9e03a;
				}
				&.t-3 span.one,
				&.t-3 span.two,
				&.t-3 span.three {
					background: #ffd829;
				}
				&.t-4 span.one,
				&.t-4 span.two,
				&.t-4 span.three,
				&.t-4 span.four {
					background: #fd2872;
				}
				&.t-5 span {
					background: #ae5cff;
				}
			}
		}

		.unlock {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: absolute;
			height: calc(100% - 1em);
			width: calc(100% - 1.34em);

			.premium-benefits {
				position: absolute;
				left: 1.7em;
				top: 5.4em;
				width: 3.4em;
				height: 1.3em;
				padding: 0 0.2em;
				background: @premium-benefit-gradient;
				border-radius: 0.3em;
				color: #000000;
				display: flex;
				justify-content: center;
				align-items: center;
	
				.premium-benefits-text {
					font-size: 0.35em;
					text-align: right;
					text-shadow: none;
					padding-right: 2em;
	
					background: url(../../../assets/images/generic-icons/premium-diamond.png) 100% 50% no-repeat;
					background-size: contain;
				}
			}

			.padlock {
				width: 4.4em;
				height: 4.4em;
				margin: auto;
				background: url(../../../assets/images/global/padlock-darkblue.png) 0 0 no-repeat;
				background-size: contain;
			}

			.gems {
				opacity: 0;
				position: absolute;
				top: 50%;
				margin-left: -0.15em;
				font-family: "LatoBold", sans-serif;
				letter-spacing: 0.069em;

				img {
					width: 0.8em;
					margin-left: 0.2em;
					vertical-align: middle;
				}
			}
		}

		.play-icon {
			opacity: 0;
			position: absolute;
			z-index: 1000;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: opacity ease-in-out;

			.inner,
			&:after {
				position: absolute;
				width: 3.5em;
				height: 3.5em;
				top: 0;
				right: 0;
				bottom: 0;
				left: 5%;
				margin: auto;
			}

			.inner {
				background: url(../../../assets/images/generic-icons/play-icon.png) 0 0 no-repeat;
				background-size: cover;
			}
		}

		&.selected {
			transform: scale(1.1);
			color: @font-color-title;

			&.gradient-top-border {
				border-radius: 0.2em;
				&:after {
					display: none;
				}
			}

			.tile-challenge-animation-container {
				.pulsar-component{
					z-index: 11;
					display: block;
				}
				display: block;
			}

			.unlock {
				.message {
					opacity: 1;
				}
				.padlock {
					background: url(../../../assets/images/global/padlock-blue-grad.png) 0 0 no-repeat;
					background-size: contain;
				}
				.gems {
					opacity: 1;
					transition: opacity ease-in-out 0.1s;
				}
			}

			.content {
				.difficulty {
					opacity: 0;
				}
				.title {
					opacity: 0;
				}
			}

			.img-bg {
				&:after {
					display: none;
				}
			}

			&.unlocked {
				.play-icon {
					opacity: 1;
				}
			}
		}

		&.unlocked{
			.content {
				.title {
					color: @font-color-title;
					transition: opacity ease-in-out;
				}
			}
			.unlock {
				display: none;
			}
			&.animate {
				.img-bg,
				.play-icon {
					opacity: 0;
				}
				.unlock {
					display: flex;
					.padlock {
						opacity: 0;
						animation: 3s unlocking-locker ease-in-out;
					}

					.gems {
						opacity: 0;
						animation: 0.6s unlock-gems-slide-fade-out ease-in-out;
					}
				}
			}
		}

		&.locked{
			.img-bg {
				display: none;
			}
		}

		@keyframes unlocking-locker {
			0% { opacity: 1; }
			19% { transform: rotate(0deg);}
			20% { transform: rotate(5deg); }
			25% { transform: rotate(-5deg); }
			30% { transform: rotate(5deg); }
			35% { transform: rotate(-5deg); }
			40% { transform: rotate(5deg); }
			45% { transform: rotate(-5deg); }
			50% { transform: rotate(0deg); }
			90% { opacity: 1; transform: scale(1.5); }
			100% { opacity: 0; transform: scale(1.5); }
		}

		@keyframes unlock-gems-slide-fade-out {
			0% { opacity: 1; top: 50%;}
			100% { opacity: 0; top: calc(50% - 3em); }
		}
	}

	&.trending {
		margin-left: 4em;
		&--double {
			margin-left: 7em;
		}
	}

	.trending-index {
		top: -0.5em;
		left: -4em;
		position: absolute;
		background-color: #FD2872;
		border-radius: 3em 0 0 0;
		padding: 0.3em 0 0 1em;
		margin-right: -0.6em;
		width: 100%;
		min-width: 10em;

		&--double {
			left: -7em;
			min-width: 13em;
		}
	}
	.trending-index-text {
		color: #ffffff;
		font-size: 7.5em;
		font-family: 'Quantify', sans-serif;
	}
}
