@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.quit-game-popup-component {
	position: relative;
	width: 27em;
	padding: 1em;

	&:before {
		.popup-background-mixing;
	}

	.message-text {
		width: 100%;
		margin-top: 1.75em;
		margin-bottom: 2em;
		font-size: 1.3em;
		text-align: center;
		text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		color: @font-color-title;
	}

	.buttons-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		.common-button {
			padding: 0.5em;
			width: 25%;
			margin-right: 1em;
		}
	}
}