.countdown-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 4.5em;
    height: 4.5em;
    border-radius: 50%;
    color: #fff;
    background: #000000;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    box-shadow: 0.2em 0.2em 0.7em #000000;

    &.countdownFarFuture {
        background-color: #FFAD29;
    }
    &.countdownNearFuture {
        background-color: #FD2872;
    }
    &.countdownStarted {
        background-color: #1AB58A;
    }
    &.countdownEnded {
        background-color: #A0A0A0;
    }
    
    .status {
        font-size: 0.7em;
        line-height: 1.2em;
        padding: 0 5%;
    }

    .countdown-title {
        font-size: 0.9em;
        font-family:'Quantify-Bold';
        padding: 0 5%;
    }

    .sub-title {
        font-size: 0.7em;
        line-height: 1.2em;
        padding: 0 5%;
    }
}