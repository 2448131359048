.daily-challenges-medals {
    display: flex;
    &__title {
        font-family: "Lato", sans-serif;
    }
    &__box {
        display: flex;
        height: 1.5em;
    }
    &__item {
        display: flex;
    }
    &__medal-icon {
        width: 2em;
        height: 2em;
        background-repeat: no-repeat;
        background-size: contain;
        &--gold {
            background-image: url("../../../assets/images/daily-challenges/medals/medal-gold.png");
        }
        &--silver {
            background-image: url("../../../assets/images/daily-challenges/medals/medal-silver.png");
        }
        &--bronze {
            background-image: url("../../../assets/images/daily-challenges/medals/medal-bronze.png");
        }
    }
    &__points {
        color: #fff;
        font-size: 0.7em;
        padding: 0.3em 0 0;
        position: relative;
        margin-left: -0.5em;
    }
}