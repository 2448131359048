.debug-container {
	.debugger {
		width: 100%;
		height: 20em;
		overflow-y: scroll;
		position: fixed;
		bottom: 0;
		z-index: 200;
		background: rgba(0, 0, 0, 0.5);
		font-size: 11px;

		[data-method="warn"] {
			background-color: rgba(51, 43, 0, 0.5);
		}

		[data-method="error"] {
			background-color: rgba(38, 0, 0, 0.5);
		}
	}
	.fpsPanel{
		position: relative;
		z-index: 201;
		pointer-events: none;
	}

	.toggle-click-through-button {
		pointer-events: all;
		position: fixed;
		bottom: 1em;
		right: 1em;
		padding: 1em;
		border: 0.2em solid grey;
		border-radius: 1em;
		cursor: pointer;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}

	&.click-through {
		pointer-events: none;

		.toggle-click-through-button {
			border-color: green;
		}
	}
}

