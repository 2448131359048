.giant-slayer-status {
    position: relative;

    &.noHeader {
        padding-top: 5em;
    }

    .giant-slayer-status-inner {
        position: relative;
    }

    .intro-text {
        padding: 0 2.8em;

        h1 {
            margin: 0;
            padding-left: 1em;
            background: url(../../../assets/images/generic-icons/giant-slayer.png) 0 50% no-repeat;
            background-size: 0.6em;
            font-size: 2em;
        }
    }

    .slayers-block {
        position: absolute;
        left: 12em;
        top: 6em;
        width: 11em;
        height: 13em;

        .avatar-container {
            position: absolute;
            top: 4em;
            right: 3em;
            width: 8em;
            border: 0.1em solid #ffffff;
            border-radius: 50%;
            background-color: #ffffff;

            .avatar-image-container {
                border: 0.1em solid #000000;
                border-radius: 50%;
                background-color: #000000;
            }
            .user-image {
                border: 0.2em solid #1C98EA;
                border-radius: 50%;
                background-color: #1C98EA;
            }
            .notification {
                display: none;
            }
        }
    }
    .giant-block {
        position: absolute;
        right: 12em;
        top: 6em;
        width: 18.5em;
        height: 13em;

        .avatar-container {
            position: absolute;
            top: 0;
            right: 0;
            width: 12em;
            border: 0.2em solid #ffffff;
            border-radius: 50%;
            background-color: #ffffff;

            .avatar-image-container {
                border: 0.2em solid #000000;
                border-radius: 50%;
                background-color: #000000;
            }
            .user-image {
                border: 0.4em solid #FF343C;
                border-radius: 50%;
                background-color: #FF343C;
            }
            .notification {
                display: none;
            }
        }
    }

    .vs-icon-big {
        position: absolute;
        top: 13.5em;
        left: 23.5em;
        width: 6.5em;
        height: 6em;
        background: url(../../../assets/images/generic-icons/vs.png) 50% 50% no-repeat;
        background-size: contain;

        .css-filter-drop-shadow & {
            filter: drop-shadow(0 0 0.2em #000000);
        }
    }

    .slayer-illus {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 6em;
        height: 5.5em;
        background: url(../../../assets/images/generic-icons/slayer-illus.png) 50% 50% no-repeat;
        background-size: contain;
    }
    .giant-illus {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10em;
        height: 9.5em;
        background: url(../../../assets/images/generic-icons/giant-illus.png) 50% 50% no-repeat;
        background-size: contain;
    }

    .slayer-score-flag,
    .giant-score-flag {
        position: absolute;
        top: 6.5em;
        width: 8em;
        height: 13.5em;
        text-align: center;
        padding-top: 3.5em;
        color: #ffffff;
        font-family: 'Quantify-Bold', sans-serif;

        .score-label {
            font-size: 0.7em;
        }
    }

    .slayer-score-flag {
        left: 14.5em;
        background: url(../../../assets/images/generic-icons/slayer-score-flag.png) 50% 50% no-repeat;
        background-size: contain;
    }
    .giant-score-flag {
        right: 21.5em;
        background: url(../../../assets/images/generic-icons/giant-score-flag.png) 50% 50% no-repeat;
        background-size: contain;
    }

    .slayer-bar {
        position: absolute;
        top: 16em;
        left: 20em;
        transform: scale(1.5);
    }

    .vs-block {
        position: absolute;
        top: 19em;
        left: 8em;
        width: 40em;
        height: 3em;
        display: flex;
        flex-direction: row;

        .slayers-text,
        .giant-text {
            padding-top: 0.1em;
            color: #ffffff;
            font-family: 'Quantify-Bold', sans-serif;
            font-size: 1.6em;
        }

        .slayers-text {
            width: 11em;
            text-align: right;
        }

        .vs-icon {
            margin: 0 1em;
            width: 2em;
            height: 2em;
            background: url(../../../assets/images/generic-icons/vs.png) 50% 50% no-repeat;
            background-size: contain;

            .css-filter-drop-shadow & {
                filter: drop-shadow(0 0 0.2em #000000);
            }
        }
    }

    .score-outcome-text {
        position: absolute;
        left: 8.5em;
        top: 7em;
        width: 6em;
        text-transform: uppercase;
        text-align: center;
        color: #ffffff;
        font-family: 'Quantify-Bold', sans-serif;

        .css-filter-drop-shadow & {
            filter: drop-shadow(0 0 0.5em #ffffff);
        }

        .text-inner {
            font-size: 2em;
        }
    }

    .outcome-text {
        position: absolute;
        left: 22.5em;
        top: 7em;
        width: 10em;
        text-transform: uppercase;
        text-align: center;
        color: #ffffff;
        font-family: 'Quantify-Bold', sans-serif;

        .css-filter-drop-shadow & {
            filter: drop-shadow(0 0 0.5em #ffffff);
        }

        .text-inner {
            font-size: 2em;
        }
    }

    .buttons-container {
        margin-top: 15em;
        display: flex;
        flex-direction: row;
        height: 5em;
        justify-content: flex-end;
    }

    .common-button {
        margin: 1em;
        width: 10em;
    }

    .glow {
        .css-filter-drop-shadow & {
            filter: drop-shadow(0 0 0.5em #ffffff);
        }
    }

}
