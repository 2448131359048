@import '../../assets/styles/variables';

.player-search {
	height: 100%;
	width: 100%;

	.top-container {
		padding: 0 2.8em;
		height: 5em;

		.search-input-container {
			width: 55%;
			margin: 0 auto;
			padding-top: 1em;
			padding-bottom: 1em;
		}
	}

	.tile-lists-container {
		height: calc(100% - 5em);

		.list-container {
			.list-object {
				.list-title {
					font-size: 1.5em;
					font-family: LatoBold,sans-serif;
					text-transform: capitalize;
				}

				.list-inner {
					padding: 0.2em 0 0.4em 0;

				}
			}
		}
	}
}
