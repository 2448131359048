@import '../../../assets/styles/variables';

.game-info-panel-component {
	z-index: 3;
	width: 100%;
	height:100%;

	.title {
		font-family: 'Quantify-Bold';
		color: @font-color-title;
		line-height: 1.12;
		margin-bottom: 0;
		text-shadow: 0 0 0.5em @font-color-text-shadow;
		font-size: 1.8em;
		letter-spacing: 0.08em;
	}

	.content-main {
		width: 100%;
		height: 100%;
		display: flex;

		.content-left {
			position: relative;
			width: 50%;
			height: 100%;

			.description {
				width: 90%;
			}

			.logo-year-genre {
				display: flex;
				position:absolute;
				letter-spacing: 0.05em;
				text-shadow: 0 1px 1px @font-color-text-shadow-black;
				bottom:2.5%;
				width: 100%;
				.iconBlock,
				.multiplayerIcon,
				.germanyAgeIcon,
				.highScoreIcon,
				.publisher {
					max-height: 1.6em;
					max-width: 17em;
					margin-right: 0.4em;
					vertical-align: text-bottom;
				}
				.publisher {
					white-space: nowrap;
				}
				
				p {
					display: inline-block;
					vertical-align: middle;
					margin-bottom: 0;
					font-weight: 400;

					.year {
						margin-right: 1em;
						width:100%;
					}

					.genre {
						margin-right: 1em;
						width:100%;
					}
				}

				.germanyAgeIcon{
					max-height: 2.2em;
				}

				.iconLargeText {
					font-size: 1.4em;
				}
				.iconImage {
					height: 1.2em;
				}
				.iconText {
					font-size: 0.3em;
				}
			}
		}

		.image-container {
			position: relative;
			width: 50%;
			height: 100%;
			overflow: hidden;
			z-index:4;
			display: flex;
			transition: opacity 0.2s;
			padding-left: 1.4em;

			&.hidden {
				opacity: 0;
			}

			img {
				object-fit: cover;
				width: 100%;
			}
		}
	}
}
