@import "../../../assets/styles/variables";

.drop-down-component{
	position: relative;

	.drop-down-menu{
		position: absolute;
		top: 98%;
		right: 0.3em;
		z-index: 10;
		width: 100%;
		max-height: 11em;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		padding: 0;
		margin-bottom: 1em;

		.menu-item {
			&.common-button {
				width: 100%;
				transform: skew(0);
				padding: 0.5em;

				box-shadow:none;
				border-radius: 0;

				&.primary {
					background: @background-button-primary;
				}

				&.secondary {
					background: #4a517e;
				}

				.content {
					transform: skew(0);
				}

				&.bounce-animation {
					animation:menu-item-button-bounce-frames 50ms;
				}

				//override common-button animation
				@keyframes menu-item-button-bounce-frames {
					100% { transform: scale(1) skew(0deg); }
				}
			}
		}

		background: @background-solid;
		border: 0 solid transparent;
		border-radius: 0.15em;
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
	}
}