@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.shop-result-popup-component {
    position: relative;
    width: 38em;
    padding: 1em 0.7em 0.8em;
    color: #ffffff;

    &:before {
        .popup-background-mixing;
    }

    .title-text {
        text-align: center;
        width: 100%;
        font-family: 'Quantify-Bold', sans-serif;
        letter-spacing: 0.05em;
        font-size: 2em;
        color: @font-color-title;
        text-shadow: 0 0 0.6em @font-color-text-shadow;
        display: flex;
        justify-content: center;
        align-items: center;

        .shop-icon {
            height: 1.5em;
        }
    }

    p {
        text-align: center;
    }

    .illus {
        height: 10em;
    }

    .gem-count {
        position: absolute;
        left: 23em;
        top: 8em;
        height: 3em;
        background: url("../../../assets/images/global/icon-gem.png") 0 50% no-repeat;
        background-size: contain;
        padding-left: 3em;
        padding-top: 0.5em;

        .gems-value {
            font-size: 2em;
        }

        &.gems-bonus,
        &.gems-daily {
            padding-top: 0.7em;
            .gems-value {
                font-size: 1.5em;
            }
            .gems-label {
                font-size: 1em;
                margin-left: 0.5em;
            }
        }

        &.gems-daily {
            top: 12em;
        }
    }

    .bottom-container {
        display: inline-flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 1em;

        .common-button {
            width: 8em;
            margin-right: 2em;
        }
    }

    &.gems-illus-1 .bottom-illus,
    &.gems-illus-2 .bottom-illus,
    &.gems-illus-3 .bottom-illus,
    &.gems-illus-4 .bottom-illus,
    &.gems-illus-5 .bottom-illus,
    &.gems-illus-6 .bottom-illus {
        position: absolute;
        left: -1.5em;
        bottom: 0em;
        width: 40em;
        height: 21.5em;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.gems-illus-1 .bottom-illus {
        background-image: url("../../../assets/images/shop/gems-illus-1-large.png") ;
    }
    &.gems-illus-2 .bottom-illus {
        background-image: url("../../../assets/images/shop/gems-illus-2-large.png") ;
    }
    &.gems-illus-3 .bottom-illus {
        background-image: url("../../../assets/images/shop/gems-illus-3-large.png") ;
    }
    &.gems-illus-4 .bottom-illus {
        background-image: url("../../../assets/images/shop/gems-illus-4-large.png") ;
    }
    &.gems-illus-5 .bottom-illus {
        background-image: url("../../../assets/images/shop/gems-illus-5-large.png") ;
    }
    &.gems-illus-6 .bottom-illus {
        background-image: url("../../../assets/images/shop/gems-illus-6-large.png") ;
    }

    &.subscription-success .bottom-illus {
        position: absolute;
        left: 8em;
        bottom: 5em;
        width: 11em;
        height: 8em;
        background: url("../../../assets/images/shop/one-year-diamond-gold.png") 50% 50% no-repeat;
        background-size: contain;
    }

    &.shop-error .bottom-illus {
        position: absolute;
        left: 16em;
        bottom: 5em;
        width: 6em;
        height: 8em;
        background: url("../../../assets/images/shop/shop-error.png") 50% 50% no-repeat;
        background-size: contain;
    }

}
