.progress-circle-container {
  width: 100%;
  height: 100%;
  transform-origin: center center;
}

.progress-circle {
	font-size: 0.73em;
	position: relative; /* so that children can be absolutely positioned */
	padding: 0;
	width: 5em;
	height: 5em;
	background-color: #57677c; 
	border-radius: 50%;
	line-height: 5em;
    float: left;
 }
 
.progress-circle:after{
   border: none;
   position: absolute;
   top: 0.35em;
   left: 0.35em;
   text-align: center;
   display: block;
   border-radius: 50%;
   width: 4.3em;
   height: 4.3em;
   background-color: white;
   content: " ";
}
 /* Text inside the control */
.progress-circle span {
   position: absolute;
   line-height: 5em;
   width: 5em;
   text-align: center;
   display: block;
   color: #53777A;
   z-index: 2;
}
.left-half-clipper { 
  /* a round circle */
  border-radius: 50%;
  width: 5em;
  height: 5em;
  position: absolute; /* needed for clipping */
  clip: rect(0, 5em, 5em, 2.5em); /* clips the whole left half*/ 
}
/* when p>50, don't clip left half*/
.progress-circle.over50 .left-half-clipper {
  clip: rect(auto,auto,auto,auto);
}
.value-bar {
  /*This is an overlayed square, that is made round with the border radius,
  then it is cut to display only the left half, then rotated clockwise
  to escape the outer clipping path.*/ 
  position: absolute; /*needed for clipping*/
  clip: rect(0, 2.5em, 5em, 0);
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 0.45em solid #e7ba33; /*The border is 0.35 but making it larger removes visual artifacts */
  /*background-color: #4D642D;*/ /* for debug */
  box-sizing: border-box;
}
/* Progress bar filling the whole right half for values above 50% */
.progress-circle.over50 .first50-bar {
  /*Progress bar for the first 50%, filling the whole right half*/
  position: absolute; /*needed for clipping*/
  clip: rect(0, 5em, 5em, 2.5em);
  background-color: #e7ba33;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.progress-circle:not(.over50) .first50-bar{ display: none; }

.hidden {
 visibility: hidden;
 width: 0;
}

.progress-bar {
 background: #f1f6fa;
 border-radius: 5px;
 box-shadow: inset 0 0 0 1px #ccd6dd;
 height: 10px;
 overflow: hidden;
 position: relative;
 text-indent: 100%;
 width: 300px;
}
.progress-bar--counter {
 margin-left: 10px;
 position: relative;
 top: -1px;
}
.progress-bar--counter .hidden {
 display: inline-block;
}
.progress-bar--wrap {
 display: -webkit-box;
 display: -moz-box;
 display: flex;
 display: -webkit-flex;
 display: -moz-flex;
 display: -ms-flexbox;
 display: flex;
 font-size: 13px;
 font-weight: 500;
 line-height: 1;
 margin: 10px 0;
}
.progress-bar--inner {
 -webkit-transition: all 0.5s ease-in-out;
 -moz-transition: all 0.5s ease-in-out;
 transition: all 0.5s ease-in-out;
 border-radius: 10px;
 height: 10px;
 left: 0;
 min-height: 10px;
 position: absolute;
 top: 0;
}
.progress-bar--green {
 color: #6fc14b;
}
.progress-bar--green .progress-bar--inner {
 background-color: #6fc14b;
}
.progress-bar--blue {
 color: #068eda;
}
.progress-bar--blue .progress-bar--inner {
 background-color: #068eda;
}
.progress-bar--red {
 color: #fb797e;
}
.progress-bar--red .progress-bar--inner {
 background-color: #fb797e;
}
.progress-bar--yellow {
 color: #f7a81d;
}
.progress-bar--yellow .progress-bar--inner {
 background-color: #f7a81d;
}


.in-game-header-progress-circle {
  display: none;
}
.player-profile-progress-circle {
  .progress-circle {
    font-size: 1.5em;
  }
}
.tile-achievement-progress-circle {
  position: absolute;
  .progress-circle {
    font-size: 1em;
    &:after{
      background-color: #112645;
    }
  }
  .value-bar {
    border: 0.45em solid #efefef;
  }
  .progress-circle.over50 .first50-bar {
    background-color: #efefef;
  }
  .progress-circle {
    background-color: #32395d;
  }
  .avatar-svg {
    display: none;
  }
}
