.control-menu-component,
.in-game-menu-component {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.in-game-menu-component {
	.common-button {
		width: 10em;
		margin-bottom: 0.5em;
	}

	.save-buttons-container{
		height: 3.5em;
		width: 10em;
		display: flex;
		flex-direction: row;

		.common-save-button{
			margin-right: 0.3em;
		}

		.save-button{
			width: 3em;
			height: 2.3em;
			border-radius: 0.7em 0 0.7em 0;
			flex:0 0 auto;
			opacity: 0;
			margin: 0.35em 0.2em 0;

			.content {
				.title{
					font-size: 0.5em;
				}
			}
			&.disabled{
				.content {
					.title{
						height: 0.85em;
						font-size: 1.5em;
					}
				}
			}
			&.empty{
				.content {
					.title{
						height: 0.85em;
						font-size: 1.5em;
					}
				}
			}

			.border{
				border-radius: 0.7em 0 0.7em 0;
			}

			transition: opacity 200ms;

			.premium-overlay {
				.premium-benefits {
					height: 0.7em;
					.premium-benefits-text {
						font-size: 0.25em;
					}
				}
			}
		}

		.common-button {
			flex:0 0 auto;
		}

		&.focused{
			.save-button{
				opacity: 1;
			}
		}
	}
}
