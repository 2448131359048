@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';


.tile-giant-slayer-skew {
	transform: skew(-10deg);
	.tile-giant-slayer {
		margin: 0.4em;
		height: 8.5em;
		width: 8.5em;
		overflow: hidden;
		position: relative;

		&.selected {
			transform: scale(1.1);

			.colored-header,
			.difficulty,
			&:not(.giant-tile) .countdown-component {
				display: none;
			}

			.play-icon {
				opacity: 1;
			}

			.pulsar-component {
				display: block;
				z-index: 1;
			}

		}

		.disabled-overlay {
			display: none;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.8);
		}

		.attempt-cost {
			transform: skew(10deg);
			position: absolute;
			z-index: 1;
			left: 0;
			bottom: 0.5em;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: @font-color-button;

			.gem-image {
				width: 1em;
				height: 1.2em;
				margin-left: 0.2em;
			}
		}

		&.disabled {
			.disabled-overlay {
				display: block;
			}
			.attempt-cost {
				color: @font-color-button-disabled;
			}
		}

		&.random-giant-slayer,
		&.giant-tile {
			background-color: #1C3554;

			.random-illus {
				position: absolute;
				top: 1.7em;
				left: 0.5em;
				width: 5em;
				height: 5em;
				transform: skew(10deg);
				background: url(../../../assets/images/generic-icons/giant-slayer-random-dice.png) 50% 50% no-repeat;
				background-size: contain;
			}

			.giant-illus {
				position: absolute;
				left: 1.3em;
				top: 1.5em;
				width: 5.5em;
				height: 5.5em;
				transform: skew(10deg);
				background: url(../../../assets/images/generic-icons/giant-illus.png) 50% 50% no-repeat;
				background-size: contain;

			}

			.prize {
				position: absolute;
				right: 0;
				top: 0;
				width: 4em;
				height: 3em;
				transform: skew(10deg);
				background: url(../../../assets/images/generic-icons/giant-slayer-random-prize.png) 50% 50% no-repeat;
				background-size: contain;
			}

			.multiplier {
				position: absolute;
				right: 0;
				top: 0;
				width: 2em;
				height: 2em;
				transform: skew(10deg);
				background: url(../../../assets/images/generic-icons/giant-slayer-random-multiplier.png) 50% 50% no-repeat;
				background-size: contain;
			}
			.giant-slayer-icon {
				position: absolute;
				left: 0;
				top: 0.2em;
				width: 1em;
				height: 1em;
				transform: skew(10deg);
				background: url(../../../assets/images/generic-icons/giant-slayer-grey.png) 50% 50% no-repeat;
				background-size: contain;
			}
		}

		

		.img-bg {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			border-radius: 0.15em;

			img {
				position: absolute;
				left: -0.7em;
				height: 104%;
				transform: skew(10deg);
				image-rendering: pixelated;
				image-rendering: crisp-edges;
			}
		}

		.colored-header {
			position: absolute;
			z-index: 1;
			height: 1.2em;
			width: 100%;
			border-radius: 0.15em 0.15em 0 0;
			

			&.t-1 {
				background-color: @color-tier-1;
			}
			&.t-2 {
				background-color: @color-tier-2;
			}
			&.t-3 {
				background-color: @color-tier-3;
			}
			&.t-4 {
				background-color: @color-tier-4;
			}
			&.t-5 {
				background-color: @color-tier-5;
			}

			.giant-slayer-icon {
				margin-top: 0.2em;
				margin-left: 0.3em;
				height: 0.8em;
				width: 0.8em;
				transform: skew(10deg);
				background: url(../../../assets/images/generic-icons/giant-slayer-dark.png) no-repeat;
				background-size: contain;
			}
		}

		.difficulty {
			position: absolute;
			z-index: 1;
			bottom: 0.5em;
			left: 0.5em;
			display: flex;
			justify-content: space-between;
			width: 2.35em;
			height: 0.56em;

			span {
				display: inline-block;
				width: 18%;
				height: 100%;
				background: @background-solid;
			}

			&.t-1 span.one {
				background: @color-tier-1;
			}
			&.t-2 span.one,
			&.t-2 span.two {
				background: @color-tier-2;
			}
			&.t-3 span.one,
			&.t-3 span.two,
			&.t-3 span.three {
				background: @color-tier-3;
			}
			&.t-4 span.one,
			&.t-4 span.two,
			&.t-4 span.three,
			&.t-4 span.four {
				background: @color-tier-4;
			}
			&.t-5 span {
				background: @color-tier-5;
			}
		}

		.slayer-illus {
			transform: skew(10deg);
			position: absolute;
			z-index: 1;
			right: 0.3em;
			bottom: 0.2em;
			width: 3em;
			height: 3em;
			background: url(../../../assets/images/generic-icons/slayer-illus.png) 50% 50% no-repeat;
			background-size: contain;
		}

		.countdown-component {
			top: 0.2em;
			left: auto;
			right: 0.4em;
			transform: skew(10deg) scale(0.75);
			transform-origin: top right;
		}

		.play-icon {
			opacity: 0;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: skew(10deg);
			background: url(../../../assets/images/generic-icons/play-icon.png) 50% 50% no-repeat;
			background-size: 45%;
		}

		.giant-count-block {
			transform: skew(10deg);
			position: absolute;
			bottom: 0.5em;
			left: 0;
			width: 100%;
			color: #D7D7D7;
			font-size: 0.8em;

			display: flex;
			flex-direction: row;
			justify-content: center;

			.giant-count,
			.giant-limit {
				font-weight: bold;
				margin: 0 0.2em;
			}
		}
	}
}
