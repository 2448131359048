.login-credentials {
    padding-top: 1.3em;
    .login-with {
        display: flex;
        &__box {
            display: flex;
            flex-direction: column;
            padding: 0 0.5em;
        }
        &__fake-btn-img {
            height: 1.5em;
        }
    }
}

.with-playstation,
.with-xbox{
  padding: 0.4em 0.4em 0.3em;
  background-color: rgb(53, 53, 53);
  transform: skew(0);
  width: auto;
  color: white;
  .content {
    display: inline-flex;
    align-items: center;
    height: auto;
    transform: skew(0);
    font-family: "Helvetica", "Arial", sans-serif;
  }
  .text-container{
    padding: 0 0.3em;
  }
}
