@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.store-popup-container {
    height: 22.5em;
    width: 34em;

    .webview-ultralight & {
        padding-top: 0em;
        padding-bottom: 0em;
    }

    background-repeat: no-repeat;
    background-position: 0 25px;
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0.3em;
        left: -1.2em;
        transform: skew(-3deg);
        background-image: url("../../../assets/images/shop/shop_splash.png");
        background-repeat: no-repeat;
        background-size: contain;
    }

    .shop-header-text {
        font-family: Quantify;
        font-size: 1.1em;
        font-weight: 400;
        line-height: 1.1em;
        letter-spacing: 0px;
        text-align: center;
    }
    .shop-header-icon {
        position: relative;
        width: 1.2em;
        top: 0.3em;
        left: 0.1em;
    }
    .checkmark {
        color: lawngreen;
    }
    .store-popup {
        margin: 0 auto;
        min-width: 27em;
        display: flex;
        justify-content: space-around;
    }
    .left-col {
        text-align: left;
        padding-top: 1em;
        height: 15em;
        width: 9.24em;

        .webview-ultralight & {
            width: 9.34em;
        }
    }
    .right-col {
        padding-top: 0.5em;
        padding-bottom: 2.5em;
        position: relative;
        max-width: 50%;
        margin-left: 2em;
        justify-content: space-between;
        height: 14em;
        width: 10.5em;
        margin-top: 0.5em;

        &:before{
            content: "";
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: skew(-10deg);
            background: linear-gradient(135deg, #DE975E 0%, #ECBB7D 21.87%, #FFF6A2 34.24%, #E9B664 49.48%, #D3983F 74.48%, #B59D23 80.73%, #E4A860 100%);;
            border-radius: 0.4em;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            width: 9.95em;
            top: 0.1em;
            height: 13.5em;
            left: 0.15em;
            transform: skew(-10deg);
            border-radius: 0.4em;
            border: 0.15em solid #013A71;
        }

        &__img {
            width: 6em;
            margin: 0 auto;
            height: 100%;
        }

        h5 {
            margin-left: 2.4em;
            color: #2C2F48;
            font-family: Quantify;
            font-size: 0.8em;
            font-weight: 400;
            line-height: 1.3em;
            letter-spacing: 0px;
            text-align: center;
        }
    }

    .col-header {
        margin-bottom: 0;
        position: relative;
    }
    p {
        margin-bottom: 0;
        font-family: Lato;
        font-size: 1em;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: right;
    }
    .buttons-container {
        padding-top: 0 !important;
        padding-bottom: 1em;

        .webview-ultralight & {
            padding-top: 2.5em !important;
        }
    }
    .buttons-container .shop-exit-button {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background: #005BA4;
        width: 9.5em;
        margin-left: 15.3em !important;
        height: 2.5em;
        font-family: Source Sans Pro;
        letter-spacing: 0;
    }
    .buttons-container .premium-button {
        position: absolute;
        margin: 0;
        font-family: Source Sans Pro;
        font-size: 1em;
        font-weight: 400;
        line-height: 1em;
        background: #013A71;
        letter-spacing: 0em;
        top: 15.4em;
        right: 5.35em;
        width: 9.5em;
    }
    .common-button.secondary.selected {
        background: #4a5286;
    }
    .left-col-content {
        min-height: 280px;
        display: flex;
        align-items: center;
        margin-top: 2em;
    }
    .per-month-circle {
        border-radius: 50%;
        background: red;
        position: absolute;
        z-index: -1;
        padding: 1em;
        top: 27px;
        right: -9px;
        line-height: 1;
        &__per-month {
            white-space: nowrap;
            position: absolute;
            font-size: 9px;
            bottom: 12px;
            left: 16px;
        }
    }
    .store-popup-col {
        display: flex;
        flex-direction: column;
    }
}

.popup-component {
    max-width: 80%;
}