@import '../../../assets/styles/variables';

.mpc-status-component{
    position: relative;

	&:before {
		display: block;
		content: '';
		height: 13.5%;
	}

	.content-with-bg-full-screen {
		display: flex;
		flex-direction: column;
		padding-bottom: 1.5em;
		height: 100%;

		.challenge-opponents {
			position:relative;
			display: flex;
			flex-direction: column;
			padding: 0;

			.giant-slayer-title {
				margin-bottom: 0;
				padding-left: 1em;
				background: url(../../../assets/images/generic-icons/giant-slayer.svg) 0 50% no-repeat;
				background-size: 0.6em;
			}

			h2 {
				margin: 0;
			}

			.panels {
				position: relative;
				flex: 1;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 0 0.7em 0 -0.8em;

				.panel {
					transform: skew(-10deg);
					padding: 0;

					.outcome-text {
						position: absolute;
						top: 0.2em;
						right: 0.5em;
						text-transform: uppercase;
						font-size: 2em;
					}

					&.loss {
						background-image: linear-gradient(115deg, #af3755, #903754);
					}
					&.win {
						background: linear-gradient(135deg, #0087f2 0%, #023177 100%);
					}

					&.opponent {
						&:after {
							background-image: linear-gradient(114deg, #d48161, #b33533);
						}
						.outcome-text {
							right: auto;
							left: 1em;
						}
					}

					&.gs-won {
						&:after {
							background-image: linear-gradient(to right, #3adadb 0%, #1f9677 100%);
						}
						.outcome-text {
							color: #24FF00;
						}
					}

					&.gs-lost {
						&:after {
							background-image: linear-gradient(114deg, #d48161, #b33533);
						}
						.outcome-text {
							color: #D50401;
						}
					}

					.panel-inner {
						padding: 1em 2em 1em 1em;
						transform: skew(10deg);
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.top-container {
							display: flex;
							flex-direction: column;

							&.top-container--right {
								align-items: flex-end;
								text-align: right;
							}

							.avatar-container {
								width: 7em;
								height: 7em;
							}

							.name {
								display: block;
								color: @font-color-title;
								font-size: 1.5em;
								line-height: normal;
								letter-spacing: 0.05em;
								margin-top: 0.5em;
								min-height: 1.2em;
							}

							.left-medal {
								position:absolute;
								height:20%;
								top:75%;
								left:84%;
							}
						}

						.gem-panel {
							display: flex;
							flex-direction: column;
							width: 30%;
							align-items: center;
							padding-top: 3em;

							.gems-img {
								width: 2em;
								height: auto;
							}

							.gem-text {
								color: @font-color-title;
								font-size: 2em;
								letter-spacing: 0.05em;
							}
						}
					}

					.panel-result {
						padding: 0.5em;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						&.won{
							background-image: linear-gradient(to left, #33cc33, #258d25);
						}

						&.lost{
							background-image: linear-gradient(to left, #d62b2b, #ad2323);
						}

						&.waiting{
							background: #9098ae;
						}

						&.hide{
							display: none;
						}

						.result-text {
							transform: skew(10deg);
							width: 100%;
							text-align: center;
							color: @font-color-title;
						}
						.result-text-left {
							transform: skew(10deg);
							flex: 1;
							text-align: left;
							color: @font-color-title;
							font-size: 0.9em;
						}
						.result-text-right {
							transform: skew(10deg);
							flex: 1;
							text-align: right;
							color: @font-color-title;
							font-size: 0.9em;
						}
					}
				}

				.content-left {
					width: 45%;
					animation: 0.4s panel-slide-right ease-in-out;

					.panel {
						.panel-inner {
							.top-container {
								.avatar-container {
									margin-left: 1em;
								}
							}
						}
					}
				}

				.content-right {
					width: 45%;
					animation: 0.4s panel-slide-left ease-in-out;
				}

				.content-vs {
					position:absolute;
					transform: translateY(-50%);
					top: 50%;
					left: 0;
					width: 100%;
					height: auto;
					z-index: 50;
					pointer-events: none;
					&__gif {
						width: 100%;
					}
					.vsText {
						text-align: center;
						padding-right: 1.25%;
					}
					.react-bodymovin-container {
						width: 100%;
						height: 100%;

						.lootie {
							background-color:#fff;
							width:100%;
							height:100%;
							display:block;
							overflow: hidden;
							transform: translate3d(0,0,0);
							text-align: center;
							opacity: 1;
						}
					}
				}
			}

			.common-button {
				margin: 1em;
				align-self: flex-end;
				width: 20%;
			}
		}
	}
	//
	//@keyframes vs-text-scale-animation {
	//	0%  {
	//		opacity: 0.4;
	//		transform: scale(2.5);
	//	}
	//
	//	100% {
	//		opacity: 1;
	//		transform: scale(1);
	//	}
	//}
}
