@import "../../../assets/styles/variables";

.player-profile-container {
	position: relative;
	font-family: "SourceSansPro-Bold";
	text-align: right;
	cursor: pointer;

	.gem-container{
		position: absolute;
		top: 0;
		left: -0.3em;
		z-index: 2;
		width: 4em;

		.gem-text{
			position: absolute;
			bottom: 0.5em;
			right: 1.3em;
			font-size: 1.2em;
			color: #ff00d0;
		}
		.gem-icon{
			position: relative;
			height: 2em;
			width: auto;
		}
	}

	.star-container{
		position: absolute;
		top: 0;
		right: -1.7em;
		z-index: 2;
		width: 4em;

		.star-icon{
			position: relative;
			height: 2em;
			width: auto;

			.star-path{
				fill:url(#paint0_linear);
				stroke:#57677C;
				stroke-width:0.1em;
			}
		}

		&.active {
			fill:url(#paint0_linear);
		}

		&.selected{

		}
	}

	.rank-container{
		position: absolute;
		margin-top: calc(100% - 4.9em);
		// top: calc(100% - 2.3em);
		left: -3.3em;
		z-index: 2;
		width: 7em;

		.rank-text-container{
			position: absolute;
			// bottom: 0.4em;
			right: 1.7em;

			.exp-text-line-1{
				color: #e8c324;
				text-align: center;
				font-size: 0.8em;
				line-height: 0.9em;
				font-family: "SourceSansPro-Regular";
			}
			.exp-text-line-2{
				color: #e8c324;
				font-size: 0.8em;
				line-height: 0.9em;
				font-family: "SourceSansPro-Regular";

				>span{
					color: #e8c324;
					font-family: "SourceSansPro-Bold";
				}
			}

			.rank-text{
				font-size: 1.2em;
				color: #e8c324;
			}
		}

		.rank-icon{
			position: relative;
			height: 2em;
			width: auto;
			margin-top: 0.9em;
		}
	}

	.avatar-with-circle{
		position: relative;
		z-index: 0;
		width: 7.5em;
		height: 7.5em;
		.avatar-container {
			.notification {
				width: 13%;
				height: 13%;
				border-color: #57677C;
				border-width: 0.15em;
				top:0.5em;
				right: 0.3em;
				overflow: visible;
			}
		}
	}
}
