.tournaments-participants {
	width: 100%;
    height: 100%;


	.list-container {
		.list-container-inner{
			.list-object{
				.list-title {
					padding-left: 1.5em;
					padding-right: 1.5em;
				}

				.list {
					.list-inner{
						padding: 0 1.5em 0.7em 1.5em;
					}

					.tile {
						.tournament-player-component {
							//width: 5.33em;
							//height: 5.33em;
						}
					}
				}
			}
		}
	}

}
