.control-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    .common-button {
        margin-bottom: 0em;
    }

    .control-menu-options {
        margin: 0 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        .control-menu-option {
            .tab-menu-item-component {
                min-width: 5em;
            }
            &:last-of-type{
                margin-bottom: 1em;
            }
            &:first-of-type{
                margin-top: 1em;
            }	
            margin-bottom: 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            label{
                font-family: 'Quantify-Bold';
            }

            .control-menu-option-volume .tab-menu-item-component {
                min-width: 2em;
            }
        }

        .control-menu-tab-option {
            display: flex;
            label {
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .control-menu-option-tabs {
                display: flex;
                justify-content: flex-end;
                width: 100%;
            }
        }
    }
}