.in-game-menu-podium {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;

  width: 7em;
  height: 3.7em;
  background-size: 7em 3.7em;


  .rank-elements {
    display: flex;
    align-items: center;
    margin-left: 1em;
    height: 4em;

    background-image: url(../../../assets/images/webrtc-game/score-panel.png);
    background-repeat: no-repeat;
    background-size: 100% 80%;
    padding-right: 1em;
    padding-left: 0.5em;
    background-position-y: center;

    .rank-element {
      margin-right: 0.3em;
    }

    img {
      height: 50%;
    }

    .rank-position {
      color: white;
      font-family: 'Quantify', sans-serif;
      font-size: 1.8em;

    }
  }
}
