@import '../../../assets/styles/variables';

.selection-button {
	.link {
		position: relative;
		z-index: 100;
		margin-left: 2em;
		padding-bottom: 0.5em;
		border-bottom: 0.1em solid #3d485a;
		letter-spacing: 0.08em;
		cursor: pointer;
		&.selected {
			color: @font-color-button;
			transition: color 0.2s ease-in-out;

			&:after{
				content: '';
				display: block;
				position: absolute;
				height: 0.16em;
				width: 100%;
				bottom:0;
				left: 0;
				background: #1e88e5;
				background-image: linear-gradient(to left, #1e88e5, #14ccf8);
			}
		}

		&.focused {
			color: @font-color-button;
			transition: 0.2s color ease-in-out;

			&:after{
				content: '';
				display: block;
				position: absolute;
				bottom:0;
				left: 0;
				height: 0.16em;
				width: 100%;
				background: #1e88e5;
				background-image: linear-gradient(to left, #ff465f, #ff469c);
			}
		}
	}
}
