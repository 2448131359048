.signup-link-component {
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;

	section {
		.logo {
			width: 90%;
			img {
				height: 3em;
			}
		}

		.container {
			display: flex;
			margin-top: 2em;

			.left {
				margin-right: 3em;

				button {
					width: 30%;
				}

				.link {
					color: #FFFFFF;
					font-size: 2em;
				}
			}

			.right {
				.qrcode {
					border: 0.5em solid #AD5CFF;;
					border-radius: 0.5em;

					canvas {
						width: 15em !important;
						height: 15em !important;
					}
				}
			}
		}
	}
}
