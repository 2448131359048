@import '../../assets/styles/variables';

.homepage-component {
	height: 85%;
	overflow: hidden;
	min-height: 85vh;
	.safe-area-turned-on & {
		min-height: auto;
	}

	.tile-giant-slayer {
		.safe-area-turned-on & {
			width: 7.5em;
			height: 7.5em;
		}
	}
	
	.top-container {
		width: 100%;
		height: 12.5em;
		overflow: hidden;
		position: relative;
		z-index: 2;
		transform: translateY(0);
		transition: transform 200ms linear;

		&.hidden{
			transform: translateY(-101%);
			pointer-events: none;
			position: initial;
		}

		.game-info-panel-component{
			padding: 0.5em 2.8em 0 2.8em;

			.title {
				font-size: 2.25em;
			}
		}

		.giant-slayer-info-panel-component {
			display: none;
		}

		.giant-slayer-random-panel-component {
			display: none;
		}

		&.carousel-view {
			.hero-carousel-component {
				opacity: 1;
			}
			.game-info-panel-component {
				display: none;
			}
		}

		&.info-view {
			.hero-carousel-component {
				opacity: 0;
				pointer-events: none;
			}
		}


		&.giant-slayer-info-view {
			.hero-carousel-component {
				opacity: 0;
				pointer-events: none;
			}
			.game-info-panel-component {
				display: none;
			}
			.giant-slayer-info-panel-component {
				display: block;
			}
		}
		&.giant-slayer-random-view {
			.hero-carousel-component {
				opacity: 0;
				pointer-events: none;
			}
			.game-info-panel-component {
				display: none;
			}
			.giant-slayer-random-panel-component {
				display: block;
			}
		}
	}

	.gameslist-container {
		height: 100%;
		transform: translateY(0);
		transition: transform 200ms linear;

		&.top-container-hidden{
			transform: translateY(-12.5em);
		}

		.list-container{
			padding-top: 0.6em;

			&:after {
				content: '';
				display: block;
				height: calc(86.5vh - 10em);
			}

			.list-object {
				.list-title {
					margin-left: 1.95em;
					&.list-giantSlayer {
						padding-left: 1.2em;
						background: url(../../assets/images/generic-icons/giant-slayer.svg) 0 50% no-repeat;
						background-size: contain;
					}
				}

				.list-inner {
					padding: 0.1em 0 0.4em 1.8em;

					.tile-game{
						margin: 0.5em 0.2em;
						height: 6em;
						width: 6em;
					}
					.brutal-challenge-wrapper {
						.tile-challenge-component {
							height: 8.5em;
							width: 8.5em;
						}
						.play-icon {
							display: none;
						}
						.img-bg {
							img {
								width: 120%;
							}
							&:after {
								display: block !important;
							}
						}
						.content .title {
							text-align: center;
							padding-left: 0;
						}
						.selected {
							.img-bg {
								border: 0.3em solid black;
								border-top: 0.6em solid black;
							}
							.content .title {
								padding-top: 1em;
								opacity: 1;
							}
							.difficulty {
								opacity: 1 !important;
								margin-bottom: 0.2em;
							}
						}
						.t-1 .img-bg {
							border-color: @color-tier-1;
							background-color: @color-tier-1;
						}
						.t-2 .img-bg {
							border-color: @color-tier-2;
							background-color: @color-tier-2;
						}
						.t-3 .img-bg {
							border-color: @color-tier-3;
							background-color: @color-tier-3;
						}
						.t-4 .img-bg {
							border-color: @color-tier-4;
							background-color: @color-tier-4;
						}
						.t-5 .img-bg {
							border-color: @color-tier-5;
							background-color: @color-tier-5;
						}
					}

					.tile-challenge-component{
						height: 6em;
						width: 6em;

						.content{
							.title {
								font-size: 0.8em;
								margin-left: 0;
							}
						}
					}
				}

				&.list-Trending-Now {
					.list-inner {
						padding-top: 0.5em;
						.tile {
							display: flex;
							flex-direction: row;
							margin-right: 1.5em;
						}
						.trending-index {
							background-color: #FD2872;
							border-radius: 3em 0 0 0;
							padding: 0.3em 0 0 1em;
							margin-right: -0.6em;
							.trending-index-text {
								color: #ffffff;
								font-size: 7.5em;
								font-family: 'Quantify', sans-serif;
							}
						}
						.tile-game{
							margin: 0;
							height: 7.8em;
							width: 7.8em;
							transform: scale(1);
							animation: none;
						}
					}
				}

				&.list-7-Brutal-Challenges {
					margin-top: 1em;
					margin-bottom: 1em;
					.tile {
						margin-right: 1.5em;
					}
				}
			}
		}
	}
}

.loader-games {
    position:absolute;
    left:45%;
    top:30%;
    width:10%;
    height:50%
}
