@import '../../assets/styles/animations';
@import '../../assets/styles/variables';

.nav-item-component {
	position: relative;
	display: inline-block;
	cursor: pointer;
	margin:0.2em 0.1em;
	padding: 0.2em 0.6em;

	.badge {
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 100%;
		border-radius: 0.6em;
		overflow: auto;
		transition: transform 100ms ease-in-out;

		.title {
			position: absolute;
			top: -0.9em;
			left: -3.3em;
			height: 3em;
			width: 8em;
			line-height: 4.5em;
			text-align: center;
			font-family: "Quantify-Bold";
			font-size: 0.5em;
			color: @background-solid;
			background-color: #A0A0A0;
			transform: rotate(-45deg);
		}
	}

	.background {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.3em;
		border: 0.1em solid transparent;
		transition: transform 100ms ease-in-out;
	}

	.nav-image {
		position: relative;
		z-index: 2;
		padding: 0.65em;
		width: 100%;
		height: 100%;
		transition: transform 100ms ease-in-out;

		.icon-color {
			fill: #A0A0A0;
		}
	}

	.button-title {
		position: relative;
		z-index: 2;
		color: white;
		font-size: 1em;
		text-align: center;
		transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;
	}

	&.active {
		.background {
			border-color: @background-button-primary-selected;
		}
		.nav-image {
			transform: translateY(-0.25em);
			.icon-color {
				fill: white;
			}
		}

		.badge {
			transform: translate(-0.4em, -0.1em);
			.title {
				color: white;
				background: @background-button-primary-selected;
			}
		}
	}

	&.disabled {
		cursor: default;
		pointer-events: none;
		opacity: 0.5;
	}

	&.selected {
		.background {
			border-color: @font-color-outline-yellow;
		}
		.badge {
			.title {
				color: white;
				background: @font-color-outline-yellow;
			}
		}
	}

	&.hover-enabled {
		&:hover {
			.background {
				border-color: @font-color-outline-yellow;
			}
			.badge {
				.title {
					color: white;
					background: @font-color-outline-yellow;
				}
			}
		}
	}

	&.unread-message:after {
		display: block;
		content: '';
		z-index: 10;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		width: 0.43em;
		height: 0.43em;
		background: #f1ff29;
		border-radius: 20px;
		text-align: center;
	}

	&.back {
		width: 2.5em;
		height: 2.5em;
		background: url(../../assets/images/global/back.png) 50% 50% no-repeat;
		background-size: 1.5em;
		.button-title {
			display: none;
		}
	}

	&.community {
		
		.button-title {
			padding-right: 1em;
			background: url(../../assets/images/global/discord.png) 100% 50% no-repeat;
			background-size: 0.8em;
		}
	}

	&.atari {
		width: 2.5em;
		height: 2.5em;
		background: url(../../assets/images/global/atari-logo.svg) 50% 50% no-repeat;
		background-size: 1.8em;
		.button-title {
			display: none;
		}
	}

	&.library {
		margin-left: 2em;
		width: 2.5em;
		height: 2.5em;
		background: url(../../assets/images/global/search.svg) 50% 50% no-repeat;
		background-size: 1.8em;
		.button-title {
			display: none;
		}
	}

	&.xboxLibrary {
		margin-left: 1.5em;
		padding-left: 2.3em;
		height: 2.5em;
		background: url(../../assets/images/global/search-y-button.png) 10% 50% no-repeat;
		background-size: 1.6em;
		display: flex;
		align-items: center;
	}
	&.playstationLibrary {
		margin-left: 1.5em;
		padding-left: 2.3em;
		height: 2.5em;
		background: url(../../assets/images/global/search-tri-button.png) 10% 50% no-repeat;
		background-size: 1.6em;
		display: flex;
		align-items: center;
	}
	&.storeCart {
		margin-left: 1em;
		padding-left: 1.9em;
		padding-right: 1.9em;
		height: 2.5em;
		background: url(../../assets/images/global/playstaion_square.png) 10% 50% no-repeat;
		background-size: 1.6em;
		display: flex;
		align-items: center;
		.storeCartImg {
			position: absolute;
			height: 2.5em;
			width: 2.5em;
			background: url(../../assets/images/global/shop-cart-button-icon.png) 10% 50% no-repeat;
			background-size: 64%;
		}
	}
	&.settings {
		margin-top: 0;
		margin-bottom: 0;
		position: absolute;
		right: -2.2em;
		top: 0.85em;
		width: 2em;
		height: 2em;
		background: url(../../assets/images/global/settings.png) 50% 50% no-repeat;
		background-size: 1.5em;
		.button-title {
			display: none;
		}
	}
}
