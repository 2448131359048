.slayer-bar {
    background-color: #000000;
    border-radius: 0.5em;
    border: 0.15em solid #ffffff;
    width: 15em;
    height: 2.1em;
    padding: 0.1em;

    display: flex;
    flex-direction: row;

    .slayer-portion,
    .giant-portion {
        height: 100%;
        border-radius: 0.3em;
    }

    .slayer-portion {
        background: #1C98EA calc(100% - 0.1em) 50% ~"/" 0.4em no-repeat url(../../../assets/images/generic-icons/slayer-bar-arrow-right.png);
        position: relative;

        .slayers-army {
            position: absolute;
            top: -1.95em;
            width: 100%;
            height: 2em;
            background: 100% 100% no-repeat url(../../../assets/images/generic-icons/slayers-army.png);
            background-size: 14.6em 1.5em;
        }
    }
    .giant-portion {
        background: #FF343C 0.1em 50% ~"/" 0.4em no-repeat url(../../../assets/images/generic-icons/slayer-bar-arrow-left.png);
    }
    .target-flag {
        position: absolute;
        bottom: -0.2em;
        width: 2.5em;
        height: 4.5em;
        background: 50% 50% ~"/" contain no-repeat url(../../../assets/images/generic-icons/slayer-bar-target.png);

    }
}
