@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.shop-popup-component {
    position: relative;
    width: 38em;
    padding: 1em 0.7em 0.8em;

    &:before {
        .popup-background-mixing;
    }

    .title-text {
        text-align: center;
        width: 100%;
        font-family: 'Quantify-Bold', sans-serif;
        letter-spacing: 0.05em;
        font-size: 2em;
        color: @font-color-title;
        text-shadow: 0 0 0.6em @font-color-text-shadow;
        display: flex;
        justify-content: center;
        align-items: center;

        .shop-icon {
            height: 1.5em;
        }
    }

    .shop-content-text {
        transform: skew(-10deg);
        margin: 1em;
    }

    .shop-content {
        transform: skew(-10deg);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 2fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 1em;
        column-gap: 1em;
        margin: 1em;
    }

    .premium-shop-button {
        transform: skew(0deg);
        grid-column: 4 ~"/" 4;
        grid-row: 1 ~"/" 3;

        height: 17em;
        padding: 0;
        color: #2C2F48;

        background: linear-gradient(135deg, #DE975E 0%, #ECBB7D 21.87%, #FFF6A2 34.24%, #E9B664 49.48%, #D3983F 74.48%, #B59D23 80.73%, #E4A860 100%);
        box-shadow: 0.2em 0.2em 0.3em 0em #000000;

        .button-discount {
            background: linear-gradient(135deg, #cfcfcf 0%, #e0e0e0 21.87%, #e2e2e2 34.24%, #ffffff 49.48%, #929292 74.48%, #9e9e9e 80.73%, #a5a5a5 100%);
            box-shadow: 0.2em 0.2em 0.3em 0em #000000;

            text-align: center;
            font-weight: bold;
            position: absolute;
            right: 0em;
            top: 2.5em;
            padding-top: 0.4em;
            border-top-left-radius: 0.2em;
            border-bottom-left-radius: 0.2em;
            height: 1.8em;
            width: 3em;
        }

        .price-strikethrough {
            position: relative;
            margin-right: 0.7em;
            color: rgb(219, 10, 10);
          }

          .price-strikethrough:before {
            position: absolute;
            content: "";
            left: 0;
            top: 40%;
            right: 0;
            border-top: 0.10em solid;
            border-color: rgb(219, 10, 10);
            
            -webkit-transform:rotate(-5deg);
            -moz-transform:rotate(-5deg);
            -ms-transform:rotate(-5deg);
            -o-transform:rotate(-5deg);
            transform:rotate(-5deg);
          }

        .content {
            transform: none;
            height: 100%;
        }

        .button-title {
            transform: skew(10deg);
            margin-top: 0.5em;
            text-align: center;
            font-family: 'Quantify-Bold', sans-serif;
        }
        .button-illus {
            transform: skew(10deg);
            margin-top: 0.5em;
            height: 6em;
            background: url("../../../assets/images/shop/premium-diamond.svg") 50% 50% no-repeat;
            background-size: contain;
        }
        .button-illus-1year {
            position: absolute;
            left: -1em;
            top: 7.5em;
            transform: skew(10deg);
            height: 6em;
            width: 6em;
            background: url("../../../assets/images/shop/one-year.png") 50% 50% no-repeat;
            background-size: contain;
        }
        ul {
            text-align: left;
            list-style-type: disc;
            padding-left: 5em;
            margin-top: 0.5em;
            li {
                font-size: 0.7em;
                transform: skew(10deg);
            }
        }

        .button-cost {
            margin: 1em 0.5em 0.5em 0.5em;
            height: 2em;
            background-color: #013A71;
            border-radius: 0.2em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .button-cost-inner {
            transform: skew(10deg);
            font-size: 1.2em;
            color: #ffffff;
        }

        .subscribed-text {
            font-size: 0.9em;
        }
        .expire-date {
            line-height: 1.2em;
            font-size: 0.4em;
        }
    }

    .bottom-container {
        display: inline-flex;
        justify-content: flex-end;
        width: 100%;

        .common-button {
            width: 8em;
            margin-right: 2em;

            &.epic-store-redeem {
                width: 13em;
            }
        }
    }

}
