.Gem-Payment-Popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .Gem-Payment-Popup-center-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Gem-Payment-Popup-top-text {
        padding: 0.5em;
    }
    p {
        margin: 0;
        font-size: 0.9em;
    }
}

.popup-component {
    max-width: 80%;
}