
@keyframes fade-out {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes fade-out-in {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes panel-slide-right {
	0% {
		opacity: 0;
		transform: translateX(-10em);
	}
	80% {
		opacity: 1;
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes panel-slide-left {
	0% {
		opacity: 0;
		transform: translateX(10em);
	}
	80% {
		opacity: 1;
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes drop-down-list {
	0% {
		transform: translateY(-1.3em);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes slide-up-list {
	0% {
		opacity: 0;
		position: relative;
		transform: translateY(1.3em);
	}
	80% {
		opacity: 1;
	}
	100% {
		position: relative;
		transform: translateY(0);
	}
}

@keyframes rank-old-shake-grow-fade-out {
	0% { opacity: 1; transform: rotate(0deg); top: 0; color: grey; }
	10% { transform: rotate(-5deg); color: #efefef; }
	20% { transform: rotate(5deg); opacity: 0.3; }
	30% { transform: rotate(-5deg); opacity: 1; }
	40% { transform: rotate(5deg); opacity: 0; }
	50% { transform: rotate(-5deg); opacity: 1; }
	60% { transform: rotate(5deg); }
	70% { transform: rotate(0deg); }
	80% { opacity: 1; }
	90% { top: 0; }
	98% { top: 1.5em; transform: scale(1.3); }
	100% { opacity: 0; top: 1.5em; }
}

@keyframes rank-new-fade-in-shrink {
	0% { opacity: 0; }
	46% { opacity: 0; transform: scale(2); top: -1em; }
	50% { opacity: 1; transform: scale(2.5); top: 0; }
	70% { transform: scale(2.8); text-shadow: 0 0 0.3em rgba(20,204,248,0.8); }
	80% { transform: scale(3); text-shadow: 0 0 0.5em rgba(20,204,248,0.9); }
	97% { transform: scale(1); }
	100% { transform: scale(1); }
}

@keyframes rank-new-text-fade-in {
	0% { opacity: 0; }
	80% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes bounce {
	0% { transform: scale(1); }
	60% { transform: scale(1.05); }
	80% { transform: scale(0.98); }
	100% { transform: scale(1); }
}
