.free-tier-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    &.free-tier-banner-content-normal {
        .free-tier-banner-content-icon-groups {
            padding-bottom: 1em;
            .free-tier-banner-icon-group-circle-wrapper {
                height: 4em;
                width: 4em;
                margin-bottom: 0.2em;    
                .free-tier-banner-icon {
                    height: 2em;
                    width: 2em;
                    margin-bottom: 0.5em;
                }
            }
            .free-tier-banner-icon {
                height: 3em;
                width: 3em;
            }
            .free-tier-banner-detailed-wrapper {
                .free-tier-banner-value-number {
                    font-size: 2em;
                    padding-right: 0.1em;
                }
                .free-tier-banner-value-text {
                    font-size: 1em;
                }
            }
        }
        .free-tier-banner-value {
            font-size: 2em;
            text-transform: capitalize;
        }
    }

    &.free-tier-banner-content-popup {
        .free-tier-banner-content-icon-groups {
            padding: 1em 0;
            .free-tier-banner-icon-group-circle-wrapper {
                height: 3em;
                width: 3em;
                margin-bottom: 0.2em;    
                .free-tier-banner-icon {
                    height: 1em;
                    width: 1em;
                }
            }
            .free-tier-banner-icon {
                height: 2em;
                width: 2em;
            }
            .free-tier-banner-detailed-wrapper {
                .free-tier-banner-value-number {
                    font-size: 1.25em;
                    padding-right: 0.1em;
                }
                .free-tier-banner-value-text {
                    font-size: 1em;
                }
            }
            .free-tier-banner-value {
                font-size: 1em;
            } 
        }
    }

    .free-tier-banner-content-icon-groups {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .free-tier-banner-icon-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            &.active {
                color: white;
            }
            &.inActive {
                color: #A0A0A0;
            }
        }

        .free-tier-banner-pre-value {
            font-family: 'Quantify-Bold';
            margin-bottom: 0em;
        }

        .free-tier-banner-detailed-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .free-tier-banner-value {
            font-family: 'Quantify-Bold';
            line-height: 1em;
            margin-bottom: 0;
            text-align: center;
        }
    }
   
    .free-tier-banner-content-text {
        text-align: center;
        margin-bottom: 0;
    }
    
    .free-tier-banner-value {
        text-transform: capitalize;
    }
}