@import '../../../assets/styles/variables';

.avatar-with-circle {
	position: relative;
	z-index: 1;
	width: 3.7em;
	height: 3.7em;
	&.clickable {
		cursor: pointer;
	}
	.avatar-svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		overflow: visible;

		.circle-border {
			display: none;
			fill: @font-color-outline-yellow;
		}
		.circle-bg {
			fill: #e7ba33;
		}

		.circle-stroke {
			fill:none;
			stroke: #57677C;
			stroke-width: 8%;
			transform: rotate(-210deg);
			&.circle-stroke-animate {
				transition  : stroke-dashoffset 1.5s ease-in-out;
	
			}
		} 

	}

	&.selected {
		.avatar-svg{
			.circle-border{
				display: initial;
			}
		}
	}

	.avatar-container {
		position: absolute;
		top: 0;
		left: 0;
		padding: 7%;
		width: 100%;
		height: 100%;

		.avatar-image-container {
			border-width:0;
		}

		.avatar-icon{
			left: 0;
		}

		.notification{
			border-color: #57677C;
			&.twitch-color{
				border-color: #57677C;
			}
		}
	}
}
