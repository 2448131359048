.common-button{
	&.drop-down-selection-button {
		//padding: 0.5em 1em;

		.content {
			display: inline-flex;
			justify-content: center;
			width: 100%;

			.drop-down-content{
				width: 92%;
				text-align: center;
			}
			.arrow-container {
				width: 8%;
				.arrow-image {
					height: auto;
					width: 1.1em;
				}
			}
		}
	}
}
