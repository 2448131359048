.user-details {
  height: 85%;

	>h1{
		margin-bottom: 0;
		padding-left: 1.5em;
		font-size: 2em;
	}

	.tab-menu-component {
		height: calc(100% - 2em);

		.item-container {
			padding-left: 2.8em;
		}
	}
}
