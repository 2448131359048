.achievements-component {
	height: 85%;

	>h1{
		padding-left: 1em;
	}

	.list-container {
		height: calc( 100% - 3.6em);
		.list-object {
			.list-title{
				padding-left: 2.3em;
			}
			.list-inner{
				padding:0.5em 0 0.5em 2em;

				.tile {
					padding-top: 0.5em;
				}
			}
		}
	}
}
