@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.ios-welcome-popup {
    position: relative;
    width: 36em;

    transform: skew(-5deg);
    overflow: hidden;
    border: 0.3em solid #fff;
    border-radius: 0.3em;

    .ios-welcome-popup-bg {
        left: -1em;
        top: -0.1em;
        width: 110%;
        height: 100%;
        position: absolute;
        background-image: url('../../../assets/images/popups/ios-free-tier-popup-bg-min-2.png'), linear-gradient(135deg, #00356b, #00407e);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        transform: skew(5deg);
    }

    .header-box {
        margin-left: 2em;
    }

    .header-main-text {
        white-space: nowrap;
        text-align: center;
        font-family: 'Quantify-Bold', sans-serif;
        text-shadow: 0 0 0.15em #2F0E56;
        margin-bottom: 0;
        font-size: 2.5em;
        letter-spacing: 0.05em;
        line-height: 1;
        transform: skew(5deg);
    }

    .ios-welcome-popup-buttons {
        justify-content: flex-end;
        padding-right: 1em;
        transform: skew(5deg);

        .popup-button {
            padding-left: 0;
            padding-right: 0;
            font-family: Source Sans Pro, sans-serif;
            font-size: 0.8em;
            font-weight: 400;
            letter-spacing: 0;
            height: 2.8em;
            width: 11em;
            white-space: nowrap;
            &.selected {
                text-shadow: none;
            }
        }
        .play-for-free-button {
            color: #fff;
            background: #005BA4;
            margin-right: 0 !important;
        }
        .premium-button {
            color: #000;
            background: linear-gradient(135deg, #DE975E 0%, #ECBB7D 21.87%, #FFF6A2 34.24%, #E9B664 49.48%, #D3983F 74.48%, #B57223 80.73%, #E4A860 100%);
        }
    }

    .content-text {
        display: flex;
        flex-direction: column;
        margin-left: 16em;
        margin-top: 1.5em;
        transform: skew(5deg);
        &__el {
            margin: 0;
            text-align: center;

            font-family: Lato, sans-serif;
            font-size: 1.3em;
            line-height: 1.2em;
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    .ios-welcome-popup-content {
        padding: 0 1em 0 0.5em;
        min-height: 15em;
    }
}
