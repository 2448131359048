.tournaments-list-component {
	height: 85%;
	padding: 0 1.6em;

	.group-title{
		width: 100%;
		text-align: right;
		margin-bottom: 0.3em;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		>img{
			width: 1.5em;
			height: auto;
			padding-left: 0.5em;
		}
	}

	.list-container {
		height: calc(100% - 3.7em);
	}
}
