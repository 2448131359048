@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.new-content-popup-component {
	position: relative;
	width: 42em;
	padding: 1em 0.7em 0.8em;

	&:before {
		.popup-background-mixing;
	}

	.title-text {
		text-align: center;
		width: 100%;
		font-family: 'Quantify-Bold';
		letter-spacing: 0.05em;
		font-size: 1.7em;
		color: @font-color-title;
		text-shadow: 0 0 0.6em @font-color-text-shadow;
	}

	.message-text {
		margin-top: 1em;
		margin-bottom: 1em;
		font-size: 1.1em;
		text-align: left;
		text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		color: @font-color-title;
		white-space: pre-line;
	}

	.img-Container {
		width: 100%;
		text-align: left;
		padding-top: 1em;
		padding-bottom: 1em;
		padding-left: 1em;
    	vertical-align :middle;
		.new-content-img {
			width: 10em;
			height: auto;
			float: left;
			margin-right: 0.5em;
		}
	}

	.buttons-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		.common-button {
			padding: 0.5em;
			width: 25%;
			margin-right: 1em;
		}
	}
}