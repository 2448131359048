.navigation  {
	padding: 0 0.5em 0 4.7em;
	display: flex;
	align-items: center;
	z-index: 1;
	.back-button-placeholder{
		display: inline-block;
		width: 3.5em;
		height: 3.5em;
		margin: 0.4em;
	}
}
