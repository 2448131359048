@import '../../../assets/styles/variables';

.inbox-list-item {
	position: relative;
	width: calc(100% - 1.1em);
    height: 2.69em;
    border-radius: 0.1em;
	cursor: pointer;
	
	.item-inner {
		display: flex;
		align-items: center;
		height: 100%;

		.image-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 3em;
			height: 100%;

			.avatar-container {
				width: 2em;
				height: 2em;
				margin-right: 0.4em;
			}

			.icon {
				width: 2em;
				height: 2em;
			}
		}

		.text {
			flex: 1;
			text-align: left;
			font-family: 'SourceSansPro-Regular', sans-serif;
		}
	}

	.pulsing-border {
		position: absolute;
		z-index: 10;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		border: 0.1em solid transparent;
		border-radius: 0.1em;
	}

	.pulsing-border:after,
	.triangle-pulsing-border:after {
		display: block;
		content: '';
		position: absolute;
		z-index: 11;
		top: -0.1em;
		bottom: -0.1em;
		left: -0.1em;
		right: -0.1em;
		border: 0.1em solid transparent;
		border-radius: 0.1em;
	}

	.triangle-pulsing-border:after {
		left: 0;
		bottom: 0;
		border-left: none;
		border-bottom: none;
		border-radius: 0 0.1em 0.1em 0;
	}
}

.tile.selected {
	.inbox-list-item {
		background: linear-gradient(135deg, #0087f2 0%, #023177 85%, #023177 100%);
		.triangle {
			position: absolute;
			z-index: 100;
			top: 0em;
			bottom: 0.12em;
			right: -0.23em;
			width: 0.57em;
			background: #023177;
			transform: skew(-10deg);
		}

		.pulsing-border{
			border-color: @font-color-outline-pink;
		}

		.triangle-pulsing-border {
			position: absolute;
			top: 0em;
			bottom: -0.1em;
			left: 0;
			right: 0;
			border: 0.1em solid @font-color-outline-pink;
			border-radius: 0 0.1em 0.1em 0;
			border-left: none;
			border-bottom: none;
		}

		.pulsing-border:after,
		.triangle-pulsing-border:after {
			border-color: @font-color-outline-yellow;
		}
		.pulsing-border--animate:after,
		.triangle-pulsing-border--animate:after {
			animation: 2s fade-out-in 0.2s infinite;
		}

		.text {
			color: @font-color-button;
		}
	}
}






