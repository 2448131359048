.challenge-button {
    width: 8em;
    height: 10.5em;
    text-align: center;
    padding: 0;
    box-shadow: 0.2em 0.2em 0.3em 0em #000000;
    text-shadow: none;

    &.selected {
        text-shadow: none;
        .pulsar-component {
            .back-border,
            .front-border{
                left: -0.15em;
                top: -0.15em;
                width: calc(100% + 0.25em);
                height: calc(100% + 0.3em);
                border-radius: 0.45em;

                // fix for ultralight - ultralight cuts off pulsar's border on scaling animation start, but only if 'left' and 'top' are not equal 0
                .webview-ultralight & {
                    left: 0em;
                    top: 0em;
                    width: calc(100%);
                    height: calc(100%);
                    border-radius: 0.25em;
                }
            }
        }
    }

    &.disabled {
        color: #000000;
        box-shadow: inset 0.2em 0.2em 0.3em 0em #000000;
    }

    .content {
        transform: none;
    }

    .button-title {
        transform: skew(10deg);
        margin: 0.5em;
        font-family: 'Quantify-Bold', sans-serif;
    }

    .button-illus {
        transform: skew(10deg);
        height: 6em;
    }

    .button-cost {
        margin: 0.5em;
        height: 1.5em;
        background-color: #013A71;
        border-radius: 0.2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-cost-inner {
        transform: skew(10deg);

        &.gem-icon {
            background: url("../../../assets/images/global/icon-gem.png") 100% 50% no-repeat;
            background-size: contain;
            padding-right: 1em;
        }
    }

    &.with-giant-count {
        .button-cost {
            justify-content: space-evenly;
        }

        .giant-count-block {
            transform: skew(10deg);
            font-size: 0.8em;

            display: flex;
            flex-direction: row;
            justify-content: center;

            .giant-count,
            .giant-limit {
                font-weight: bold;
                margin: 0 0.2em;
            }
        }
    }

    &.challenge-solo {
        background-color: #FFAD29;
        &.disabled {
            background: #573a0d;
        }
        .button-illus {
            background: url("../../../assets/images/generic-icons/illus-solo.png") 50% 50% no-repeat;
            background-size: contain;
        }
        &.disabled {
            background: #573a0d;
            .button-illus {
                background-image: url("../../../assets/images/generic-icons/illus-solo-dark.png");
            }
        }
    }

    &.challenge-duel {
        background-color: #1AB58A;
        .button-illus {
            background: url("../../../assets/images/generic-icons/illus-duel.png") 50% 50% no-repeat;
            background-size: contain;
        }
        &.disabled {
            background: #083b2d;
            .button-illus {
                background-image: url("../../../assets/images/generic-icons/illus-duel-dark.png");
            }
        }
    }

    &.challenge-giant-slayer {
        background-color: #FF343C;
        .button-illus {
            background: url("../../../assets/images/generic-icons/illus-giant-slayer.png") 50% 50% no-repeat;
            background-size: contain;
        }
        &.disabled {
            background: #5F0A0D;
            .button-illus {
                background-image: url("../../../assets/images/generic-icons/illus-giant-slayer-dark.png");
            }
        }
    }

    .button-prize {
        transform: skew(10deg);
        position: absolute;
        z-index: 1;
        right: -1.2em;
        top: 1.7em;
        width: 4.5em;
        height: 2.5em;
        background: url("../../../assets/images/generic-icons/golden-banner.png") 50% 50% no-repeat;
        background-size: contain;
        text-align: left;
        letter-spacing: 0em;

        display: flex;
        flex-direction: column;
        align-items: center;

        .prize-label {
            text-align: center;
            font-size: 0.8em;
            font-weight: bold;
            color: #97633E;
        }
        .prize-premium-only {
            font-size: 0.5em;
            color: #97633E;
            max-width: 5em;
            text-align: center;
            line-height: 1em;
            margin-top: -0.15em;
        }
        .prize-value {
            padding-left: 0.8em;
            text-align: center;
            font-size: 0.7em;
            line-height: 1.2em;
            font-weight: bold;
            color: #DB23AD;
            background: url("../../../assets/images/generic-icons/gem-outline.svg") 0% 50% no-repeat;
            background-size: contain;
        }

    }

}
