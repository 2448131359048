.game-details-component {
	height: 85%;
	min-height: 85vh;
    background: transparent;
    position: relative;
	overflow: hidden;

	.safe-area-turned-on & {
		min-height: auto;
	}

	.content-with-bg-full-screen {
		width: 100%;
		height: 100%;
	}

	h1 {
		width: 100%;
		white-space: nowrap;
		position: relative;
		word-break: break-all;
		font-size: 2em;
		line-height: 1;
		margin-top: 0;
		margin-bottom: 0;
		z-index: 11;

		&.long-text {
			font-size: 1.4em;
		}
		&.very-long-text {
			font-size: 1.2em;
		}
	}

	.tab-menu-component {
		height: calc(100% - 2em);
	}
}
