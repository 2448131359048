.common-button{
	&.with-gem {
		padding: 0.5em 1em;

		.content {
			display: inline-flex;
			align-items: center;
		}

		.gem-image-container {
			height: 1.8em;
			padding-left: 0.3em;
			padding-right: 0.3em;

			.gem-image {
				height: 100%;
				width: auto;
			}
		}
	}
}


