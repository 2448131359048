@import '../../../assets/styles/variables';

.input-container {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 3em;
	border-radius: 0.15em;
	&.disabled {
		opacity: 0.5;
	}

	input {
		position: absolute;
		z-index: 10;
		top: 0.14em;
		left: 0.5em;
		min-width: 1em;
		width: 93%;
		height: 2.7em;
		padding: 0 1.9em 0.1em 0.2em;
		background: @background-solid !important;
		border: none;
		outline: none;
		box-shadow: none;
		color: @font-color-button;
		font-family: 'Lato', sans-serif;
		font-weight: 400;
		font-size: 1em;
		letter-spacing: 0.09em;

		&:-webkit-autofill {
			background-color: @background-solid !important;
			box-shadow: 0 0 0 8em @background-solid inset;
			-webkit-text-fill-color: @font-color-title  !important;
			caret-color: @font-color-title;
		}
		&::placeholder {
			color: @font-color-button-disabled;
		}

		&.selected ~.pulsing-border {
			border-color: @font-color-outline-yellow;
		}

		&::-moz-focus-inner {
			border: 0;
		}
	}

	label {
		position: absolute;
		left: -12em;
		width: 12em;
		line-height: 2.6;
		padding-right: 1em;
		font-size: 1em;
		text-align: right;
	}

	.pulsing-border {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 3em;
		background: @background-solid;
		border: 0.1em solid #c3c9d7;
		border-radius: 0.15em;
		box-shadow: 1px 1px 10px rgba(0,0,0,.5);
		transform: skew(-10deg);
	}


	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance: textfield;
	}

	.message {
		position: absolute;
		top: 32px;
		right: -420px;
		width: 400px;
		height: 62px;
		color: #fff;
		text-align: left;

		.icon {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
			left: 0;
			width: 32px;
			height: 32px;
			object-fit: contain;
			border-radius: 20px;
			background-size: 32px 32px;
		}

		.text {
			margin-bottom: 0;
			vertical-align: middle;
			font-size: 1em;
		}

		&.tick {
			.icon {
				background-image: url(../../../assets/images/global/blue-tick.svg) 0 0 no-repeat;
			}
			.text {
				color: #bbb;
			}
		}
		&.error {
			display: inline-block;
			position: relative;
			width: 200px;
			height: 16px;
			border: 2px solid #fff;
			border-radius: 0.15em;
			vertical-align: middle;

			.icon {
				background-image: url(../../../assets/images/global/red-exlamation.svg) 0 0 no-repeat;
			}
			.text {
				color: #cf2e32;
				line-height: 30px;
			}

		}
	}

	.validation-error {
		display: flex;
		align-items: center;
		position: absolute;
		z-index: 10;
		top: 0;
		left: 104%;
		left: calc(100% + 1em);
		width: 12em;
		min-height: 3em;
		margin-bottom: 1em;
		padding: 0.5em 0.7em;
		background: @font-color-validation;
		border-radius: 0.15em;
		transform: skew(-10deg);
		color: @font-color-title;
		text-align: left;

		span {
			display: inline-block;
			transform: skew(10deg);
		}

		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 0.8em;
			left: -2.9em;
			width: 1.4em;
			height: 1.4em;
			background: url(../../../assets/images/global/red-exlamation.svg) 0 0 no-repeat;
			background-size: contain;
			transform: skew(10deg);
		}
	}

	&.novalidate input {
		padding-right: 0.7em;
	}
}
