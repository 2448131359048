@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.pulsar-component {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;

	.back-border,
	.front-border{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 0.15em solid @font-color-outline-pink;
		&.radius{
			border-radius: 0.15em;
		}
		
		&.round {
			border-radius: 100px !important;
			transform: skew(10deg)
		}
	}

	.front-border {
		border-color: @font-color-outline-yellow;
		//animation: fade-out-in 2s infinite;
	}
}
