@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.generic-popup-component {
	position: relative;
	width: 27em;
	padding: 1em 0.7em 0.8em;

	&:before {
		.popup-background-mixing;
	}

	.title-text {
		text-align: center;
		width: 100%;
		font-family: 'Quantify-Bold';
		letter-spacing: 0.05em;
		font-size: 1.7em;
		color: @font-color-title;
		text-shadow: 0 0 0.6em @font-color-text-shadow;
	}

	.message-text {
		width: 100%;
		text-align: center;
		text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		color: @font-color-title;
		padding: 0.7em 0.7em;
		&.large-text {
			font-size: 1.2em;
		}

		&.medium-text {
			font-size: 1em;
		}

		&.small-text {
			font-size: 0.8em;
		}
	}

	.buttons-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding-top: 0.7em;

		.common-button {
			padding: 0.5em 1em;
			width: auto;
			min-width: 25%;
			margin-right: 1em;
		}
	}

	&.checkout-offerings-popup {
		width: 28em;
		&:before {
			transform: skew(-6deg);
			border: 0.25em solid #14ccf8;
			border-radius: 0.25em;
		}
		.title-text {
			letter-spacing: 0.01em;
			text-align: right;
			text-shadow: none;
			font-size: 2.1em;
		}
		.message-text {
			margin-left: 20em;
			width: 12em;
			font-size: 0.8em;
			line-height: 1.5em;
		}
		.common-button {
			transform: skew(-6deg);
			padding: 0.8em 0.5em;
			font-size: 0.8em;
			&.selected {
				background: linear-gradient(135deg, #0087f2 0%, #023177 100%);
			}
			.content {
				transform: skew(6deg);
			}
		}
		.checkout-offerings-popup-img {
			background-image: url('../../../assets/images/popups/ps-checkout-offerings.png');
			background-size: contain;
			background-repeat: no-repeat;
			height: 160%;
			width: 160%;
			position: absolute;
			top: -6.6em;
			left: -3.5em;
			z-index: -1;
		}
	}
}