@import '../../../assets/styles/variables';

.tab-menu-component {
	height: calc(100% - 2.5em);
	width: 100%;

	.item-container {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		position: relative;
		z-index: 10;
		height: 3em;
		padding-bottom: 0.5em;
	}

	.content-container {
		height: calc(100% - 3em);
		width: 100%;
	}

	.loader-block {
		width: 100%;
		height: 0;

		&--toLeft {
			animation: from-left-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--toRight {
			animation: from-right-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--fromLeft {
			animation: to-left-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--fromRight {
			animation: to-right-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--loaderToLeft {
			animation: loader-to-left-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--loaderToRight {
			animation: loader-to-right-animation 300ms linear;
			animation-fill-mode: forwards;
		}

		.loader-container {
			margin: 0 auto;
			padding-top: 5em;
			width:8em;
			height:8em;
		}
	}

	.child-container {
		width: 100%;
		height: 100%;

		&--toLeft {
			animation: to-right-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--toRight {
			animation: to-left-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--fromLeft {
			animation: from-right-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--fromRight {
			animation: from-left-animation 300ms linear;
			animation-fill-mode: forwards;
		}
		&--loaderToLeft {
			transform: translateX(-120%);
		}
		&--loaderToRight {
			transform: translateX(-120%);
		}
	}

	@keyframes from-left-animation {
		0%{
			transform: translateX(-100%);
		}
		100%{
			transform: translateX(0);
		}
	}

	@keyframes from-right-animation {
		0%{
			transform: translateX(100%);
		}
		100%{
			transform: translateX(0);
		}
	}

	@keyframes to-left-animation {
		0%{
			transform: translateX(0);
		}
		100%{
			transform: translateX(-120%);
		}
	}

	@keyframes to-right-animation {
		0%{
			transform: translateX(0);
		}
		100%{
			transform: translateX(120%);
		}
	}

	@keyframes loader-to-left-animation {
		0%{
			transform: translateX(0);
		}
		50%{
			transform: translateX(50%);
		}
		51%{
			transform: translateX(-50%);
		}
		100%{
			transform: translateX(0);
		}
	}

	@keyframes loader-to-right-animation {
		0%{
			transform: translateX(0);
		}
		50%{
			transform: translateX(-50%);
		}
		51%{
			transform: translateX(50%);
		}
		100%{
			transform: translateX(0);
		}
	}
}

