.free-tier-information {
    margin: 2em 7em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    .free-tier-information-logo{
        margin-bottom: 1em;
        max-height: 3em;
    }
    .free-tier-information-header {
        text-align: center;
    }

    .free-tier-information-content {
        margin: 0 5em; 
    }
    .free-tier-information-icon-groups {
        display: flex;
        justify-content: space-around;
        padding-bottom: 0.5em;
    }
    .free-tier-information-icon-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        .free-tier-information-icon-group-icon {
            height: 4em;
            width: 4em;
            padding-bottom: 1em;
        }
        .free-tier-information-icon-group-text {
            line-height: 1em;
            margin-bottom: 0;
            text-align: center;
            font-family: 'Quantify-Bold';
            color: white;
            text-transform: capitalize;
        }
    }
    .free-tier-information-button-group {
        display: flex;
        justify-content: space-around;
    }
    .free-tier-information-button {
        &:first-of-type{
            margin-left: 4em;
            margin-right: 2em;
        }
        &:last-of-type{
            margin-left: 2em;
            margin-right: 4em;
        }
    }
    
}