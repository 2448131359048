@import '../../../assets/styles/variables';


.message-mpc-issued {
    width: 100%;
	cursor: pointer;

	.title-box {
		display: flex;
	}

	.common-button {
		margin-top: 0.1em;
		margin-right: 0.4em;
		padding: 0;
		height: 2em;
		width: 2em;
		border-radius: 50%;
		transform: skew(0deg);

		.pulsar-component {
			.front-border {
				border-radius: 50%;
			}
			.back-border {
				border-radius: 50%;
			}
		}
	}

	.image-container {
		display: flex;
		align-items: center;
		width: 2em;
		height: 100%;
		margin-right: 0.4em;
		transform: skew(-10deg);

		.avatar-container {
			width: 2em;
			height: 2em;
		}

		.icon {
			width: 2em;
			height: 2em;
		}
	}

	.message-inner {
		display: flex;
		justify-content: space-between;
		background: @panel-background;
		box-shadow: 0 0 0.5em 0.15em rgba(0,0,0,0.3);

		.left-content {
			width: 19.15em;
			line-height: 1.4;
			padding: 0.3em 0.5em 0.7em;
			font-family: 'SourceSansPro-Regular', sans-serif;
		}

		.right-content {
			align-self: flex-end;
			height: 10em;

			img {
				height: 100%;
			}
		}
	}

	b {
		font-weight: bold;
		color: white;
	}

	h2 {
		font-family: 'LatoBold', sans-serif;
	}
}
