/* Source Sans Pro fonts */
@font-face {
	font-family: 'SourceSansPro-Regular';
	src: url('../fonts/SourceSansPro/SourceSansPro-Regular.eot');
	src: url('../fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2'),
	url('../fonts/SourceSansPro/SourceSansPro-Regular.woff') format('woff'),
	url('../fonts/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype'),
	url('../fonts/SourceSansPro/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg'),
	url('../fonts/SourceSansPro/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SourceSansPro-Bold';
	src: url('../fonts/SourceSansPro/SourceSansPro-Bold.eot');
	src: url('../fonts/SourceSansPro/SourceSansPro-Bold.woff2') format('woff2'),
	url('../fonts/SourceSansPro/SourceSansPro-Bold.woff') format('woff'),
	url('../fonts/SourceSansPro/SourceSansPro-Bold.ttf') format('truetype'),
	url('../fonts/SourceSansPro/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg'),
	url('../fonts/SourceSansPro/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

/* Quantify fonts */
@font-face {
	font-family: 'Quantify Bold v2.6';
	src: url('../fonts/Quantify/Quantify Bold v2.6.eot');
	src: url('../fonts/Quantify/Quantify Bold v2.6.woff2') format('woff2'),
	url('../fonts/Quantify/Quantify Bold v2.6.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Quantify-Bold';
	src: url('../fonts/Quantify/Quantify-Bold.woff');
	src: url('../fonts/Quantify/Quantify-Bold.woff') format('woff'),
	url('../fonts/Quantify/Quantify-Bold.ttf') format('truetype'),
	url('../fonts/Quantify/Quantify-Bold.svg#Quantify-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Quantify';
	src: url('../fonts/Quantify/Quantify.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* Lato fonts */
@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Regular.eot');
	src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Lato/Lato-Regular.woff2') format('woff2'),
	url('../fonts/Lato/Lato-Regular.woff') format('woff'),
	url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'LatoBold';
	src: url('../fonts/Lato/Lato-Bold.eot');
	src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Lato/Lato-Bold.woff2') format('woff2'),
	url('../fonts/Lato/Lato-Bold.woff') format('woff'),
	url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Quantify fonts */
@font-face {
	font-family: 'LuckiestGuy';
	src: url('../fonts/LuckiestGuy/LuckiestGuy-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
