.search-component {
	height: 85%;

	.search-input-container {
		margin: 0.5em 0 0.5em 2em;
		transform: translateZ(0);

		&.clearfix {
			&:after {
				visibility: hidden;
				display: block;
				content: "";
				clear: both;
				height: 0;
			}
		}

		.result-count{
			font-size: 1.3em;
			color: white;
			font-family: "SourceSansPro-Regular";
			font-weight: normal;
			margin-right: 1em;
			float: right;
			margin-top: 1em;
		}

		.input-container {
			width: 55%;
			padding-bottom: 1em;
			vertical-align: top;
			margin-bottom: 0.5em;
		}

		.recommendation-container{
			position: relative;
			display: flex;
			flex-direction: row;

			.recommendation-title{
				font-size: 1.3em;
				color:white;
				font-family: "SourceSansPro-Regular";
				font-weight: normal;
			}

		}
	}

	.no-result-message {
		margin-left: 2.75em;
	}

	.list-container {
		height: calc(100% - 5.8em);

		.list-container-inner{
			.list-object {
					.list-title {
						padding-left: 1.5em;
						padding-right: 1.5em;
						line-height: 2em;
						font-size: 1.2em;
					}

				.list {
					.list-inner{
						padding: 0 1.5em 0.7em 1.5em;
					}

					.tile {
						.tile-game {
							width: 5.33em;
							height: 5.33em;
						}
						.tile-user-component {
							height: 10em;
							width: 7em;
						}
					}
				}
			}
		}
	}

	.loader-games {
		position:absolute;
		left:45%;
		top:45%;
		width:10%;
		height:50%
	}
}
