@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.game-details-mapping-component {
    height: 100%;
    overflow-y: scroll;
    .how-to-play-controller-mapping {
        height: 100%;
    }
    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .common-button {
            width: 10em;
            &:first-of-type {
                margin-right: 0.5em;
            }
        }
    }
}
