@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/animations';

.gem-credit-popup-component {
	position: relative;
	width: 29em;
	padding: 1em 0.7em 0.8em;
	&:before {
		.popup-background-mixing;
	}

	.title-text {
		text-align: center;
		width: 100%;
		font-family: 'Quantify-Bold', sans-serif;
		letter-spacing: 0.05em;
		font-size: 1.7em;
		color: @font-color-title;
		text-shadow: 0 0 0.6em @font-color-text-shadow;
	}

	.message-text {
		width: 100%;
		font-size: 1.2em;
		text-align: center;
		text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		color: @font-color-title;
		padding: 0.7em;
	}

	.gem-credit-popup-component-gem-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		.daily-gems-img {
			width:3em;
			height:auto;
		}

		.daily-gems-value {
			font-size:2.35em;
			font-weight:bold;
			margin: 0;
			padding-left: 0.2em;
			opacity: 0;

			&.animation {
				animation-name: fade-in;
				animation-timing-function: ease-in;
				animation-duration: 0.2s;
				animation-fill-mode: forwards;
			}
		}
	}

	.buttons-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		.common-button {
			padding: 0.5em;
			width: 25%;
			margin-right: 1em;
		}
	}
}