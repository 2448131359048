@import "../../../assets/styles/variables";

.daily-challenges-leaderboard {
    padding-top: 1em;
    &__container {
        position: relative;
        background: @panel-background;
        width: 22em;
        height: 100%;
        max-height: 14em;
        &:before {
            content: '';
            height: 0.15em;
            display: block;
            background: linear-gradient(90deg,#12759a,#165592);
            border-radius: 0.15em 0.15em 0 0;
        }
    }
    &__rank-prize-icon-bg {
        background-size: contain;
        background-repeat: no-repeat;
        width: 2.8em;
        height: 2.8em;
        &--1 {
            background-image: url("../../../assets/images/daily-challenges/gems/gems-1st.png");
        }
        &--2 {
            background-image: url("../../../assets/images/daily-challenges/gems/gems-2nd.png");
            background-size: 90%;
            background-position: center left;
        }
        &--3 {
            background-image: url("../../../assets/images/daily-challenges/gems/gems-3rd.png");
            background-size: 75%;
            background-position: center left;
        }
    }
    &__loading {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 0.8em;
    }
    &__gems {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0.1em;
        right: -4.7em;
    }
    &__rank-prize {
        padding: 0.3em 0;
        height: 2.8em;
        display: flex;
    }
    &__rank-prize-amount {
        font-family: "Quantify", sans-serif;
        font-size: 1.3em;
        color: #FB4BFF;
        align-self: center;
        min-width: 2em;
        text-align: center;
    }
    &__crossplay {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 8em;
        text-align: center;
        margin-left: 7em;
    }
    .player-item {
        position: relative;
        height: 2.8em;
        cursor: pointer;
    }
    .player-item.selected {
        .pulsar-component {
            display: block;
        }
    }
    .crossplay-platform {
        min-width: 5%;
        width: 1.0em;
        height: 1.0em;

        .icon {
            width: 100%;
        }
    }
    .rankText {
        min-width: 8%;
        text-align: left;
        font-family: 'Quantify', sans-serif;
        font-size: 1em;
        color: #9098ae;
    }
    .name {
        color: @font-color-button-disabled;
        padding-left: 0.5em;
        width: 9em;
        min-width: 22%;
        //white-space: nowrap;
        //overflow: hidden;
        //text-align: left;
        //text-overflow: ellipsis;
        font-family: 'Quantify-Bold', sans-serif;
        word-break: break-all;
    }
    .item-inner {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 1em;
        border: 0.1em solid transparent;
        justify-content: space-between;
    }
    .avatar-container {
        width: 2.7em;
        height: 2.5em;
        padding: 0.1em 0;
    }
    .avatar-image-container {
        image-rendering: pixelated;
        position: relative;
        border: 0.1em #3d485a solid;
        border-radius: 50%;
        overflow: hidden;
        height: 100%;
        //width: 100%;
        transform: translateZ(0);
        width: 2.3em;
    }
    .score {
        min-width: 10%;
        justify-self: flex-end;
        text-align: right;
        font-weight: normal;
        font-family: 'SourceSansPro-Regular', sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .alternateInfos {
        position: relative;
        min-width: 15%;
        text-align: left;
        font-weight: normal;
        font-size: 0.5em;
        font-family: 'SourceSansPro-Regular', sans-serif;
        margin-top: 0.2em;
        text-transform: lowercase;
    }
    .logged-user {
        box-shadow: -0.15em 0.05em 0.15em 0.2em rgba(0, 0, 0, 0.4);
        background: linear-gradient(90deg, #12759A  0%, #165592 100%);
        border-radius: 0.15em 0.15em 0.15em 0.15em;
        .name {
            color: #efefef;
        }
        .rankText {
            color: #efefef;
        }
    }
}