.other-profile-info {
	position: relative;
	height: 100%;

	.top-container {
		height: 8.5em;
		margin: 0 2.8em;
	}

	.list-container {
		height: calc(100% - 8.5em);

		.list-object {
			.list-title {
				font-size: 1.3em;
				padding-left: 2em;
				font-weight: normal;
			}

			.list-inner {
				padding: 0.25em 0 0.3em 2.4em;

				.tile-game {
					width: 6em;
					height: 6em;
					margin: 0.4em;
				}
			}

			&:last-of-type{
				padding-bottom: 2em;
			}
		}
	}

	.xbox-gamer-card {
		position: absolute;
		top: -2em;
		right: 4em;
		font-family: Lato, sans-serif;
		font-size: 0.8em;
		z-index: 11;

		&__icon {
			color: #0072ff;
			border-radius: 50%;
			background: #fff;
			padding: 0.2em 0.4em;
			font-size: 0.8em;
			font-weight: bold;
			margin-right: 0.3em;
		}
	}
}
