@import '../../../assets/styles/variables';

.giant-slayer-random-panel-component {

    width: 100%;
    height: 100%;

    background: #102645 url(../../../assets/images/global/giant-slayer-banner-background.png) no-repeat 100% 50%;
    background-size: contain;

    .title-block {
        position: absolute;
        padding-left: 7.5em;
        padding-top: 0.5em;
        margin-bottom: 0.6em;
        height: 4.25em;
        color: #102645;

        background: linear-gradient(90deg, @color-tier-1, fade(@color-tier-1, 0%) 100%);

        .random-icon {
            position: absolute;
            left: 1em;
            top: 0.5em;
            width: 3em;
            height: 3em;
            background: url(../../../assets/images/generic-icons/giant-slayer-random-dice-white.png) 50% 50% no-repeat;
            background-size: contain;
        }

        .giant-slayer-icon {
            position: absolute;
            left: 4.5em;
            top: 0.5em;
            width: 3em;
            height: 3em;
            background: url(../../../assets/images/generic-icons/giant-slayer-dark.png) 50% 50% no-repeat;
            background-size: contain;
        }

        .title-text {
            font-family:'Quantify-Bold', sans-serif;
            font-size: 1.8em;
            margin-bottom: 0.1em;
            text-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
        }
        .giant-slayer-description {
            font-size: 0.9em;
            color: #ffffff;
        }
    }

    .gradient-block {
        position: absolute;
        right: 0;
        top: 8em;
        width: 100%;
        height: 4.2em;
        padding-top: 2.1em;
        background: linear-gradient(270deg, #FF343C, fade(#FF343C, 0%) 100%);
        display: flex;
        flex-direction: row;
    }

    .strip {
        position: absolute;
        bottom: 0.5em;
        right: 0;

        transform: scale(0.8);
        transform-origin: 100% 100%;
        width: 60em;
        height: 9.3em;
        overflow: hidden;
        mask-image: linear-gradient(to right, transparent 0%, black 30%, black 70%, transparent 100%);
    }
    .stripInner {
        height: 9.3em;

        display: flex;
        flex-direction: row;
    }

    .stripSingleBackground {
        position: absolute;
        bottom: 0.3em;
        right: 1em;
        width: 13em;
        height: 12em;
        background: url(../../../assets/images/global/skew-background.png) no-repeat 50% 50%;
        background-size: contain;
    }

    .stripSingleOuter {
        position: absolute;
        bottom: 1.6em;
        right: 2.4em;
        transform: scale(1.2);
        width: 10.3em;
        height: 9.3em;
        padding: 0 0.5em;

        overflow: hidden;
        mask-image: url(../../../assets/images/global/skew-mask.png);
        mask-size: cover;
        mask-repeat: no-repeat;
    }
    .stripSingle {
        width: 9.3em;
        height: 9.3em;
    }
    .stripSingleInner {
        height: 9.3em;

        display: flex;
        flex-direction: row;
    }

    .tile-giant-slayer .attempt-cost {
        display: none;
    }

    &.animation-finished {
        .clientV1 {
            animation: tile-blink 0.2s infinite;
        }
    }
}

@keyframes tile-blink {
    0% {
        filter: drop-shadow(0 0 0 #ffffff);
    }
    50% {
        filter: drop-shadow(0 0 0.5em #ffffff);
    }
    100% {
        filter: drop-shadow(0 0 0 #ffffff);
    }
}
