@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.tile-challenge-history-component {
	transform: skew(-10deg);
	width: 15em;
	margin: 0.5em;

	.safe-area-turned-on & {
		margin: 0.8em;
	}

	&.tile-giant-slayer-history {
		&.issued .content-wrapper  {
			background-color: #14CCF8;
		}
		&.win .content-wrapper  {
			background-color: #33CC33;
		}
		&.loss .content-wrapper  {
			background-color: #D62B2B;
		}

		.content-wrapper .content-inner {
			position: relative;
			transform: skew(10deg);
			width: 100%;
			height: 5.5em;

			p.long-title {
				font-size: 0.7em;
			}

			h3 {
				font-weight: normal;
				width: 7em;
				line-height: 0.6em;

				span {
					font-size: 0.6em;
				}
			}
		}

		.slayer-bar {
			position: absolute;
			left: -0.5em;
			bottom: 0;
			transform-origin: bottom left;
			transform: scale(0.5);
		}

		.illus-block {
			position: absolute;
			right: 0;
			top: 0;
			width: 4em;
			height: 4em;
		}

		.slayer-illus,
		.giant-illus {
			position: absolute;
			top: 0;
			right: 0;
			width: 4em;
			height: 4em;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.slayer-illus {
			background-image: url(../../../assets/images/generic-icons/slayer-illus.png);
		}
		.giant-illus {
			background-image: url(../../../assets/images/generic-icons/giant-illus.png);
		}

		.vs-icon {
			position: absolute;
			top: 2em;
			right: 3em;
			width: 1.5em;
			height: 1.5em;
			background: url(../../../assets/images/generic-icons/vs.png) 50% 50% no-repeat;
			background-size: contain;

			.css-filter-drop-shadow & {
				filter: drop-shadow(0 0 0.2em #000000);
			}
		}

		.slayer-count-block {
			position: absolute;
			right: 0;
			bottom: 0;
			text-align: center;
			color: #ffffff;
			font-size: 0.7em;
		}
	}

	.animation-container {
		position: relative;
		z-index: 1;
		&.with-transition {
			transition: transform 500ms;
		}
		&.selected {
			.pulsar-component {
				display: block;
			}
			transform: scale(1.1);
			.playstation-platform & {
				transform: scale(1.03);
			}
			z-index: 2;
		}

		.top-border {
			border-radius: 0.15em 0.15em 0 0;
			height: 0.1em;
		}

		.content-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 0.5em 0.8em;
			background: @panel-background;
			.css-filter-drop-shadow & {
				filter: drop-shadow(0em 0.1em 0.1em rgba(0,0,0,0.5));
			}

			.left {
				color: @font-color-title;
				text-align: left;
				transform: skew(10deg);

				h3 {
					font-size: 1.1em;
					font-weight: normal;
				}
			}

			.right {
				transform: skew(10deg);
				min-height: 4em;

				.avatar-container {
					width: 4em;
					height: 4em;
				}

				>img {
					height: 4em;
					width: 4em;
				}
			}

			h3 {
				font-size: 1.21em;
				margin: 0;
				word-wrap: break-word;
			}

			h2 {
				font-size: 1em;
				margin: 0;
				word-wrap: break-word;
			}

			p {
				margin: 0;
			}

			.gs-percentage {
				margin-top: 0.5em;
				margin-left: -0.5em;
				padding-left: 0.75em;
				background: url(../../../assets/images/generic-icons/giant-slayer-dark.svg) 0 50% no-repeat;
				background-size: contain;
				color: #000000;
			}
		}

		.result-message {
			padding: 0.3em 0.5em;
			border-radius: 0 0 0.15em 0.15em;
			text-align: left;
			color: @font-color-title;
			text-overflow: ellipsis;
			overflow: hidden;

			.result-text {
				transform: skew(10deg);
				font-size: 0.8em;
				margin: 0;
				width: 100%;
				white-space: nowrap;
			}
		}
	}


	&.win {
		.top-border {
			background: #33cc33;
		}
		.result-message {
			background:	#33cc33;
		}
	}

	&.loss {
		.top-border {
			background-image: linear-gradient(to left, #d62b2b, #ad2323);
		}
		.result-message {
			background-image: linear-gradient(to top, #ea4c4c, #ad2323);
		}
	}

	&.draw {
		 .top-border {
			 background: #9098ae;
		 }
		 .result-message {
			 background: #9098ae;
		 }
	}

	&.issued {
		.top-border {
			background-image: linear-gradient(to left, #1e88e5, #14ccf8);
		}
		.result-message {
			background-image: linear-gradient(to bottom, #1e88e5, #14ccf8);
		}
	}

	&.waiting {
		.top-border {
			background: #ff6600;
		}
		.result-message {
			background: #ff6600;
		}
	}

	&.solo {
		.top-border {
			background: #e4c333;
		}
		.result-message {
			background: #e4c333;
		}
	}
}
