@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.challenge-summary-component {

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
	0% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
	30% {
	  -webkit-transform: scale3d(1.25, 0.75, 1);
			  transform: scale3d(1.25, 0.75, 1);
	}
	40% {
	  -webkit-transform: scale3d(0.75, 1.25, 1);
			  transform: scale3d(0.75, 1.25, 1);
	}
	50% {
	  -webkit-transform: scale3d(1.15, 0.85, 1);
			  transform: scale3d(1.15, 0.85, 1);
	}
	65% {
	  -webkit-transform: scale3d(0.95, 1.05, 1);
			  transform: scale3d(0.95, 1.05, 1);
	}
	75% {
	  -webkit-transform: scale3d(1.05, 0.95, 1);
			  transform: scale3d(1.05, 0.95, 1);
	}
	100% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  }
  @keyframes jello-horizontal {
	0% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
	30% {
	  -webkit-transform: scale3d(1.25, 0.75, 1);
			  transform: scale3d(1.25, 0.75, 1);
	}
	40% {
	  -webkit-transform: scale3d(0.75, 1.25, 1);
			  transform: scale3d(0.75, 1.25, 1);
	}
	50% {
	  -webkit-transform: scale3d(1.15, 0.85, 1);
			  transform: scale3d(1.15, 0.85, 1);
	}
	65% {
	  -webkit-transform: scale3d(0.95, 1.05, 1);
			  transform: scale3d(0.95, 1.05, 1);
	}
	75% {
	  -webkit-transform: scale3d(1.05, 0.95, 1);
			  transform: scale3d(1.05, 0.95, 1);
	}
	100% {
	  -webkit-transform: scale3d(1, 1, 1);
			  transform: scale3d(1, 1, 1);
	}
  }
  
  
  

	max-height: 12.2em;
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	width: 100%;
	background: @panel-background;
	box-shadow: 0 0 0.5em 0.15em rgba(0,0,0,0.3);
	text-align: right;
	transform: skew(-10deg);

	&.t-2 .top-gradient {
		background-image: linear-gradient(91deg, #73b112, #a9e03a);
	}
	&.t-3 .top-gradient {
		background-image: linear-gradient(91deg, #ff950a, #ffd829);
	}
	&.t-4 .top-gradient {
		background-image: linear-gradient(91deg, #ea1e8e, #fd2872);
	}
	&.t-5 .top-gradient {
		background-image: linear-gradient(91deg, #663890, #ae5cff);
	}

	.top-gradient {
		height: 12%;
		padding: 0 0.5em;
		background: linear-gradient(to right, #32c7c2 0%, #00d9ff 100%);
		border-radius: 0.15em 0.15em 0 0;

		.challenge-type-container{
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			.difficulty {
				display: flex;
				justify-content: space-between;
				width: 2.35em;
				height: 0.56em;

				span {
					display: inline-block;
					width: 18%;
					height: 100%;
					background: @background-solid;

					&:first-child {
						border-radius: 0.1em 0 0 0.1em;
					}
					&:last-child {
						margin-right: 0;
						border-radius: 0 0.1em 0.1em 0;
					}
				}

				&.t-1 span.one,
				&.t-2 span.one,
				&.t-2 span.two,
				&.t-3 span.one,
				&.t-3 span.two,
				&.t-3 span.three,
				&.t-4 span.one,
				&.t-4 span.two,
				&.t-4 span.three,
				&.t-4 span.four,
				&.t-5 span {
					background: #efefef;
				}
			}
		}

		.challenge-type {
			display: flex;
			align-items: center;
			height: 100%;
			color: @font-color-title;
			font-family: 'SourceSansPro-Bold', sans-serif;
			text-transform: capitalize;
			letter-spacing: 0.069em;
			transform: skew(10deg);

			img {
				height: 1.2em;
				width: 1.2em;
				margin-right: 0.3em;
			}
		}
	}

	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		transform: skew(10deg);
		border-radius: 0 0 0.15em 0.15em;
		height: 88%;

		.summary-info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 33%;

			&.link {
				cursor: pointer;
			}

			img {
				width: 100%;
				height: 100%;
			}

			.number {
				display: block;
				margin-top: 0.1em;
				color: @font-color-title;
				font-size: 2em;
				font-family: "LatoBold", sans-serif;
				letter-spacing: 0.069em;

				>span{
					font-size: 1em;
				}

				&.minimized {
					>span{
						font-size: 0.6em;
					}
				}

			}

			.badge {
				height: 3em;
				width: 3em;
				margin-left: auto;
				margin-right: auto;
				
				&.leaderboard {

					img {
						-webkit-animation: jello-horizontal 0.9s 2s 2 both;
	        			animation: jello-horizontal 0.9s 2s 2 both;
					}

					img.selected {
						transform: scale(1.1);
					}
				}

				&.to-achieve {
					opacity: 0.2;
				}
				&.blank {
					opacity: 0.2;
				}
			}
		}
	}
	.leaderboard-icon {
		width: 3em!important;
	}
}