.loader-container {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	.react-bodymovin-container {
		width: 5em;
		height: 5em;
	}
}