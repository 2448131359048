@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.game-session-three-button-popup-component {
	position: relative;
	width: 36em;
	height: 24em;
	padding: 0.5em 1em;
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	.title-text {
		text-align: center;
		font-family: 'Quantify-Bold', sans-serif;
		letter-spacing: 0.05em;
		font-size: 1.7em;
		color: @font-color-title;
		text-shadow: 0 0 0.6em @font-color-text-shadow;
		margin: 0 1.5em 0 2.5em;
	}

	.info{
		font-size: 1.1em;
		margin: 0 1.5em 0 2.5em;
		text-align: center;
	}

	&:before {
		.popup-background-mixing;
	}

	.selection-buttons-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;

		.common-button {
			width: 10em;
			margin-right: 0.5em;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.challenge-button-list {
		margin-top: 2em;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}

	.challenge-button-descriptions {
		margin-top: 1em;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;

		.description {
			padding-right: 1.5em;
			width: 8em;
			text-align: center;
			color: #ffffff;
		}
	}

	.bottom-container {
		display: inline-flex;
		justify-content: flex-end;
		width: 100%;

		.common-button {
			width: 8em;
			margin-right: 2em;
		}
	}

}

.game-session-two-button-popup-component {
	position: relative;
	width: 32em;
	height: 17em;
	padding: 0.5em 1em;
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	.title {
		color: @font-color-title;
		font-size: 1.4em;
		text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		margin-left: 1.3em;
	}

	.info{
		font-size: 1.1em;
		margin: 0 0 0 1.5em;
		white-space: pre-line;
	}

	.autoscroll-box {
		height: 4.5em;
	}

	&:before {
		.popup-background-mixing;
	}

	.selection-buttons-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;

		.common-button {
			width: 10em;

			&:first-of-type {
				margin-right: 1em;
			}
		}
	}

	.bottom-container {
		display: inline-flex;
		justify-content: flex-end;
		width: 100%;

		.common-button {
			width: 8em;
			margin-right: 1em;
		}
	}

}

