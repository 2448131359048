@import '../../../assets/styles/variables';

.tournament-message {
	width: 36em;
	position: relative;
	cursor: pointer;
	
	.banner {
		display: block;
		width: 100%;
		height: auto;
	}

	.content{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 2em;

		background: #9098AE;
		width: 100%;
		height: 2em;

		font-family: 'SourceSansPro-Regular', sans-serif;
		color: white;
		line-height: 2em;

		box-shadow: inset 0 0.8em 0.4em rgba(0, 0, 0, 0.1);

		>span{
			font-size: 1.1em;
		}
	}
}
