.player-profile-gems-container {
    display: flex;
    align-items: center;
    background-color: #E386E4;
    border: 0.02em solid #9E026E;
    border-radius: 0.4em;
    margin: -1em 0.6em 0 0.2em;
    padding-left: 1em;
    margin-top: 0;
    margin-right: 0.6em;
    margin-bottom: 0.4em;
    padding-left: 1em;
    font-family: "SourceSansPro-Bold", sans-serif;
	text-align: right;
    position: relative;

    &.gems-shop-button {
        padding: 0.2em 0.2em 0.2em 2.5em;
        background-color: transparent;
        width: auto;
        border: none;

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .shop-icon {
            margin-right: 0.5em;
            width: 1.5em;
        }
        .gem-text{
            color: #A83793;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .gem-video-button {
        position: absolute;
        bottom: -1em;
        left: -1em;
        padding: 0;
        width: 2em;
        cursor: pointer;

        .content {
            width: 2em;
            height: 2em;
            margin: 0 auto;
        }
    }

    .header-shop-button {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0 0 0 0.5em;
        box-shadow: none;
        background: none;
    }

    @keyframes scale {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
    }

    .gem-video-icon {
        height: 2em;
        width: 2em;
        animation: scale 5s infinite;
        
    }
    .shop-icon {
        margin-right: 0.5em;
    }
    .gem-text{
        color: #FFF;
    }
    .gem-animation{
        position: relative;
        padding: 0 0.2em 0 0.2em;
        .gem-icon{
            height: 1.5em;
            width: 1.2em;
        }
    }
}
