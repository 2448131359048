@import '../../assets/styles/variables';

.gem-animation-container {
	position: absolute;
	left:-0.3em;
	top:0;
	height: 0;
	width: 0;

	.gem-animation-image {
		position: absolute;
		z-index: 105;
		opacity: 0;
		left:0;
		top:0;
		background: url(../../assets/images/global/gems.png) 0 0 no-repeat;
		background-size: contain;
		width: 2.7em;
		height: 2.7em;
		animation:
				gems-position-anim 0.5s linear,
				gems-fade-anim 0.5s linear;

		&.isExp {
			background: none;
			font-family: 'Quantify-Bold';
			color: @font-color-title;
			font-size: 2em;
			font-weight: bold;
			width: auto;
			height: auto;
			text-shadow: 0 0 0.6em @font-color-text-shadow;
		}
	}

	@keyframes gems-position-anim {
		70%{
			transform: translate(0,0) scale(0.8);
		}
		100%{
			transform: translateY(0) scale(0.6);
		}
	}

	@keyframes gems-fade-anim {
		0%  {opacity: 0;}
		40% {opacity: 1;}
		70% {opacity: 1;}
		100% {
			opacity: 0;
		}
	}
}
