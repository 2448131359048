@import '../../assets/styles/variables';

.rank-up {
    display: none;

	&.animate {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		z-index: 200;
		height: 100%;
		width: 100%;
		background: rgba(17,38,69,0.8);

		.react-bodymovin-container {
			width: 100%;
			height: 100%;
		}

		.number {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			width: 1em;
			height: 1.1em;
			margin-bottom: 0.4em;
			color: @font-color-title;
			font-size: 2.5em;
			font-family: 'SourceSansPro-Bold', sans-serif;
			text-shadow: 0 0 0.2em rgba(17,38,69,0.9);

			.old-rank {
				position: absolute;
				opacity: 0;
				animation: 2.3s rank-old-shake-grow-fade-out ease-in-out;
			}

			.new-rank {
				position: absolute;
				animation: 5.5s rank-new-fade-in-shrink ease-in-out;
			}
		}

		.rankup-message {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			margin-top: -1.8em;

			.title {
				margin-bottom: 4.2em;
			}

			.new-rank-text {
				animation: 7s rank-new-text-fade-in;
			}
		}
	}
}
