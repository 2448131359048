.share-button {
    padding: 0;
}

.share-button-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.share-icon {
    height: 1.5em;
    margin-right: 0.5em;
}