@import '../../../assets/styles/variables';

.game-details-challenge-leaderboard-component {
	height: 86.5%;

	h1 {
		margin-bottom: 0;
	}

	.leaderboard-wrapper {
		height: calc(100% - 6em);
		width: 100%;
	}

	.content-with-bg-full-screen {
		width: 100%;
		height: 100%;
	}

	.description{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		&--75 {
			width: 75%;
		}
	}
}