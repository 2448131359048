.result-waiting-component {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.loader-container {
		width: 6em;
		height: 6em;
	}

	.warning-label{
		margin-top: 1em;
		color: white;
	}

	.skip-button{
		opacity: 0;
		margin-top: 1em;
		width: 7em;

		&.visible{
			opacity: 1;
		}
	}
}
