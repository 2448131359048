.shop-button {
    transform: skew(0deg);
    width: 6em;
    height: 8em;
    text-align: center;
    padding: 0;
    box-shadow: 0.2em 0.2em 0.3em 0em #000000;

    background: linear-gradient(90deg, #14CCF8  0%, #1E88E5 100%);

    &.selected {
        text-shadow: none;
    }


    &.with-outline-text {
        .outline-block {
            position: absolute;
            left: -0.5em;
            top: -0.5em;
            right: -0.5em;
            bottom: -0.5em;
            border: 0.1em solid #14CCF8;
            border-radius: 1em;
        }

        .outline-text {
            position: absolute;
            bottom: -0.6em;
            width: 100%;
            color: #14CCF8;

            display: flex;
            align-items: center;
            justify-content: center;

            .outline-text-inner {
                transform: skew(10deg);
                padding: 0 0.5em;
                font-size: 0.6em;
                background: linear-gradient(0deg, 
                rgba(58, 96, 105, 0) 0%,
                #003C77 50%,
                #003C77 70%,
                rgba(58, 96, 105, 0) 0%);
            }
        }
    }

    .content {
        transform: none;
    }

    .button-title {
        transform: skew(10deg);
        margin: 0.4em 0.5em 0.2em 0.5em;
        font-family: 'Quantify-Bold', sans-serif;
        font-size: 0.6em;
        line-height: 1em;
    }

    .button-illus {
        transform: skew(10deg);
        height: 4em;
    }

    .button-cost {
        margin: 0.2em 0.5em 0.5em 0.5em;
        height: 1.5em;
        background-color: #013A71;
        border-radius: 0.2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-cost-inner {
        transform: skew(10deg);
        font-size: 0.7em;
    }

    &.gems-illus-1 {
        .button-illus {
            background: url("../../../assets/images/shop/gems-illus-1.png") 50% 50% no-repeat;
            background-size: contain;
        }
    }
    &.gems-illus-2 {
        .button-illus {
            background: url("../../../assets/images/shop/gems-illus-2.png") 50% 50% no-repeat;
            background-size: contain;
        }
    }
    &.gems-illus-3 {
        .button-illus {
            background: url("../../../assets/images/shop/gems-illus-3.png") 50% 50% no-repeat;
            background-size: contain;
        }
    }
    &.gems-illus-4 {
        .button-illus {
            background: url("../../../assets/images/shop/gems-illus-4.png") 50% 50% no-repeat;
            background-size: contain;
        }
    }
    &.gems-illus-5 {
        .button-illus {
            background: url("../../../assets/images/shop/gems-illus-5.png") 50% 50% no-repeat;
            background-size: contain;
        }
    }
    &.gems-illus-6 {
        .button-illus {
            background: url("../../../assets/images/shop/gems-illus-6.png") 50% 50% no-repeat;
            background-size: contain;
        }
    }

    .extra-banner {
        transform: skew(10deg);
        position: absolute;
        right: -0.7em;
        top: 1.4em;
        width: 2.5em;
        height: 1.5em;
        background: url("../../../assets/images/generic-icons/golden-banner.png") 50% 50% no-repeat;
        background-size: contain;
        color: #2C2F48;
        text-align: left;
        padding-left: 0.3em;
        letter-spacing: 0em;

        .extra-value {
            font-size: 0.6em;
            line-height: 1.3em;
        }
        .extra-label {
            font-size: 0.4em;
            line-height: 1em;
            text-transform: uppercase;
        }

    }

}
