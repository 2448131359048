@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.common-button {
	position: relative;
	padding: 1em;
	transform: skew(-10deg);
	width: 100%;

	text-align: center;
	color: @font-color-button;
	user-select: none;
	font-family: 'Lato', sans-serif;
	font-size: 1em;
	line-height: 1em;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-shadow: 0.05em 0.05em 0.05em rgba(0,0,0,0.1);

	box-shadow: 0.1em 0.1em 1em rgba(0,0,0,.5);
	border:0 solid transparent;
	border-radius: 0.3em;

	cursor: pointer;

	.content {
		transform: skew(10deg);
	}

	&.primary {
		background: @background-button-primary;
		&.selected{
			background: @background-button-primary-selected;
		}
	}

	&.secondary {
		background: @background-button-secondary;
		&.selected{
			background: @background-button-secondary-selected;
		}
	}

	&.disabled {
		color: @font-color-button-disabled;
		cursor: default;
		background: @background-button-disabled;
		box-shadow: none;
	}

	&.no-background {
		cursor: default;
		background: none;
		box-shadow: none;
	}

	&.two-line {
		padding: 0.5em 1em;
	}

	&.selected{
		text-shadow: 0.05em 0.05em 0.05em rgba(0,0,0,0.5);
		.pulsar-component {
			display: block;
		}
	}

	&.bounce-animation {
		animation: button-bounce-frames 150ms;
	}

	.pulsar-component{
		.back-border,
		.front-border{
			&.radius{
				border-radius: 0.3em;
			}
		}
	}

	@keyframes button-bounce-frames {
		0% { transform: scale(1) skew(-10deg); }
		50% { transform: scale(0.9) skew(-10deg); }
		100% { transform: scale(1) skew(-10deg); }
	}
	&.full-height {
		height: 100%;
	}

	&.freemium-v2-benefits {
		background: @premium-benefit-gradient !important;
		color: #000000;
		.content {
			font-size: 0.8em;
			max-width: 80%;
			position: relative;
			margin-left: -2em;
			line-height: 1em;
		}
		&:after {
			content: '';
			display: block;
			width: 2em;
			top: 0;
			right: 1.45em;
			height: 100%;
			position: absolute;
			background: url(../../../assets/images/generic-icons/premium-diamond.svg) 100% 50% no-repeat;
			background-size: contain;
			transform: skew(10deg);
		}
	}

	.premium-benefits {
		position: absolute;
		left: 0;
		top: -1.3em;
		height: 1.3em;
		width: 100%;
		background: @premium-benefit-gradient;
		border-radius: 0.3em;
		color: #000000;
		display: flex;
		justify-content: center;
		align-items: center;

		.premium-benefits-text {
			transform: skew(10deg);
			font-size: 0.5em;
			text-shadow: none;
			padding-right: 2.5em;

			background: url(../../../assets/images/generic-icons/premium-diamond.svg) 100% 50% no-repeat;
			background-size: contain;
		}

	}
}
