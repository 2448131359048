.settings-health-safety {
	width: 100%;
	max-width: 36em;
	font-size: 1em;
	font-family: helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
	line-height: 1.5;

	.mb-1 {
		display: block;
		margin-bottom: 1em;
	}
}