@import "../../../assets/styles/variables";

.test-connection-component {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	text-align: center;
	background-color: black;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.top-container {
		margin-top: 1em;
		.antstream_logo {
			height: auto;
			width: 14em;
		}

		h1 {
			font-size: 1.6em;
			text-align: center;
			margin-top: 0.5em;
		}
	}

	.test-connection-results {
		width: 100%;
		padding: 0 2em;
		text-align: left;

		.results-container {
			width: 100%;
			margin-bottom: 2em;
		}

		.test-connection-item {
			margin: 0 auto;
		}
	}

	.animation-container{

		.ant_logo{
			height: auto;
			width: 7em;
		}

		.loading-dot {
			display: inline-block;
			height: 0.9em;
			width: 0.9em;
			margin: 0 0.5em 1.5em;
			border: 0 solid;
			border-radius: 50%;
			background-color: #3D485A;

			&.selected {
				background-color: #89969F;
				animation: 0.5s loading-dot-in cubic-bezier(.17,.89,.49,1.99);
				animation-fill-mode: forwards;
			}
		}

		.server-icon{
			height: auto;
			width: 7em;
		}
	}

	.tips-container {
		text-align: left;
		padding:0 5em;

		.tip-item{
			padding: 0.5em;
		}
	}

	.buttons-container{
		margin-top: 1em;
		margin-bottom: 4em;

		.common-button{
			width: 10em;
			margin: 0 0.5em;
		}
	}


	@keyframes loading-dot-in {
		0% { transform: scale(0.5);}
		100% { transform: scale(1); }
	}
}
