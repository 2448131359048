@import '../../../assets/styles/variables';

.avatar-container {
  	position: relative;

	.notification {
		display: block;
		z-index: 10;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 3%;
		width: 17%;
		height: 17%;

		border: 0.07em solid #004082;
		border-radius: 1em;
		text-align: center;

		&.twitch-color{
			border-color: #65459B;
		}
		&.online{
			background: #1EC925;
		}
		&.offline{
			display: none;
		}
	}
	  
	.avatar-image-container {
		position: relative;
		border: 0.1em @avatar-border-color solid ;
		border-radius: 50%;
		overflow: hidden;
		height: 100%;
		width: 100%;
		transform: translateZ(0);


		.user-image {
			width: 100%;
    		height: 100%;
			object-fit: cover;

			&.pixelated {
				image-rendering: pixelated;
			}
		}
	}


	&--selected{
		.avatar-image-container {
			border-color: @avatar-border-selected-color;
		}
	}

	&.avatar-currentSelectedAvatar-container {
		.avatar-image-container {
			border-color: @font-color-outline-purple;
		}
		.avatar-current-selection-text-container {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.avatar-current-selection-text{
				cursor: pointer;
				color: white;
				text-shadow: 0 0 0.05em #fff, 0 0 0.1em #fff, 0 0 0.3em @avatar-border-selected-color, 0 0 0.2em @avatar-border-selected-color, 0 0 0.25em @avatar-border-selected-color, 0 0 0.30em @avatar-border-selected-color, 0 0 0.35em @avatar-border-selected-color;
				font-size: 1em;
			}
		}
	}

	.avatar-icon {
		text-shadow: 1px 1px black;
		color: @font-color-title;
		position: absolute;
		top: 72%;
		line-height: 0.1em;
		text-align: center;
		width: 100%;
		font-size: 1em;
	}
	.avatar-icon-image {
		width: 70%;
	}

	.twitch-icon{
		position: absolute;
		right: -5%;
		top: 65%;
		width: 100%;
		text-align: right;

		>img{
			width: 31%;
			height: auto;
		}
	}
}
