@import "assets/styles/variables";
@import "assets/styles/mixins";
@import "assets/styles/animations";
@import "assets/styles/fonts";
@import "assets/styles/global";
@import "assets/styles/icons";

@import "assets/styles/atari";

#input-manager-locked {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 99999;
	display: none;
}

.main {
	position: relative;
	height: 100%;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;

	.app-main-container {
		height: 100%;
		width: 100%;
		min-height: 100vh;

		background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);

		&.active {
			pointer-events:none;
		}
		&.no-gradient {
			background: none;
		}
	}
		.vertical-center {
			position: absolute;
			top: 50%;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			margin: auto;
			width: 100%;
			padding: 20px;

			h1 {
				font-size: 2em;
			}

			h2 {
				font-size: 1em;
			}
		}
		&.special-background {
			background: url(assets/images/global/special-background.jpg) 50% 50% no-repeat;
			background-size: cover;
		}
		&.auth-flow-background {
			background: url(assets/images/global/auth-flow-background.jpg) 50% 50% no-repeat;
			background-size: cover;
		}
}

.connection-lost-screen {
	&__h1 {
		text-align: center;
	}
	&__h2 {
		text-align: center;
	}
}

.xbox-platform .connection-lost-screen {
	h1 {
		font-size: 2em;
	}

	h2 {
		font-size: 1em;
	}

	@media screen and (min-width: 2500px) {
		h1 {
			font-size: 100px;
		}

		h2 {
			font-size: 60px;
		}
	}

	@media screen and (min-width: 3500px) {
		h1 {
			font-size: 120px;
		}

		h2 {
			font-size: 80px;
		}
	}
}

.safe-area-turned-on .app-main-container {
	padding: 5%;
	zoom: 0.8;
}
