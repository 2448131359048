.range-slider-component {
    position: relative;
    width: 14em;
    height: 2em;

    display: flex;
    flex-direction: row;
    align-items: center;

    .min,
    .max {
        margin: 0 0.5em;
    }

    .range-slider-inner {
        flex: 1;
        height: 100%;
        cursor: pointer;
        opacity: 0.7;

        &.selected {
            opacity: 1;
        }
    }

    .range-slider-background {
        position: relative;
        top: 0.9em;
        width: 100%;
        height: 10%;
        background-color: #3D485A;
        

        .range-slider-fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            background: #1e88e5;
            background-image: linear-gradient(to left, #1e88e5, #14ccf8);

        }

        .thumb {
            position: absolute;
            top: 0;
            width: 1em;
            height: 1em;
            transform: translate(-0.5em, -0.4em);
            background: #1e88e5;
            background-image: linear-gradient(to left, #1e88e5, #14ccf8);
            border-radius: 1em;
        }
    }
}