@import '../../../assets/styles/variables';

.follower-message {
	width: 36em;
	position: relative;
	cursor: pointer;

	background: @panel-background;
	box-shadow: 0.1em 0.1em 1em rgba(0,0,0,.5);
	padding: 0.7em;

	.content{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.content-left{
			>h2{
				line-height: 1em;
				font-weight: normal;
			}
			>p{
				padding: 0.7em 0.2em;
				.display-name{
					color: white;
				}
			}
		}
		.content-right{
			width: 8em;
			position: relative;

			.avatar-container {
				left: 0.15em;
				width: 7.5em;
				height: 7.5em;

				.avatar-image-container{
					border-width: 0.2em;
					border-color: #004082;
				}

				.notification{
					display: none;
				}
			}
		}
	}
}
