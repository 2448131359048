.user-header {
    margin-right: 2.5em;
	.playstation-platform & {
		margin-right: calc(2.5em + 5%); /* 5% is the safe area of playstation */
	}

	transform: translateZ(0);
	z-index: 102;
	cursor: default;
	user-select: none;
}
.xboxIcon {
	width: 3em;
}
