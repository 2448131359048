@import "../../../assets/styles/variables";

.list-container {
    overflow: auto;
    height: 100%;
    width: 100%;
	-webkit-overflow-scrolling: touch;
	animation: initial;

	.list-container-inner {
		width: 100%;
		position: relative;

		.list-object {
			width: 100%;

			.list-title {
				font-size: 1em;
				line-height: 1em;
				margin: 0 0 0 0.5em;
			}

			.list {
				width: 100%;

				.list-inner {
					display: flex;
					position: relative;
				}

				&.horizontal {
					overflow-x: scroll;

					.list-inner {
						width: auto;
						flex-direction: row;
					}
				}

				&.vertical {
					overflow: hidden;

					.list-inner {
						width: 100%;
						flex-direction: column;
					}
				}

				&.grid {
					overflow: hidden;

					.list-inner {
						width: 100%;
						flex-wrap: wrap;
					}
				}

			}
		}
	}
}