@import '../../../assets/styles/variables';

.giant-slayer-info-panel-component {
	position: relative;
	z-index: 3;
	width: 100%;
	height:100%;

	background: #102645 url(../../../assets/images/global/giant-slayer-banner-background.png) no-repeat 100% 50%;
	background-size: contain;

	.title {
		font-family: 'Quantify-Bold', sans-serif;
		color: @font-color-title;
		line-height: 1.12;
		margin-bottom: 0;
		text-shadow: 0 0 0.5em @font-color-text-shadow;
		font-size: 1.8em;
		letter-spacing: 0.08em;
	}

	.content-main {
		width: 100%;
		height: 100%;
		display: flex;

		.gs-panel-left {
			width: 70%;
			padding-top: 0.5em;
		}

		.title-block {
			position: relative;
			padding-left: 7.5em;
			padding-top: 0.5em;
			margin-bottom: 0.6em;
			height: 4.25em;
			color: #102645;

			&.t-1 {
				background: linear-gradient(90deg, @color-tier-1, fade(@color-tier-1, 0%) 100%);
			}
			&.t-2 {
				background: linear-gradient(90deg, @color-tier-2, fade(@color-tier-2, 0%) 100%);
			}
			&.t-3 {
				background: linear-gradient(90deg, @color-tier-3, fade(@color-tier-3, 0%) 100%);
			}
			&.t-4 {
				background: linear-gradient(90deg, @color-tier-4, fade(@color-tier-4, 0%) 100%);
			}
			&.t-5 {
				background: linear-gradient(90deg, @color-tier-5, fade(@color-tier-5, 0%) 100%);
			}

			.giant-slayer-icon {
				position: absolute;
				left: 4.5em;
				top: 0.5em;
				width: 3em;
				height: 3em;
				background: url(../../../assets/images/generic-icons/giant-slayer-dark.png) 50% 50% no-repeat;
				background-size: contain;
			}

			.title-text {
				font-family:'Quantify-Bold', sans-serif;
				font-size: 1.8em;
				margin-bottom: 0.1em;
				text-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
			}
			.giant-slayer-description {
				font-size: 0.9em;
				color: #ffffff;
			}
		}

		.challenge-details {
			display: flex;
			flex-direction: row;

			.game-image {
				margin-left: 1.2em;
				margin-right: 0.6em;
				width: 6.25em;
				height: 6.25em;
				border-radius: 0.15em;
			}
			.challenge-details-right {
				flex: 1;
				display: flex;
				flex-direction: column;
				.challenge-details-text {
					flex: 1;
				}
				.challenge-details-infos {
					height: 1.25em;
					display: flex;
					flex-direction: row;
				}
			}


			.challenge-title {
				padding-left: 1.2em;
				color: #ffffff;
				background: url(../../../assets/images/generic-icons/challenges-icon.png) 0 50% no-repeat;
				background-size: contain;
			}
			.challenge-text {
				width: 27em;
				margin-top: 0.5em;
				color: #d7d7d7;
				font-size: 0.9em;
			}
		}

		.difficulty {
			display: flex;
			justify-content: space-between;
			width: 6em;
			height: 1.25em;
			transform: skew(-10deg);

			span {
				display: inline-block;
				width: 18%;
				height: 100%;
				background: #314664;
				border-radius: 0.15em;
			}

			&.t-1 span.one {
				background: @color-tier-1;
			}
			&.t-2 span.one,
			&.t-2 span.two {
				background: @color-tier-2;
			}
			&.t-3 span.one,
			&.t-3 span.two,
			&.t-3 span.three {
				background: @color-tier-3;
			}
			&.t-4 span.one,
			&.t-4 span.two,
			&.t-4 span.three,
			&.t-4 span.four {
				background: @color-tier-4;
			}
			&.t-5 span {
				background: @color-tier-5;
			}
		}

		.iconChallengeType {
			margin-left: 1em;
			width: 2em;
			height: 100%;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: contain;
			&.type-boss_attack {
				background-image: url(../../../assets/images/challenge-type-icons/boss_attack.png);
			}
			&.type-boss_rush {
				background-image: url(../../../assets/images/challenge-type-icons/boss_rush.png);
			}
			&.type-carnage {
				background-image: url(../../../assets/images/challenge-type-icons/carnage.png);
			}
			&.type-collector {
				background-image: url(../../../assets/images/challenge-type-icons/collector.png);
			}
			&.type-deathwish {
				background-image: url(../../../assets/images/challenge-type-icons/deathwish.png);
			}
			&.type-pacifist {
				background-image: url(../../../assets/images/challenge-type-icons/pacifist.png);
			}
			&.type-pole_position {
				background-image: url(../../../assets/images/challenge-type-icons/pole_position.png);
			}
			&.type-score_attack {
				background-image: url(../../../assets/images/challenge-type-icons/score_attack.png);
			}
			&.type-speed_run {
				background-image: url(../../../assets/images/challenge-type-icons/speed_run.png);
			}
			&.type-survivor {
				background-image: url(../../../assets/images/challenge-type-icons/survivor.png);
			}
			&.type-time_attack {
				background-image: url(../../../assets/images/challenge-type-icons/time_attack.png);
			}
		}

		.iconImage {
			height: 1.25em;
		}

		.prize {
			width: 2em;
			height: 100%;
			background: url(../../../assets/images/global/icon-challenge-trophy-gems.png) 50% 50% no-repeat;
			background-size: contain;
			text-align: center;
			.prize-label {
				padding-top: 0.6em;
				color: #EFD97B;
				font-size: 0.4em;
			}
			.prize-value {
				color: #624C14;
				font-size: 0.5em;
			}
		}

		.vs-block {
			position: absolute;
			right: 0;
			top: 8em;
			width: 30em;
			height: 4.2em;
			padding-top: 2.1em;
			background: linear-gradient(270deg, #FF343C, fade(#FF343C, 0%) 100%);
			display: flex;
			flex-direction: row;

			.slayers-text,
			.giant-text {
				color: #ffffff;
				font-family: 'Quantify-Bold', sans-serif;
				font-size: 1.6em;
			}

			.slayers-text {
				width: 6em;
				text-align: right;
			}

			.vs-icon {
				margin: 0 1em;
				width: 2em;
				height: 2em;
				background: url(../../../assets/images/generic-icons/vs.png) 50% 50% no-repeat;
				background-size: contain;
				.css-filter-drop-shadow & {
					filter: drop-shadow(0 0 0.2em #000000);
				}
			}
		}

		.avatar-container {
			position: absolute;
			right: 1em;
			top: 0.5em;
			width: 9.5em;
			height: 9.5em;
			border: 0.2em solid #ffffff;
			border-radius: 50%;
			background-color: #ffffff;

			.avatar-image-container {
				border: 0.2em solid #000000;
				border-radius: 50%;
				background-color: #000000;
			}
			.user-image {
				border: 0.4em solid #FF343C;
				border-radius: 50%;
				background-color: #FF343C;
				height: 100%;
				display: flex;
			}
		}

		.giant-score-flag {
			position: absolute;
			right: 10em;
			top: 1em;
			width: 5em;
			height: 8em;
			text-align: center;
			padding-top: 2em;
			background: url(../../../assets/images/generic-icons/giant-score-flag.png) 50% 50% no-repeat;
			background-size: contain;
			color: #ffffff;
			font-family: 'Quantify-Bold', sans-serif;
			.score-title {
				font-size: 0.7em;
			}
			.giant-score-text {
				font-size: 0.7em;
			}
			.score-label {
				font-size: 0.5em;
			}
		}

		.giant-illus {
			position: absolute;
			top: 4.5em;
			left: 46.2em;
			width: 6em;
			height: 5.5em;
			background: url(../../../assets/images/generic-icons/giant-illus.png) 50% 50% no-repeat;
			background-size: contain;
		}

		.slayer-bar {
			position: absolute;
			left: 33em;
			top: 7.5em;
		}
	}

}
