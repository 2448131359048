@import '../../../assets/styles/variables';

.control {
    height: 100%;
    width: 100%;
    .image-container {
        position: relative;
        height: 100%;
        width: 100%;
        .loader-container { 
            width: 100%;
            height: 100%;
            .react-bodymovin-container{
                z-index: 1;
            }
        }
        .react-bodymovin-container,
        svg,
        img {
            height: 100%;
            width: auto;
            max-width: 100%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            .playstation-platform & {
                max-width: initial; /* do not squeeze the image if screen width is short */
            }

            &.controls {
                z-index: 1;
            }
        }

        .input-element.hidden {
            display: none;
        }
    }

    &.touch {
        .gradient-top-border {
            position:absolute;
            height: 100%;
            max-height: 17.3em;
            width: 32em;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) ;
            padding: 0;

            .inner {
                width: 100%;
                height: 100%;
                background: @background-solid;
                border: 0.5em solid @panel-background;
                border-radius: 0.2em;
            }
        }

        img {
            top: 1em;
        }
    }

    &.keyboard {
        display: flex;
        flex-direction: row;
        .key-list {
            flex: 1.5;
            margin-right: 1em;
            columns: 2;
            align-self: center;

            .key-entry {
                display: inline-block;
                width: 100%;
            }

            .key-entry-inner {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.2em 0;
            }

            .key-label {
                flex: 1;
                text-align: right;
                margin-right: 1em;
                color: #efefef;
            }
            .key-illus {
                flex: 1;

                img {
                    height: 1.5em;

                    &.Key_Enter {
                        height: 3em;
                    }
                }
            }

            // display smaller elements if:
            // - there are many keys
            // - and the screen aspect ratio is very large (not much height available)
            &.long-list {
                @media screen and (min-aspect-ratio: ~"9/5") {
                    .key-label {
                        font-size: 0.75em;
                    }
                    .key-illus {
                        img {
                            height: 1em;
                            &.Key_Enter {
                                height: 2em;
                            }
                        }
                    }
                }
            }
            &.very-long-list {
                @media screen and (min-aspect-ratio: ~"9/5") {
                    columns: 3;
                    .key-label {
                        font-size: 0.6em;
                    }
                    .key-illus {
                        img {
                            height: 0.8em;
                            &.Key_Enter {
                                height: 1em;
                            }
                        }
                    }
                }
            }

        }
        .image-container {
            flex: 1;
        }
    }

}