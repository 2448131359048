@import '../../../assets/styles/variables';

.game-details-challenges-component {
	display: block;
    flex-direction: column;
    justify-content: space-between;
	width: 100%;
	height: 100%;

	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	.challenge-summary-component {
		width: 96%; /* because the right edge is cut off*/
	}

	.top-container {
		height: 12.5em;
		margin: 0 -2.8em;

		.challenge-info {
			display: none;
			justify-content: space-between;
			height: 100%;
			margin: 0 2.8em;
		}

		.giant-slayer-info-panel-component {
			display: none;
			min-height: 12.5em;
		}

		&.challenge-info-view {
			.challenge-info {
				display: flex;
			}
			.giant-slayer-info-panel-component {
				display: none;
			}
		}
		&.giant-slayer-info-view {
			.challenge-info {
				display: none;
			}
			.giant-slayer-info-panel-component {
				display: block;
			}
			.safe-area-turned-on & {
				margin: 0 auto;
			}
		}


		.content-left,
		.content-right {
			display: inline-block;
			width: 50%;
			height: 100%;
			vertical-align: top;
		}
		.content-left {
			animation: 0.25s panel-slide-right ease-in-out;

			.left-inner {
				display: flex;
				flex-direction: column;
				height: 100%;

				h2 {
					font-family: "LatoBold", sans-serif;
					letter-spacing: 0.069em;
				}

				.autoscroll-box {
					max-height: 4.5em;
					margin-bottom: 1em;
				}

				.description {
					max-width: 85%;
					white-space: pre-line;
					margin-bottom: 0;
				}

				.challenge-options {
					display: flex;
					flex-direction: row;
					.share-button {
						width: 7em;
					}
				}

				.to-unlock {
					margin-right: 2em;
					display: flex;
					align-items: center;
					color: @font-color-title;
					font-family: 'SourceSansPro-Bold', sans-serif;
					letter-spacing: 0.069em;

					img {
						height: 1.1em;
						margin-left: 0.2em;
					}
				}
			}
		}
		.content-right {
			animation: 0.25s panel-slide-left ease-in-out;

			.summary-container {
				display: flex;
				flex-direction: column;
				position: relative;
				height: 100%;
				background: @panel-background;
				box-shadow: 0 0 0.5em 0.15em rgba(0,0,0,0.3);
				text-align: right;
				transform: skew(-10deg);
				&.t-2 .top-gradient {
					background-image: linear-gradient(91deg, #73b112, #a9e03a);
				}
				&.t-3 .top-gradient {
					background-image: linear-gradient(91deg, #ff950a, #ffd829);
				}
				&.t-4 .top-gradient {
					background-image: linear-gradient(91deg, #ea1e8e, #fd2872);
				}
				&.t-5 .top-gradient {
					background-image: linear-gradient(91deg, #663890, #ae5cff);
				}

				.top-gradient {
					height: 12%;
					padding: 0 0.5em;
					background: linear-gradient(to right, #32c7c2 0%, #00d9ff 100%);
					border-radius: 0.15em 0.15em 0 0;

					.challenge-type-container{
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 100%;

						.difficulty {
							display: flex;
							justify-content: space-between;
							width: 2.35em;
							height: 0.56em;

							span {
								display: inline-block;
								width: 18%;
								height: 100%;
								background: @background-solid;

								&:first-child {
									border-radius: 0.1em 0 0 0.1em;
								}
								&:last-child {
									margin-right: 0;
									border-radius: 0 0.1em 0.1em 0;
								}
							}

							&.t-1 span.one,
							&.t-2 span.one,
							&.t-2 span.two,
							&.t-3 span.one,
							&.t-3 span.two,
							&.t-3 span.three,
							&.t-4 span.one,
							&.t-4 span.two,
							&.t-4 span.three,
							&.t-4 span.four,
							&.t-5 span {
								background: #efefef;
							}
						}
					}

					.challenge-type {
						display: flex;
						align-items: center;
						height: 100%;
						color: @font-color-title;
						font-family: 'SourceSansPro-Bold', sans-serif;
						text-transform: capitalize;
						letter-spacing: 0.069em;
						transform: skew(10deg);

						img {
							height: 1.2em;
							width: 1.2em;
							margin-right: 0.3em;
						}
					}
				}

				.inner {
					display: flex;
					justify-content: space-between;
					align-items: center;
					text-align: center;
					transform: skew(10deg);
					border-radius: 0 0 0.15em 0.15em;
					height: 100%;

					.summary-info {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 48%;

						&.link {
							cursor: pointer;
						}

						img {
							max-width: 100%;
							max-height: 4.65em;
						}

						.number {
							display: block;
							margin-top: 0.1em;
							color: @font-color-title;
							font-size: 2em;
							font-family: "LatoBold", sans-serif;
							letter-spacing: 0.069em;
						}

						.badge {
							height: 4.65em;

							img.selected {
								transform: scale(1.1);
							}

							&.to-achieve {
								opacity: 0.2;
							}
						}
					}
				}
			}
		}
	}

	.list-container {
		flex: 1;
		animation: 0.5s fade-in ease-in-out;

		.list-object {
			&.list-listChallenges {
				.horizontal {
					margin-top: -2em;
					padding-top: 2em;
				}
			}
		}
		.list-inner {
			padding: 0 0 0 1em;

			 .tile-challenge-component {
				 width: 7.5em;
				 height: 7.5em;
				 .title {
					 font-size: 0.8em;
				 }
			 }
		}

		.list-title.list-listChallenges {
			padding-left: 1.2em;
			background: url(../../../assets/images/generic-icons/challenges-icon.svg) 0 50% no-repeat;
			background-size: contain;
		}
		.list-title.list-giantSlayer {
			padding-left: 1.2em;
			background: url(../../../assets/images/generic-icons/giant-slayer.svg) 0 50% no-repeat;
			background-size: contain;
		}
	}
	.game-details-challenge-wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		.share-button {
			position: absolute;
			top: -1em;
			right: -0.5em;
			transform: skew(0);
			font-size: 0.8em;
			display: flex;
			width: 40%;
			justify-content: center;

			.content {
				transform: skew(0);
				padding: 0.1em 0;
			}

			.share-icon {
				height: 0.8em;
				margin-right: 0.2em;
			}
		}
	}
	.tile:first-of-type .share-button {
		width: 20%;
		.text-container {
			display: none;
		}
	}
}

.game-details-challenges-component--no-share {
	.list-container {
		margin-top: 2em;
	}
	.challenge-summary-component {
		max-height: 14.2em;
    	min-height: 8em;
	}
}
