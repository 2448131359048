.osk {
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 25%;

    .osk-inner {
        position: relative;
        margin: 0 auto;
        max-width: 1920px;
        height: 100%;
        background-color: #123557;

        .osk-background {
            position: absolute;
            width: 100%;
            height: 100%;

            .hg-button {
                background: #000000;
            }
        }
    
        .simple-keyboard.hg-theme-default {
            position: relative;
            padding: 0;
            margin: 0 auto;
            max-width: 100%;
            height: 100%;
            background-color: transparent;
        }

        div.hg-button {
            position: absolute;
            height: 16%;
            width: 6%;
            font-size: 1em;
            border-radius: 5px;
            background: linear-gradient(180deg, #0087f2 0%, #023177 100%);
            color: #ffffff;
            border: none;

            &.hg-activeButton {
                background: linear-gradient(180deg, #023177 0%, #0087f2 100%);
            }

            &.hg-standardBtn.hg-keyMarker,
            &.hg-functionBtn.hg-keyMarker {
                box-shadow: 0 0 0 2px #08FFFF;
            }

            &[data-skbtn="F1"] { top: 1%; left: 8%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F2"] { top: 1%; left: 15%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F3"] { top: 1%; left: 22%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F4"] { top: 1%; left: 29%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F5"] { top: 1%; left: 36%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F6"] { top: 1%; left: 43%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F7"] { top: 1%; left: 50%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F8"] { top: 1%; left: 57%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F9"] { top: 1%; left: 64%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F10"] { top: 1%; left: 71%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F11"] { top: 1%; left: 78%; height: 8%; font-size: 0.7em; }
            &[data-skbtn="F12"] { top: 1%; left: 85%; height: 8%; font-size: 0.7em; }

            &[data-skbtn="1"] { top: 10%; left: 8%; }
            &[data-skbtn="2"] { top: 10%; left: 15%; }
            &[data-skbtn="3"] { top: 10%; left: 22%; }
            &[data-skbtn="4"] { top: 10%; left: 29%; }
            &[data-skbtn="5"] { top: 10%; left: 36%; }
            &[data-skbtn="6"] { top: 10%; left: 43%; }
            &[data-skbtn="7"] { top: 10%; left: 50%; }
            &[data-skbtn="8"] { top: 10%; left: 57%; }
            &[data-skbtn="9"] { top: 10%; left: 64%; }
            &[data-skbtn="0"] { top: 10%; left: 71%; }
            &[data-skbtn="{bksp}"] { top: 10%; left: 78%; }

            &[data-skbtn="q"] { top: 28%; left: 10%; }
            &[data-skbtn="w"] { top: 28%; left: 17%; }
            &[data-skbtn="e"] { top: 28%; left: 24%; }
            &[data-skbtn="r"] { top: 28%; left: 31%; }
            &[data-skbtn="t"] { top: 28%; left: 38%; }
            &[data-skbtn="y"] { top: 28%; left: 45%; }
            &[data-skbtn="u"] { top: 28%; left: 52%; }
            &[data-skbtn="i"] { top: 28%; left: 59%; }
            &[data-skbtn="o"] { top: 28%; left: 66%; }
            &[data-skbtn="p"] { top: 28%; left: 73%; }

            &[data-skbtn="a"] { top: 46%; left: 12%; }
            &[data-skbtn="s"] { top: 46%; left: 19%; }
            &[data-skbtn="d"] { top: 46%; left: 26%; }
            &[data-skbtn="f"] { top: 46%; left: 33%; }
            &[data-skbtn="g"] { top: 46%; left: 40%; }
            &[data-skbtn="h"] { top: 46%; left: 47%; }
            &[data-skbtn="j"] { top: 46%; left: 54%; }
            &[data-skbtn="k"] { top: 46%; left: 61%; }
            &[data-skbtn="l"] { top: 46%; left: 68%; }
            &[data-skbtn="{enter}"] { top: 46%; left: 75%;  width: 10%;}

            &[data-skbtn="z"] { top: 64%; left: 14%; }
            &[data-skbtn="x"] { top: 64%; left: 21%; }
            &[data-skbtn="c"] { top: 64%; left: 28%; }
            &[data-skbtn="v"] { top: 64%; left: 35%; }
            &[data-skbtn="b"] { top: 64%; left: 42%; }
            &[data-skbtn="n"] { top: 64%; left: 49%; }
            &[data-skbtn="m"] { top: 64%; left: 56%; }

            &[data-skbtn="{space}"] { top: 82%; left: 25%; width: 50%; }

        }
    }
}
