@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.subscription-comparisons-popup {
    position: relative;
    width: 44em;
    max-width: 40em;
    &:after{
        content: "";
        position: absolute;
        top: 0.25em;
        left: -1.3em;
        background-image: url("../../../assets/images/popups/popups-mask-background.png");
        background-size: cover;
        z-index: -1;
        width: 100%;
        height: 100%;
        transform: skew(-5deg);
    }

    .header-box {
        position: absolute;
        right: 2.6em;
    }
    .header-main-text {
        font-family: 'Quantify-Bold', sans-serif;
        margin-bottom: 0;
        font-size: 2em;
        line-height: 1;
    }
    .header-price-text {
        font-size: 0.9em;
        text-align: right;
        margin-bottom: 0;
        font-weight: bold;
    }

    .subscription-comparisons-popup-buttons {
        justify-content: flex-end;
        padding-right: 1.3em;
        
        .popup-button {
            font-family: Source Sans Pro, sans-serif;
            font-size: 0.95em;
            font-weight: 400;
            letter-spacing: 0px;
            height: 2.8em;
            width: 10.9em;
        }
        .play-for-free-button {
            color: #fff;
            background: #005BA4;
        }
        .premium-button {
            color: #000;
            background: linear-gradient(135deg, #DE975E 0%, #ECBB7D 21.87%, #FFF6A2 34.24%, #E9B664 49.48%, #D3983F 74.48%, #B57223 80.73%, #E4A860 100%);
        }
    }

    .left-col {
        max-width: 50%;
        display: flex;
        flex-direction: column-reverse;

        &__text-elem {
            display: block;
        }
        &__text {
            margin-bottom: 0;
            font-family: Quantify, sans-serif;
            font-weight: 400;
            letter-spacing: 0px;
            text-align: left;
            text-shadow: #000000F2 3px 3px 7px;
            font-size: 2em;
        }
    }
    .right-col {
        max-width: 50%;
        display: flex;
        flex-direction: column-reverse;
    }
    .subscription-comparisons-popup-bg {
        z-index: -1;
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: url("../../../assets/images/popups/popups-splash-full.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &--V3 {
            background-image: url("../../../assets/images/popups/freemium-v3-bg.png");
            background-position: -1em;
        }
    }
    .slot-with-coin {
        font-family: Lato, sans-serif;
        position: absolute;
        height: 8em;
        width: 8em;
        top: -1.2em;
        right: -2.2em;
        background-image: url("../../../assets/images/popups/poup-slot-main-empty-coin.png");
        background-size: cover;
        &__price-container {
            position: absolute;
            z-index: 1;
            letter-spacing: 0px;
            top: 3.6em;
            left: 1.7em;
            font-weight: 800;
        }
        &__pounds {
            font-size: 1em;
        }
        &__pennies {
            font-size: 0.6em;
        }
        &__price {
            font-size: 1.4em;
            font-weight: 800;
            letter-spacing: 0px;
            position: absolute;
            z-index: 1;
            top: 2.4em;
            left: 1.4em;
        }
    }
    .subscription-comparisons-popup-content {
        display: flex;
        justify-content: space-between;
        padding: 0 1em 0 0.5em;
        min-height: 15em;
    }
    .prices-line {
        margin-bottom: 0;
        text-align: right;
        font-family: Lato, sans-serif;
        font-size: 1em;
        line-height: 1.2em;
        font-weight: 700;
        letter-spacing: 0px;
    }
    .premium-features {
        p {
            margin-bottom: 0;
            text-align: right;
            font-family: Lato, sans-serif;
            font-size: 1em;
            line-height: 1.2em;
            font-weight: 700;
            letter-spacing: 0px;
        }
    }
}

.subscription-comparisons-popup-1300 {
    .left-col__text {
        font-size: 1.5em;
    }
}

.subscription-comparisons-popup-not-enough {
    .left-col__text {
        font-size: 1.5em;
        text-align: center;
    }
}

.subscription-comparisons-popup--V3 {
    .left-col__text {
        font-size: 1.6em;
        margin-bottom: -0.4em;
    }
    .right-col {
        margin-right: 1em;
    }
}
