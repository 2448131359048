.my-profile-challenges-component {
	height: 100%;
	.my-profile-challenges-component-not-found {
		padding-left: 2em;
	}
	.list-container {
		.playstation-platform & { // in order to respect the safe area
			height: calc(100% - 3em);
		}
		.list-object {
			.list-title {
				font-size: 1.5em;
				padding-left: 2em;

				&.list-giant-slayer-live,
				&.list-giant-slayer-win,
				&.list-giant-slayer-loss {
					padding-left: 3em;
					background: url(../../../assets/images/generic-icons/giant-slayer.svg) 2em 50% no-repeat;
					background-size: contain;
				}
				&.list-issued,
				&.list-waiting,
				&.list-win,
				&.list-loss,
				&.list-draw,
				&.list-solo {
					padding-left: 3em;
					background: url(../../../assets/images/generic-icons/challenges-icon.svg) 1.8em 50% no-repeat;
					background-size: contain;
				}
			}

			.list-inner {
				padding: 0.5em 0 0.5em 2em;

				.mp-challenge { // TODO check if should be here
					width: 19em;
					margin: 1em;
				}
			}
		}
		.playstation-platform & {
			.list {
				padding-left: 2em;
			}
		}
	}
}
