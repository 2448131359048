@import "variables";

.popup-background-mixing {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: skew(-10deg);
  border: 0.2em solid #14ccf8;
  background: linear-gradient(135deg, #00356b, #00407e);
  //border-image: linear-gradient(90deg,#14CCF8 ,#1E88E5);
  //border-image-slice: 1;
  border-radius: 0.4em;
}

.bg-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.1;
  width: 100vw;
  min-height: 100%;
  height: auto;
  pointer-events: none;

  .safe-area-turned-on & {
    width: 100%;
  }

  img {
    min-width: 100%;
    background-color: transparent;
   -webkit-animation: kenburns-top 50s ease-out alternate both;
    animation: kenburns-top 50s ease-out alternate both;
  }
}
.bg-full-screen-lowspec {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.1;
  width: 100vw;
  min-height: 100%;
  height: auto;
  pointer-events: none;

  .safe-area-turned-on & {
    width: 100%;
  }

  img {
    min-width: 100%;
    background-color: transparent;
  }
}
/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
    opacity: 0;
  }
  0.2% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
    opacity: 0;
  }
  0.2% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

.content-with-bg-full-screen {
  z-index: 1;
  height: 100%;
  padding: 0 2.8em;
  .safe-area-turned-on & {
    h1 {
      font-size: 1.5em;
    }
  }
}

.gradient-top-border {
  position: relative;
  padding: 1em 0.67em;
  background: @panel-background;
  border-radius: 0 0 0.15em 0.15em;
  box-shadow: 0 0 0.5em 0.15em rgba(0, 0, 0, 0.3);

  &:after {
    position: absolute;
    top: -0.15em;
    left: 0;
    width: 100%;
    height: 0.15em;
    content: "";
    background: linear-gradient(to right, #3adadb 0%, #1f9677 100%);
    border-radius: 0.15em 0.15em 0 0;
  }
}
