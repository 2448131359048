.my-profile-favourites {
	display: flex;
    flex-direction: column;
    justify-content: space-between;
	padding: 0 2.8em;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.top-container {
		width: 100%;
		height: 13em;
	}

	.list-container {
		height: 10em;

		.list-inner {
			.tile-game {
				margin: 0.8em;
				height: 8em;
				width: 8em;
			}
		}
	}
}
