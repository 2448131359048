@import '../../../assets/styles/variables';

.leaderboard-message {
	width: 100%;
	cursor: pointer;

	.message-inner {
		display: flex;
		justify-content: space-between;
		background: @panel-background;
		box-shadow: 0 0 0.5em 0.15em rgba(0, 0, 0, 0.3);

		.left-content {
			line-height: 1.4;
			padding: 0.3em 0.5em 0.7em;
			font-family: 'SourceSansPro-Regular', sans-serif;
		}

		.right-content {
			align-self: flex-end;
			height: 11em;

			img {
				height: 100%;
			}

			.avatar-container {
				right: 0.15em;
				width: 6em;
				height: 6em;
			}
		}
	}

	.message-inner2 {
		display: flex;
		justify-content: space-between;
		background: @panel-background;
		height: 7em;

		.left-content2 {
			line-height: 1.4;
			padding: 0.3em 0.5em 0.7em;
			font-family: 'SourceSansPro-Regular', sans-serif;

			.avatar-container {
				right: 0.15em;
				width: 4em;
				height: 4em;
			}
		}

		.right-content2 {
			align-self: flex-end;
			height: 7em;

			img {
				height: 100%;
			}


		}
	}

	b {
		font-weight: bold;
		color: white;
	}

	h2 {
		font-family: 'LatoBold', sans-serif;
	}


}