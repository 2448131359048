.settings-privacy-policy {
	width: 100%;
	max-width: 36em;
	font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;

	.bulleted-list li {
		list-style-type: disc;
		margin-left: 1em;
	}
	.fake-focusable {
		opacity: 0;
		width: 0;
		height: 0;
		padding: 1px 0;
		margin: 0;
		.pulsar-component {
			display: none;
		}
	}

	.font_5 {
		font-size: 1em;
	}
	p {
		font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;
	}

	.font-weight-bold {
		font-weight: bold;
	}
	.font-size-18 {
		font-size: 1em;
		width: 100%;
	}
	.font-size-16 {
		font-size: 0.8em;
	}
	.font-size-12 {
		font-size: 0.6px;
	}
	.text-decoration-underline {
		text-decoration: underline;
	}
	.fake-link {
		color: #14ccf8;
		text-decoration: underline;
	}

	.theTable {
		width: 100%;
		display: table;
		border-top-width: 0;
		border-right-width: 0;
		border-left-width: 0;
		border-radius: 0px;
		margin: 0;
		padding: 0;
		position: relative;
		border-spacing: 0;
		border-collapse: collapse;
		font-size: 0.9em;
	}
	.table-cell {
		display: table-cell;
		border-color: rgba(199,199,199,1);
		border-style: solid;
		border-width: 1px;
		padding: 7px;
	}
	.mb-05 {
		margin-bottom: 0.5em;
	}
	.mb-1 {
		margin-bottom: 1em;
	}
	.w-100 {
		width: 100%;
	}
}