.select-avatar-component {
	height: 100%;
	.select-avatar-box {
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.select-avatar-skip-button {
		max-width: 4em;
		position: absolute;
		right: 0;
		top: -0.5em;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
	.select-avatar-component-header {
		height: 6em;
	}
	.avatar-list-container {
		width: 100%;
		height: calc(100% - 6em);
		.list-inner {
			.avatar-container {
				margin: 0.8em;
				height: 8em;
				width: 8em;
				transition: transform 500ms;

				&.avatar-container--selected{
					transform: scale(1.2);
				}
			}
		}
	}

	.buttons-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin:1em;

		.common-button {
			width: 20%;

			margin-right: 1em;
			&:last-of-type{
				margin-right: 0;
			}
		}
	}
}
