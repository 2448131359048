@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.tournaments-list-item-component {
	position: relative;
	margin-bottom: 2em;
	cursor: pointer;
	min-height: 16.222em; // 469.18px

	.group-title{
		width: 100%;
		text-align: right;
		margin-bottom: 0.3em;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;

		>img{
			width: 1.5em;
			height: auto;
			padding-left: 0.5em;
		}
	}

	.content{
		position: relative;

		.pulsar-component {
			.back-border, .front-border {
				border-top-left-radius: 3em;
			}
		}

		.banner {
			position: relative;
			height: 16em;
			width: 100%;
			border-top-left-radius: 3em;
		}

		.details {
			position: absolute;
			z-index: 1;
			bottom: 0;
			display: flex;
			align-items: center;
			width: 100%;
			height: 4.2em;
			padding-left: 0.5em;

			.title {
				font-family: Quantify-Bold, sans-serif;
				font-size: 1.5em;
				color: #ffffff;
				margin-left: 0.5em;
			}

			.icon {
				width: 3em;
			}
		}
	}

	&.selected {
		.content{
			.pulsar-component {
				display: block;
			}
		}
	}
}
