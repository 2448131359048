@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.tournament-player-component {
	position: relative;
	width: 5.7em;

	&.friends{
		font-size: 1.6em;
	}

	.user-details {
		height: 100%;
		width: 100%;
		padding: 0.5em;

		.avatar-container {
			width: 4.7em;
			height: 4.7em;

			.avatar-image-container{
				border: 0.2em #004082 solid ;
			}

			.notification {
				width: 15%;
				height: 15%;
				border-width: 0.1em;
				right: 7%;
			}
		}

		.username {
			width: 100%;
			text-align: center;
			overflow: hidden;
			word-break: break-word;

			span {
				display: inline-block;
				width: 100%;
				color: #A0A0A0;
				font-family: 'SourceSansPro-Bold', sans-serif;
				font-size: 0.7em;
			}
		}

		.rank-container{
			position: absolute;
			top: 3.6em;
			right: 3.8em;

			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-family: 'SourceSansPro-Bold', sans-serif;
			text-align: right;

			.rank-text{
				color: #A98630;
				margin-right: -0.1em;
				font-size: 0.9em;
			}
			.rank-icon{
				height: 1.5em;
				width: auto;
			}
		}
	}

	&.twitch{
		.avatar-container {
			.avatar-image-container{
				border: 0.2em #65459B solid;
			}
		}
	}

	&.selected {
		.avatar-container {
			.avatar-image-container{
				border: 0.2em @font-color-outline-yellow solid ;
			}
		}
	}
}
