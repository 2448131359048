.leaderboard-position-component {
	display: flex;
	align-items: center;

	.icon {
		height: 4em;
	}

	.position-container {
		margin-top: 0.9em;
		margin-left: 0.2em;
	}

	.rank-text {
		font-family: 'Quantify-Bold', serif;
		font-size: 2.3em;
		color: #1C98EA;
	}

	.nth-text {
		font-family: 'Quantify-Bold', serif;
		font-size: 1.5em;
		color: #1C98EA;

	}
}