@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.tile-achievement-component {
	display: flex;
	flex-direction: column;
	width: 12.3em;
	margin: 0.5em;
	background: @panel-background;
	border-radius: 0.15em;
	box-shadow: 0 0.1em 0.5em 0.05em rgba(0,0,0,0.6);
	transition: transform 500ms;

	.top {
		padding: 0.12em;
		background: #efefef;

		.achievement-title {
			padding: 0.1em 0.6em;
			margin: 0;
			text-align: left;
			color: @background-solid;
			font-family: "LatoBold", sans-serif;
		}

		.progress {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			overflow: hidden;
			height: 10em;
			background: #151f2d;

			.bg-img {
				position: absolute;
				width: 100%;
				height: 100%;

				img {
					opacity: 0.2;
					height: 100%;
					width:100%;
					transition: opacity 0.3s ease-in-out;
				}
			}
			.circle {
				transition: opacity 0.3s ease-in-out;

				.number {
					position: relative;
					z-index: 1;
					color: @font-color-title;
					font-size: 1.75em;
					font-family: "LatoBold", sans-serif;
					letter-spacing: 0.04em;
					width:100%;
					text-align: center;
				}

				svg {
					position: absolute;
				}
			}

			.progress-outer {
				display: flex;
				right:-3.38em;
				top:0.5em;
				position: relative;
				width: 6em;
				height: 6em;

				.CircularProgressbar {
					width: 100%;

					.CircularProgressbar-path {
						stroke: @font-color-title;
						stroke-linecap: round;
						transition: stroke-dashoffset 0.5s ease 0s;
					}

					.CircularProgressbar-trail {
						stroke: #32395d;
					}

					.CircularProgressbar-text {
						display: none;
					}

					.CircularProgressbar-background {
						fill: @background-solid;
					}
				}
			}
		}
	}

	.bottom {
		padding: 0.25em;

		.top-line {
			display: flex;
			justify-content: space-between;

			.left {
				position: relative;
				width: 0.25em;
				border-bottom: 0.05em solid #efefef;
				left: -0.09em;
			}

			.right {
				position: relative;
				width: 0.25em;
				border-bottom: 0.05em solid #efefef;
				right: -0.09em;
			}
		}

		.description {
			width: 100%;
			height: 4.5em;
			padding: 0.1em 0.4em;
			border: 0.05em solid #efefef;
			border-width: 0 0.05em;
			text-align: left;
			color: @font-color-title;

			p {
				margin-bottom: 0;
			}
		}

		.bottom-line {
			display: flex;
			position: relative;

			.corner {
				width: 0.6em;
				height: 0.6em;
				overflow: hidden;

				span {
					display: block;
					width: 1.5em;
					height: 0.3em;
					border: 0.05em solid #efefef;
					border-width: 0.05em 0;
				}

				&.left{
					span {
						margin: 0.14em 0 0 -0.615em;
						transform: rotate(45deg);
					}
				}

				&.right {
					span {
						margin: 0.44em 0 0 -0.6em;
						transform: rotate(-45deg);
					}
				}
			}

			.middle-line {
				flex: 1;
				border-bottom: 0.05em solid #efefef;
			}
		}
	}

	&.tier-2{
		.top {
			background-image: linear-gradient(116deg, #46c6e0, #70c2ae);
		}
		.progress .progress-outer .progress-line:after,
		.bottom .description,
		.bottom .top-line .line,
		.bottom .bottom-line .corner span,
		.bottom .bottom-line .middle-line {
			border-color: #46c6e0;
		}
		.CircularProgressbar .CircularProgressbar-path {
			stroke: #46c6e0;
		}
	}
	&.tier-3{
		.top {
			background-image: linear-gradient(116deg, #73fe85, #73d648);
		}
		.progress .progress-outer .progress-line:after,
		.bottom .description,
		.bottom .top-line .line,
		.bottom .bottom-line .corner span,
		.bottom .bottom-line .middle-line {
			border-color: #73fe85;
		}
		.CircularProgressbar .CircularProgressbar-path {
			stroke: #73fe85;
		}
	}
	&.tier-4{
		.top {
			background-image: linear-gradient(116deg, #c43bff, #6558df);
		}
		.progress .progress-outer .progress-line:after,
		.bottom .description,
		.bottom .top-line .line,
		.bottom .bottom-line .corner span,
		.bottom .bottom-line .middle-line {
			border-color: #c43bff;
		}
		.CircularProgressbar .CircularProgressbar-path {
			stroke: #c43bff;
		}
	}
	&.tier-5{
		.top {
			background-image: linear-gradient(116deg, #ffd900, #e2a437);
		}
		.progress .progress-outer .progress-line:after,
		.bottom .description,
		.bottom .top-line .line,
		.bottom .bottom-line .corner span,
		.bottom .bottom-line .middle-line {
			border-color: #ffd900;
		}
		.CircularProgressbar .CircularProgressbar-path {
			stroke: #ffd900;
		}
	}


	&.completed {
		.top {
			.progress {
				.bg-img img {
					opacity: 1;
				}

				.circle {
					opacity: 0;
				}
			}
		}

		.circle svg {
			display: none;
		}
	}

	&.percentage {
		.progress .circle {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			opacity: 1;
			width: 84%;
			height: 84%;
			background: #112645;
			border-radius: 50%;

			svg {
				stroke: #efefef;
			}
		}

		&.tier-2 .progress .circle svg {
			stroke: #46c6e0;
		}

		&.tier-3 .progress .circle svg {
			stroke: #73fe85;
		}

		&.tier-4 .progress .circle svg {
			stroke: #c43bff;
		}

		&.tier-5 .progress .circle svg {
			stroke: #ffd900;
		}
	}

	&.selected {
		.pulsar-component {
			display: block;
			.back-border,
			.front-border{
				border-width: 0.13em;
			}
		}

		transform: scale(1.1);
	}
}
