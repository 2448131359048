@import "../../assets/styles/variables";

.payment-plan-item {
	position: relative;
	display: inline-block;
	background: @panel-background;
	width: 21em;
	height: 9em;
	margin: 0 0.5em;
	cursor: pointer;

	.top-bar{
		width: 100%;
		height: 0.1em;
	}

	&.selected{
		.pulsar-component {
			display: block;
		}
	}

	&.active{
		background: linear-gradient(72.9deg, #1E88E5 2.83%, #01347B 96.8%);
	}

	&.freetier-v2 {
		width: 19em;
		height: auto;
	}

	.content-container{
		display: flex;
		flex-direction: row;
		padding: 1em;
		height: calc(100% - 0.1em);

		p {
			font-size: 0.7em;
		}

		.left-content{
			height: 100%;
			width: 60%;
			margin: 0.3em 0;

			h5 {
				text-align: left;
				margin-bottom: 1em;
			}

			p {
				text-align: left;
				line-height: 1.25;
			}
		}

		.right-content{
			height: 100%;
			width: 40%;

			h2 {
				line-height:1;
				text-align: right;
				font-size: 1.7em;
				margin-bottom: 0.4em;
			}

			h5 {
				text-align: right;
			}

			p {
				line-height:1;
				text-align: right;
				font-size: 0.6em;
			}

			h1 {
				font-size: 2.5em;
				line-height:0.7;
				text-align: right;
				margin: 0.4em 0 0;
				letter-spacing: 0;
			}

			span{
				font-size: 0.5em;
			}
		}
	}

	.most-popular-frame {
		display: none;
	}

	&.monthly{
		.top-bar{
			background: @payment-monthly-top;
		}
	}

	.new-content-container {
		display: flex;
		flex-direction: column;
		padding: 1em;
		height: calc(100% - 0.1em);

		&__head {
			line-height: 1;
			font-size: 1.2em;
			text-align: center;
			max-width: 260px;
			margin: 0 auto 0.8em;
		}

		&__text {
			line-height:1;
			text-align: center;
			font-size: 0.6em;
		}

		&__price {
			font-size: 2em;
			line-height: 0.7;
			text-align: center;
			margin-bottom: 0.2em;
			letter-spacing: 0;
		}
	}
	
	&.annual{
		.top-bar{
			background: @payment-annual-top;
		}

		.most-popular-frame {
			display: block;
			position: absolute;
			top: -0.5em;
			left: -0.5em;
			width: calc(100% + 1em);
			height: calc(100% + 1em);
			border: 0.05em solid @payment-frame;
			border-top: none;

			.title {
				color: @payment-frame;
				margin: -0.6em auto 0;

				span{
					font-size: 0.6em;
				}

				&:before{
					content: "";
					display: block;
					position: absolute;
					top:0;
					left: 0;
					height: 0.05em;
					width: 40%;
					background: @payment-frame;
				}

				&:after{
					content: "";
					display: block;
					position: absolute;
					top:0;
					right: 0;
					height: 0.05em;
					width: 40%;
					background: @payment-frame;
				}
			}
		}
	}
}