.inbox-component {
    display: flex;
    flex-direction: column;
    height: 85%;
    background: transparent;
    position: relative;
    padding: 0 2.8em;

	.inbox-container {
		display: flex;
		flex-direction: row;
		height: 85%;
		overflow-y: scroll;

		.message-list {
			flex: 1;
			overflow-y: scroll;
			width: 100%;
			-webkit-overflow-scrolling: touch;
		}

		.inbox-message {
			position: relative;
			padding-left: 1em;
			flex: 2;
			word-wrap: break-word;
			animation: 0.25s slide-up-list ease-in-out;

			.loader-container {
				display: flex;

				.react-bodymovin-container {
					width: 5em;
					height: 5em;
					margin: 5em 0 0 5.8em;
				}
			}
		}
	}
}
