.common-button{
	&.with-upvote {
		padding: 0.5em 1em;

		.content {
			display: inline-flex;
			align-items: center;
		}

		.upvote-image-container {
			padding-left: 0.7em;

			.upvote-image {
				height: auto;
				width: 1.5em;
			}
		}
	}
}


