@import '../../../assets/styles/variables';

.game-details-rewards-component {
	padding: 0 2.8em 0.7em;
	height: 85%;

	.content-with-bg-full-screen{
		padding: 0;
		display: flex;
		flex-direction: column;

		>h1 {
			margin-bottom: 0.2em;
		}

		.subtitle {
			margin-top: -0.2em;
			color: @font-color;
			font-size: 1em;
		}

		.number-container {
			margin-top: 1em;

			.number {
				color: @font-color-title;
				font-size: 1.5em;
				font-family: "LatoBold", sans-serif;
				letter-spacing: 0.1em;
				text-align: center;
			}
		}

		.buttons-container {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.marketing-buttons {
				.common-button {
					height: 3em;
					width: 11.5em;
				}
			}

			.other-buttons{
				display: flex;
				justify-content: flex-end;

				.common-button {
					height: 3em;
					width: 11.5em;
					margin-right: 1em;

					&:last-of-type{
						margin-right: 0;
					}
				}
			}
		}

		.leaderboard-position-result {
			text-align: center;
			font-size: 1.5em;
			font-family: "LatoBold", sans-serif;
			color: @font-color-title;
			margin: 0.3em 0 0.3em;
		}


		.panels {
			position: relative;
			display: flex;
			flex-direction: column;
			flex: 1;
			align-items: center;
			justify-content: center;
			animation: 0.3s reward-slide-left ease-in-out;
			margin: 1em;

			.challenge-summary-component {
				width: 58%;
			}

			.rewards-container {
				width: 58%;
				max-height: 12.2em;
				height: 100%;
				transform: skew(-10deg);
				margin: 0.2em 1em 1em;

				.inner {
					height: 100%;
					padding: 0 1.5em;
					transform: skew(10deg);

					.badges {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 100%;

						> li {
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							width: 30%;
							height: 100%;
							opacity: 0.2;
						}

						img {
							&.glow {
								opacity: 0;
								position: absolute;
								width: 100%;
								max-height: 100%;
							}
							&.badge {
								position: relative;
								z-index: 1;
								width: 80%;
								max-height: 100%;
								vertical-align: middle;
							}
						}
					}
				}

				&.bronze {
					&:after {
						background-image: linear-gradient(to right, #e5a684, #fdd5c2 31%, #ff9577 51%, #a94e34 );
					}

					li {
						&.bronze {
							opacity: 1;
							animation: 0.6s reward-badge-bronze ease-out;

							img.glow {
								animation: 3s fade-out-in infinite;
							}
						}
					}
				}

				&.silver {
					&:after {
						background-image: linear-gradient(to right, #cbd3e0, #f6faff 21%, #b2c4e4 31%, #f8fbff 62%, #b2b8c2 89%, #ced6e4);
					}

					li {
						&.bronze {
							opacity: 1;
							animation: 0.6s reward-badge-bronze ease-out;
						}
						&.silver {
							opacity: 1;
							animation: 1s reward-badge-silver ease-out;

							img.glow {
								animation: 3s fade-out-in infinite;
							}
						}
					}
				}

				&.gold {
					&:after {
						background-image: linear-gradient(to right, #edad4d, #f6ce7e 41%, #daa444);
					}

					li {
						&.bronze {
							opacity: 1;
							animation: 0.6s reward-badge-bronze ease-out;
						}

						&.silver {
							opacity: 1;
							animation: 1s reward-badge-silver ease-out;
						}
						&.gold {
							opacity: 1;
							animation: 1.5s reward-badge-gold ease-out;

							img.glow {
								animation: 3s fade-out-in infinite;
							}
						}
					}
				}
			}
		}
	}

	@keyframes reward-slide-right {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-10em);
		}
	}

	@keyframes reward-slide-left {
		0% {
			transform: translateX(20em);
		}
		100% {
			transform: translateX(0);
		}
	}

	@keyframes reward-badge-bronze {
		0% {
			opacity: 0;
			transform: scale(2);
		}
		40% {
			opacity: 0;
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes reward-badge-silver {
		0% {
			opacity: 0;
			transform: scale(2);
		}
		67% {
			opacity: 0;
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes reward-badge-gold {
		0% {
			opacity: 0;
			transform: scale(2);
		}
		67% {
			opacity: 0;
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}
}
