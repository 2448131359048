@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.xbox-subscription-popup {
    position: relative;
    width: 32em;
    max-width: 40em;
    padding: 1em 0.7em 0.8em;

    .already-have-subscription {
        position: absolute;
        bottom: 1em;
        left: 0;
        width: 60%;
        font-size: 0.7em;
        color: #fff;
        &__1 {
            font-weight: bold;
        }
    }

    &:before {
        .popup-background-mixing;
    }

    .plan-button {
        transform: skew(0deg);
        width: 8em;
        min-height: 10.2em;
        text-align: center;
        padding: 0;
        box-shadow: 0.2em 0.2em 0.3em 0em #000000;
        margin: 0 1em;
        background: linear-gradient(135deg, #0087f2 0%, #023177 100%);

        &.disabled {
            color: #fff;
            opacity: 0.5;
        }
        &.selected {
            text-shadow: none;
        }
        .content {
            transform: none;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

        &--1year { background: #84c184; }
        &--3year { background: #d03f73; }

        .button-title-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        .button-title {
            transform: skew(10deg);
            margin: 0.4em 1em 0.2em;
            font-family: 'Quantify-Bold', sans-serif;
            font-size: 0.75em;
            line-height: 1em;
            letter-spacing: initial;
        }
    }

    &__content {
        transform: skew(-10deg);
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 1em 0;
    }

    .subtitle-text {
        text-align: center;
        width: 100%;
        font-family: 'Lato', sans-serif;
        letter-spacing: 0.05em;
        font-size: 0.8em;
        color: @font-color-title;
        margin: 0.5em 0;
    }
    .title-text {
        text-align: center;
        width: 100%;
        font-family: 'Quantify-Bold', sans-serif;
        letter-spacing: 0.05em;
        font-size: 2em;
        color: @font-color-title;
        text-shadow: 0 0 0.6em @font-color-text-shadow;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-illus {
        border-radius: 0.3em;
        height: 6em;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0 0.5em;
    }
    .plan-button--1year .button-illus {
        background-image: url("../../../assets/images/xbox-subscription-popup/xbox-subscription-popup-1-year-plan-sm.png");
    }
    .plan-button--3year .button-illus {
        background-image: url("../../../assets/images/xbox-subscription-popup/xbox-subscription-popup-3-year-plan-sm.png");
    }

    .button-cost {
        margin: 0.3em 0.5em;
        height: 1.5em;
        background-color: #013A71;
        border-radius: 0.2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-cost-inner {
        transform: skew(10deg);
        font-size: 0.7em;
    }

    &__buttons {
        display: inline-flex;
        justify-content: flex-end;
        width: 100%;

        .common-button {
            width: 8em;
            margin-right: 2em;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }
    }
    .subscription-renewal-content {
        padding: 0.5em 0 1em;
        display: flex;
    }

    .subscription-purchase-complete {
        padding: 0.5em 2em 1em;
        display: flex;
        transform: skew(10deg);
        .left-col {
            margin-right: 1em;
        }
        .subscription-image {
            height: 12em;
            width: 8em;
            background-size: cover;
            background-repeat: no-repeat;
            margin: 0 0.5em;

            &--1year {
                background-image: url("../../../assets/images/xbox-subscription-popup/xbox-subscription-popup-1-year-plan-lg.png");
            }
            &--3year {
                background-image: url("../../../assets/images/xbox-subscription-popup/xbox-subscription-popup-3-year-plan-lg.png");
            }
        }
    }

    .loading-content-text {
        font-size: 1em;
    }
    .loading-content {
        transform: skew(10deg);
        padding: 1em 1em 2em;
    }

    .confirmation-text-content {
        transform: skew(10deg);
        padding: 1em 1em 2em;

        &__item {
            text-align: center;
        }
    }
}
