@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.leaderboard-component {
    display: flex;
    justify-content: space-between;
	width: 100%;
	height: 100%;

	.content-left {
		position: relative;
		width: 49%;
		//animation: 0.25s panel-slide-right ease-in-out;

		.podium-container {
			text-align: center;
			font-size: 0.9em;
			position: fixed;
			left: 9.6em;
			bottom: 0.5em;
			z-index: 1;

			.safe-area-turned-on & {
				position: absolute;
				left: 7.6em;
			}

			>img {
				display: block;
				position: relative;
				left: 4em;
				bottom: 0;
				width: 16.5em;
				height: auto;
			}

			.podium-item {
				position: absolute;
				width: 4.8em;
				height: 7.2em;

				.avatar-container {
					margin: 0 auto;
					width: 4.4em;
					height: 4.4em;
					margin-bottom: 0.2em;
					.avatar-image-container{
						border: 0.2em #004082 solid ;
					}

					.notification {
						border-width: 0.1em;
					}
				}

				.name {
					display: flex;
					justify-content: center;
					align-items: center;
					word-wrap: break-word;
					font-weight: bold;
					line-height: 1.1em;
					font-family: "Lato", sans-serif;
					color: #89969F;
					font-size: 0.7em;
					height: 2.2em;
					word-break: break-all;
				}

				.score{
					font-family: "Lato", sans-serif;
					font-weight: normal;
					color: #6e7881;
				}

				&.rank-1{
					left: 9.9em;
					bottom: 10.6em;
					-webkit-animation: bounce-in-fwd 1.1s 0.2s both;
	        		animation: bounce-in-fwd 1.1s 0.2s both;
				}
				&.rank-2{
					left: 4.1em;
					bottom: 8.1em;
					-webkit-animation: bounce-in-fwd 1.1s 0.4s both;
	        		animation: bounce-in-fwd 1.1s 0.4s both;
				}
				&.rank-3{
					left: 15.7em;
					bottom: 5.6em;
					-webkit-animation: bounce-in-fwd 1.1s 0.6s both;
	        		animation: bounce-in-fwd 1.1s 0.6s both;
				}

				&.twitch{
					.avatar-container {
						.avatar-image-container{
							border: 0.2em #6441A4 solid ;
						}
					}
				}

				&.selected{
					.name{
						color:white;
					}
					.score{
						color:white;
					}

					.avatar-container {
						.avatar-image-container{
							border: 0.2em @font-color-outline-yellow solid ;
						}
					}
				}
			}
		}

		.buttons-container{
			display: flex;
			flex-direction: column;
			position: fixed;
			left: 2.15em;
			bottom: 0.4em;
			z-index: 1;

			.safe-area-turned-on & {
				position: absolute;
				left: 0.15em;
			}

			.common-button{
				height: 3em;
				width: 9em;
				margin-bottom: 1.3em;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		&.tournament{
			&.global{//add badges only for the global list
				.podium-container {
					.podium-item {
						height: 9.2em; // +2em for badge
						padding-top: 2em;
						&:after{
							content: "";
							display: block;
							position: absolute;
							top: 0.6em;
							left: 1.4em;
							width: 2em;
							height: 2em;
						}

						&.rank-1{
							&:after{
								background: url("../../assets/images/generic-icons/M-tournament_trophy-generic-gold.png");
								background-size: contain;
								background-repeat: no-repeat;
								
							}
						}
						&.rank-2{
							&:after{
								background: url("../../assets/images/generic-icons/M-tournament_trophy-generic-silver.png");
								background-size: contain;
								background-repeat: no-repeat;
							}
						}
						&.rank-3{
							&:after{
								background: url("../../assets/images/generic-icons/M-tournament_trophy-generic-bronze.png");
								background-size: contain;
								background-repeat: no-repeat;
							}
						}
					}
				}
			}
		}
	}

	.content-right {
		width: 51%;
		height: 100%;
		position: relative;
		animation: 0.25s panel-slide-left ease-in-out;

		.xbox-gamer-card {
			position: absolute;
			top: -2em;
			right: 0;
			font-family: Lato, sans-serif;
			font-size: 0.8em;
			z-index: 11;

			&__text {
				color: #933B9B;
			}
			&__icon {
				color: #000;
				border-radius: 50%;
				background: #933B9B;
				padding: 0.2em 0.4em;
				font-size: 0.8em;
				font-weight: bold;
				margin-right: 0.3em;
			}
		}

		.leaderboard-container {
			position: relative;
			width: 100%;
			height: calc(100% - 1em);
			color: @font-color-button-disabled;
			background: @panel-background;
			box-shadow: 0 0 0.7em 0.02em rgba(0,0,0,0.5);
			border-radius: 0 0 0.15em 0.15em;
			z-index: 1;

			.alternateInfos {
				position: relative;
				min-width: 15%;
				text-align: right;
				font-weight: normal;
				font-size: 0.6em;
				font-family: "SourceSansPro-Regular";
			}

			.timeago,
			.duration {
				position: absolute;
				right: 0;
				top: 0;
				opacity: 0;
				transition: opacity 1s;
				.webview-ultralight & {
					transition: none;
				}
			}

			&.showTimeAgo .timeago,
			&.showDuration .duration {
				opacity: 1;
			}

			.gradient-top-border {
				box-shadow: none;
				padding: 0;
			}
			.list-inner {
				.tile:last-child{
					.player-item {
						border-radius: 0.15em 0.15em 0.15em 0.15em;
					}
				}
				.tile:first-child{
					.player-item {
						border-radius: 0 0 0.15em 0.15em;
					}
				}	
			}
			

			.no-result-message {
				display: flex;
    			justify-content: center;
    			flex-direction: column;
				height: 100%;
				text-align: center;
				&.global {
					color: #1b97bf;
				}

				&.xbox {
					color: #933B9B;
				}

				&.following {
					color: #047178;
				}

				&.twitch-rivals {
					color: #6441A4;
				}

				&.kickstarter {
					color: #05CE78;
				}

				&.filter-with-access {
					color: #79B82C;
				}
			}

			.player-item {
				position: relative;
				height: 3em;
				cursor: pointer;

				&.selected{
					.item-inner{
						.pulsar-component {
							display: block;
						}
					}
				}

				.item-inner {
					display: flex;
					align-items: center;
					width: 100%;
					height: 100%;
					padding: 0 0.5em;
					border: 0.1em solid transparent;
					justify-content: space-between;
					.crossplay-platform {
							min-width: 5%;
							width: 1.0em;
							height: 1.0em;
					
							.icon {
								width: 100%;
							}
						}
					.rankText {
						min-width: 10%;
						text-align: left;
						font-family: 'Quantify-Bold';
						.rank {
							font-size: 1.1em;
						}
						.prefix,
						.suffix {
							font-size: 0.7em;
						}
					}
					.avatar-container {
						width: 2.7em;
    					height: 2.7em;
						.avatar-image-container{
							border: 0.08em #004082 solid;

							img {
								width: 100%;
							}
						}

						.notification {
							border-width: 0.07em;
						}
					}

					&.twitch{
						.avatar-container {
							.avatar-image-container{
								border-color: #65459B;
							}
						}
					}

					.name {
						padding-left: 0.5em;
						width: 9em;
						min-width: 22%;
						word-wrap: break-word;
						font-family: 'Quantify-Bold';
					}

					.score {
						min-width: 23%;
						justify-self: flex-end;
						text-align: right;
						font-weight: normal;
						font-family: "SourceSansPro-Regular";
						overflow: hidden;
    					text-overflow: ellipsis;
					}
				}
			}

			.logged-user-container{
				display: none;
				height: 2.9em;
				position: absolute;
				width: 100%;
				z-index: 10; 
				&.top {
					display: block;
					top: 0;
					.player-item {
						bottom: initial;
						box-shadow: -0.1em 0.10em 0.05em 0.0em rgba(0, 0, 0, 0.4);
						border-radius: 0 0 0.15em 0.15em;
					}
				}

				&.bottom {
					display: block;
					bottom:0;
					.player-item {
						box-shadow: -0.1em -0.10em 0.05em 0em rgba(0, 0, 0, 0.4);
						border-radius: 0.15em 0.15em 0.15em 0.15em;
					}
				}
				.player-item {
					color: @font-color-button;
					.item-inner {
						.rankText {
							span {
								color: @font-color-button;
							}
						}
					}
				}
			}


			&.global {
				.gradient-top-border {
					&:after{
						background: linear-gradient(90deg, #12759A  0%, #165592 100%);
					}
				}

				.player-item {
					&.logged-user {
						box-shadow: -0.15em 0.05em 0.15em 0.2em rgba(0, 0, 0, 0.4);
						background: linear-gradient(90deg, #12759A  0%, #165592 100%);
					}
					&.logged-user-crossplay {
						background: linear-gradient(180deg, #933B9B 0%, #6D2379 100%);
					}
					.item-inner {
						.rankText {
							color: #16688a;
						}
					}
				}

				.logged-user-container {
					.player-item {
						background: linear-gradient(90deg, #12759A  0%, #165592 100%);
					}
				}
				.player-item-crossplay {
					background: linear-gradient(180deg, rgba(147, 59, 155, 0.25) 0%, rgba(109, 35, 121, 0.25) 100%);
				}
				.top {
					.player-item-crossplay {
						background: linear-gradient(180deg, rgba(147, 59, 155, 1) 0%, rgba(109, 35, 121, 1) 100%);
					}
				}
				.bottom {
					.player-item-crossplay {
						background: linear-gradient(180deg, rgba(147, 59, 155, 1) 0%, rgba(109, 35, 121, 1) 100%);
					}
				}
			}

			&.xbox {
				.gradient-top-border {
					&:after{
						background: linear-gradient(90deg, #933B9B 0%, #6D2379 100%);
					}
				}

				.player-item {
					&.logged-user {
						background: linear-gradient(90deg, #933B9B 0%, #6D2379 100%);
					}
					&.logged-user-xbox {
						background: linear-gradient(180deg, #933B9B 0%, #6D2379 100%);
					}

					.item-inner {
						.rankText {
							color: #933B9B;
						}
					}
				}

				.logged-user-container {
					.player-item {
						background: linear-gradient(90deg, #933B9B 0%, #6D2379 100%);
					}
				}
				.player-item-crossplay {
					background: linear-gradient(180deg, rgba(147, 59, 155, 0.25) 0%, rgba(109, 35, 121, 0.25) 100%);
				}
				.top {
					.player-item-crossplay {
						background: linear-gradient(180deg, rgba(147, 59, 155, 1) 0%, rgba(109, 35, 121, 1) 100%);
					}
				}
				.bottom {
					.player-item-crossplay {
						background: linear-gradient(180deg, rgba(147, 59, 155, 1) 0%, rgba(109, 35, 121, 1) 100%);
					}
				}
			}

			&.following {
				.gradient-top-border {
					&:after{
						background: linear-gradient(90deg, #058D75 0%, #047178 100%);
					}
				}

				.player-item {
					&.logged-user {
						background: linear-gradient(90deg, #058D75 0%, #047178 100%);
					}
					&.logged-user-crossplay {
						background: linear-gradient(180deg, #933B9B 0%, #6D2379 100%);
					}

					.item-inner {
						.rankText {
							color: #058D75;
						}
					}
				}

				.logged-user-container {
					.player-item {
						background: linear-gradient(90deg, #058D75 0%, #047178 100%);
					}
				}

				.player-item-crossplay {
					background: linear-gradient(180deg, rgba(147, 59, 155, 0.25) 0%, rgba(109, 35, 121, 0.25) 100%);
				}
				.top {
					.player-item-crossplay {
						background: linear-gradient(180deg, rgba(147, 59, 155, 1) 0%, rgba(109, 35, 121, 1) 100%);
					}
				}
				.bottom {
					.player-item-crossplay {
						background: linear-gradient(180deg, rgba(147, 59, 155, 1) 0%, rgba(109, 35, 121, 1) 100%);
					}
				}
			}

			&.twitch {
				.gradient-top-border {
					&:after{
						background: linear-gradient(90deg, #754fb4 0%, #65459B 100%);
					}
				}

				.player-item {
					&.logged-user {
						background: linear-gradient(90deg, #65459B 0%, #754fb4 100%);
					}

					.item-inner {
						.rankText {
							color: #65459B;
						}
					}
				}

				.logged-user-container {
					.player-item {
						background: linear-gradient(90deg, #65459B 0%, #754fb4 100%);
					}
				}

				.player-item-crossplay {
					background: linear-gradient(180deg, rgba(147, 59, 155, 0.25) 0%, rgba(109, 35, 121, 0.25) 100%);
				}
			}

			&.kickstarter {
				.gradient-top-border {
					&:after{
						background: linear-gradient(90deg, #05CE78 0%, #05CE78 100%);
					}
				}

				.player-item {
					&.logged-user {
						background: linear-gradient(90deg, #05CE78 0%, #05CE78 100%);
					}

					.item-inner {
						.rankText {
							color: #05CE78;
						}
					}
				}

				.logged-user-container {
					.player-item {
						background: linear-gradient(90deg, #05CE78 0%, #05CE78 100%);
					}
				}

				.player-item-crossplay {
					background: linear-gradient(180deg, rgba(147, 59, 155, 0.25) 0%, rgba(109, 35, 121, 0.25) 100%);
				}
			}
			&.filter-with-access {
				.gradient-top-border {
					&:after{
						background: linear-gradient(90deg, #79B82C 0%, #79B82C 100%);
					}
				}

				.player-item {
					&.logged-user {
						background: linear-gradient(90deg, #79B82C 0%, #79B82C 100%);
					}

					.item-inner {
						.rankText {
							color: #79B82C;
						}
					}
				}

				.logged-user-container {
					.player-item {
						background: linear-gradient(90deg, #79B82C 0%, #79B82C 100%);
					}
				}
			}
			
			.player-item {
				&.logged-user {
					color: @font-color-button;
					border-radius: 0.15em 0.15em 0.15em 0.15em;
					.item-inner {
						.rankText {
							color: @font-color-button;
						}
					}
				}
			}
			.logged-user, .logged-user-container {
				.item-inner {
					.rankText {
							.rank {
								font-size: 1.2em;
							}
							.nthText {
								font-size: 0.8em;
							}
						}
						.score {
							font-size: 1.2em;
						}
						.timeago {
							font-size: 0.7em;
						}
					}
				}
			}

			.list-container {
				height: 100%;

				.list-title{
					display: none;
				}
			}

			.loading-container{
				position: absolute;
				bottom:0;
				background: rgba(0,0,0,0.2);
				width: 100%;
				text-align: center;
				display: none;
				z-index: 11;
				color: @font-color;
				font-size: 0.8em;
				padding: 0.2em 0;

				&.visible{
					display: block;
				}
			}
		}

/**
 * ----------------------------------------
 * animation bounce-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-fwd {
	0% {
	  -webkit-transform: scale(0);
			  transform: scale(0);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	  opacity: 0;
	}
	38% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	  opacity: 1;
	}
	55% {
	  -webkit-transform: scale(0.7);
			  transform: scale(0.7);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	72% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	81% {
	  -webkit-transform: scale(0.84);
			  transform: scale(0.84);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	89% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	95% {
	  -webkit-transform: scale(0.95);
			  transform: scale(0.95);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
  }
  @keyframes bounce-in-fwd {
	0% {
	  -webkit-transform: scale(0);
			  transform: scale(0);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	  opacity: 0;
	}
	38% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	  opacity: 1;
	}
	55% {
	  -webkit-transform: scale(0.7);
			  transform: scale(0.7);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	72% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	81% {
	  -webkit-transform: scale(0.84);
			  transform: scale(0.84);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	89% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
	95% {
	  -webkit-transform: scale(0.95);
			  transform: scale(0.95);
	  -webkit-animation-timing-function: ease-in;
			  animation-timing-function: ease-in;
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	  -webkit-animation-timing-function: ease-out;
			  animation-timing-function: ease-out;
	}
  }
  
	}
