.icon i {
	display: block;
}

/* Top genre icons */

.icon i.Action {
	background: url(../images/genre-icons/action.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Adventure {
	background: url(../images/genre-icons/adventure.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Arcade,
.icon i.ArcadeAdventure {
	background: url(../images/genre-icons/arcade-adventure.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.BeatEmUp {
	background: url(../images/genre-icons/beat-em-up.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Driving {
	background: url(../images/genre-icons/driving.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Fighting {
	background: url(../images/genre-icons/fighting.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.FlightSimulator {
	background: url(../images/genre-icons/flight-simulator.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Maze {
	background: url(../images/genre-icons/maze.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Pinball {
	background: url(../images/genre-icons/pinball.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Platform {
	background: url(../images/genre-icons/platform.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Puzzle {
	background: url(../images/genre-icons/puzzle.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Racing {
	background: url(../images/genre-icons/racing.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.RolePlayingGame {
	background: url(../images/genre-icons/role-playing-game.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Shooting {
	background: url(../images/genre-icons/shooting.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Sports {
	background: url(../images/genre-icons/sports.png) 0 0 no-repeat;
	background-size: contain;
}

.icon i.Strategy {
	background: url(../images/genre-icons/strategy.png) 0 0 no-repeat;
	background-size: contain;
}