.ShareModal {
    min-width: 30em;
    padding: 0 3em;
    .ShareModal-Buttons{
        display: flex;
        justify-content: space-around;
    }
    .ShareModal-CopyAndPaste-container {
        display: flex;
        padding: 1em 0 0 0;
        .ShareModal-CopyAndPaste-icon {
            height: 1em;
            margin: 1em 0.5em;
        }
        .ShareModal-CopyAndPaste-button {
            width: 5em;
        }
    }
    .ShareModal-copiedToClipboardText {
        color: white;
        text-align: center;
        padding: 1em 0 0 0;
    }
}