@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.tile-tournament-history-component {
	width: 15em;
	height: 9.7em;
	margin: 0.5em;

	position: relative;
	transition: transform 500ms;
	z-index: 1;
	
	&--lowspec {
		transition: none;
	}

	.background-container{
		width: 100%;
		height: 100%;
		border: 0 solid;
		border-radius: 3em 0.15em 0.15em 0.15em;
		overflow: hidden;

		>img{
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.countdown-component{
		top: 0;
		left: 0;
		font-size: 0.7em;
	}

	.content-container{
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 3.2em !important;


		.badge-image-container{
			width: 2.8em;
			min-width: 2.8em;
			max-width: 2.8em;
			margin: 0 0.35em;
			position: relative;

			.badge-img{
				width: 100%;
				height: auto;
			}

			.position{
				position: absolute;
				bottom: 2.3em;
				padding-right: 0.4em;
				width: 100%;
				text-align: center;
				font-family: 'Quantify-Bold', serif;
				color: #001611;
				font-size: 0.5em;
				top: 3.2em;
			}
		}


		.tournament-title{
			margin-right: 0.3em;
			font-family: 'Quantify-Bold', serif;
			font-weight: bold;
			color: white;
			font-size: 1.1em;
			line-height: 0.9em;
		}
	}


	&.selected {
		.pulsar-component {
			.back-border,
			.front-border{
				border-radius: 3em 0.15em 0.15em 0.15em;
			}
			display: block;
		}
		transform: scale(1.1);
		z-index: 2;
	}
	&--lowspec.selected {
		transform: none;
	}

}
