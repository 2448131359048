@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.hero-carousel-component {
	position: absolute;
	top:0;
	left:0;
	height: 100%;
	width: 100%;
	z-index: 10;
	overflow: hidden;

	.frame {
		position: relative;
		height: 12.5em;
		z-index: 10;

		.tile-container{
			display: inline-flex;
			position: absolute;
			top: 0;
		}

		&:hover,
		&.selected{
			.pulsar-component {
				display: block;
			}
		}
	}

	.indicator-container{
		position: absolute;
		height: 1.2em;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 11;

		.indicator-item{
			display: inline-block;
			height: 0.5em;
			width: 0.5em;
			margin: 0.2em;
			border: 0 solid;
			border-radius: 50%;
			background-color: #a2a2a2;
			box-shadow: 0.05em 0.05em 0.1em 0.03em rgba(0, 0, 0, 0.4);
			opacity: 0.8;
			cursor: pointer;

			&.selected {
				background-color: #efefef;
				opacity: 1;
			}
		}
	}
}
