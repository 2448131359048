@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.reward-popup-component {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 40em;
	height: 23em;
	padding: 1em;

	&:before {
		.popup-background-mixing;
	}

	.left-container {
		width: 50%;

		.title {
			color: @font-color-title;
			font-size: 1.4em;
			text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
			padding: 0.4em 0 0.6em 1.7em;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.image-container {
			width: 18em;
			height: 18em;
			background-color: @panel-background;
			box-shadow: 0.1em 0.1em 0.7em rgba(0,0,0,0.4);
			overflow: hidden;
			margin-left: 1em;

			img {
				height: 100%;
				width: 100%;
			}
		}
	}

	.right-container {
		width: 50%;
		height: 100%;
		padding: 0.5em 0 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 1em;

		.result-title {
			font-family: 'Quantify-Bold';
			letter-spacing: 0.05em;
			font-size: 2em;
			color: @font-color-title;
			text-shadow: 0 0 0.6em @font-color-text-shadow;
		}

		.result-content {
			font-size: 1.1em;
			color: @font-color-title;
			text-shadow: 0 0 0.6em @font-color-text-shadow;
		}

		.message-title {
			font-size: 1.15em;
			font-weight: bold;
			color: @font-color-title;
			text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		}

		.message-content {
			text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		}

		.icon-container {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
		}

		.buttons-container {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			.common-button {
				padding: 0.5em;
				width: 7em;
				margin-right: 1em;
				&:last-of-type{
					margin-right: 2em;
				}
			}
		}
	}
}