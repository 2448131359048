@import '../../assets/styles/variables';
@import '../../assets/styles/animations';
.attract-container {
	width: 98%;
	height: 98%;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	img {
		max-width: 100%;
		max-height: 100%;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
.login-wrapper {
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
	margin: 0 auto;

	&--normal {
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		overflow: scroll;
	}

	&.login-first {
		background-image: url('../../assets/images/global/login_v2-960.jpg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.top-text {
		display: flex;
		flex-direction: column;
		padding-top: 2.4em;
		&__item {
			color: white;
			font-family: Lato, sans-serif;
			font-size: 0.7em;
			margin-bottom: 0.3em;
			text-align: center;
			&--first {
				font-size: 0.8em;
				color: white;
				font-family: Quantify, sans-serif;
				margin-bottom: 0.5em;
			}
		}
	}
	.footer-text {
		display: flex;
		flex-direction: column;
		&__item {
			font-size: 0.7em;
			text-align: center;
			font-family: Quantify, sans-serif;
			padding-bottom: 1.5em;
			text-transform: uppercase;
			font-style: italic;
			color: white;
		}
	}
	.bottom-text {
		padding-top: 1em;
		display: flex;
		flex-direction: column;
		&__item {
			font-family: Lato, sans-serif;
			font-size: 0.7em;
			color: grey;
			text-align: center;
			margin-bottom: 0.3em;
			&--first {
				font-family: Quantify, sans-serif;
				font-size: 1em;
				color: white;
			}
			&--last {
				font-family: Quantify, sans-serif;
				padding: 1em 0;
				text-transform: uppercase;
				font-style: italic;
				color: white;
			}
		}
	}

	.login-section {
		width: 38%;
		
		

		display: flex;
		flex-direction: column;
		align-items: center;
		&--left{
			align-items: flex-start;
		}
		&--animate{
			animation: 0.5s bounce ease-in-out;
		}
		&--avatar{
			height: 100%;
		}
		&--firstscreen{
			padding-top: 5%;
		}

		form {
			width: 100%;
		}

		.logo-container {
			margin-top: 1em;
			margin-bottom: 1em;
			img {
				height: auto;
				width: 11em;
			}
		}

		.common-button,
		.input-container {
			margin-bottom: 1em;
		}

		.right-button {
			width: 47.5%;
			align-self: flex-end;
		}

		.small-button {
			width: 47.5%;
		}

		.buttons-container {
			width: 100%;
			.common-button {
				&:first-of-type {
					margin-right: 5%;
				}
			}
		}

		.with-xbox,
		.with-playstation,
		.with-facebook,
		.with-epic-games {
			width: 18em;
		}

		.separator-with-text {
			margin-bottom: 1em;
			width: 100%;

			.line {
				display: inline-block;
				position: relative;
				top: -0.35em;
				width: 42%;
				height: 0.15em;
				background-color: #435168;
			}

			.text {
				display: inline-block;
				width: 16%;
				color: #969fb2;
				font-size: 1em;
				font-family: 'Lato', sans-serif;
				font-weight: 400;
				letter-spacing: 0.09em;
				text-align: center;
			}
		}

		.remote-validation-error {
			height: 1.5em;
			color: @font-color-validation;
		}

		.privacy_policy_container {
			margin: -0.7em 0 3em;
			text-align: left;
			display: flex;
			flex-direction: row;
			cursor: pointer;


			>div{
				display: inline-block;
			}

			.info-container{
				margin-top: 0.5em;
				font-size: 0.6em;
			}

			.checkbox-container{
				position: relative;
				border: 0.2em solid #cacece;
				box-shadow: 0 0.1em 0.2em rgba(0,0,0,0.05), inset 0 -0.15em 0.1em -0.12em rgba(0,0,0,0.05);
				border-radius: 0.3em;
				height: 3em;
				width: 3em;
				background-color: @panel-background;
				cursor: pointer;
				margin: 0.5em;
				font-size: 0.5em;
				flex-shrink: 0;

				&.focused {
					border-color: @font-color-outline-pink;
				}

				&.checked {
					&:after{
						position: absolute;
						top: 0;
						left: 0;
						content: "";
						width: 100%;
						height: 100%;
						background:  url(../../assets/images/global/tickAssetWhite.svg) 0 0 no-repeat;
						pointer-events: none;
					}
				}
			}

			.antstream-link{
				color: @font-color-title;
			}
		}
	}

	&.virtualKeyboardVisible {
		align-items: initial;
		height: 50vh;
		overflow: scroll;

		.login-section{
			height: 100vh;

			.logo-container {
				display: none;
			}

			> h1 {
				font-size: 1.2em;
			}
		}
	}
}
