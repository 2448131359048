@import "../../assets/styles/variables";

.settings-component {
    height: 85%;
	overflow-y: scroll;
	.qr-code-_img
	{
		height: auto;
    	width: 6.5em;
	}
	.xbox-platform & .settings-display-name-el .input-container input {
		font-family: sans-serif;
	}

	.fake-focusable {
		opacity: 0;
		width: 0;
		height: 0;
		padding: 1px 0;
		margin: 0;
		.pulsar-component {
			display: none;
		}
	}
	.focusable-hyperlink {
		background: none;
		border: none;
		display: inline-block;
		padding: 0;
		transform: none;
		width: auto;
		color: #14ccf8;
		.content {
			transform: none;
		}
		&.selected {
			color: yellow;
			text-decoration: underline;
		}
		.pulsar-component {
			display: none;
		}
	}

	.settings-component-free-tier-banner-wrapper {
		padding-bottom: 1em;
	}

	h1 {
		margin-bottom: 0;
	}
	.settings-content-wrapper{
		padding: 0 2.8em;
	}

	.settings-content-container {
		/* please note, if padding-top is less than 0.8 em, the first focusable element of the settings-list will be not focused when navigating from top to bottom */
		padding: 0.8em 0.5em 0 0;
		height: 100%;

		.settings-list {
			li {
				display: flex;
				justify-content: space-between;
				align-content: space-between;
				align-items: center;
				position: relative;
				height: 3em;
				margin-bottom: 1em;
				border-radius: 3px;

				p {
					margin-bottom: 0;
				}

				//&:active,
				//&:focus {
				//	background: linear-gradient(135deg, #002044 0%, #003f84 100%);
				//
				//	p {
				//		color: #efefef;
				//	}
				//}

				&:before,
				&:after {
					display: block;
					content: '';
					position: absolute;
					top: 0;
					height: 100%;
				}


				//&:active:before,
				//&:focus:before {
				//	left: -0.7em;
				//	width: 0.7em;
				//	height: 100%;
				//	background: #002044;
				//	border-radius: 3px;
				//}
				//
				//&:active:after,
				//&:focus:after {
				//	right: -2%;
				//	width: 4%;
				//	background: #003f84;
				//	border-radius: 3px;
				//	transform: skew(-10deg);
				//}

				.input-container {
					width: 15em;

					input {
						padding-right: 0.2em;
						text-overflow: ellipsis;
					}

					.validation-error {
						left:-13em;
					}
				}

				> .common-button {
					width: 15em;
				}

				> .drop-down-component {
					width: 15em;
				}
			}
		}

		.buttons-container {
			display: flex;
			justify-content: flex-end;
			margin-top: 2em;

			.common-button {
				width: 10em;
				margin-right: 0.5em;
				margin-bottom: 1em;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.settings-support {
			.test-connection-container{
				display: flex;
				//display: none;
				flex-direction: row;
				align-content: space-between;
				width: 100%;
				margin-bottom: 2em;

				.test-explanation-text {
					width: calc(100% - 15em);
				}
				.common-button {
					height: 3em;
					margin: 1em;
					width: 13em;
				}
			}

			.support-info-container{
				.version-text {
					user-select: all;
				}
				p span {
					color: @font-color-text-shadow;
				}
			}
		}

		.settings-helpful-info {
			li {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 3em;
				margin: 2em 0;

				p {
					margin-bottom: 0;
				}

				> .common-button {
					width: 20em;
					margin-right: 3em;
				}
			}
		}
	}

	.xbox-crossplay-setting {
		font-family: Source Sans Pro;
		display: flex;
		justify-content: space-between;
		&__text {
			color: #A0A0A0;
			font-size: 1em;
			margin-left: 0.5em;
		}
		&__info {
			color: #A0A0A0;
			padding-left: 1em;
			font-size: 0.8em;
			line-height: 1.2em;
			padding-top: 0.2em;
		}
		&__crossplay {
			
		}
		&__btn {
			width: 100%;
			text-align: center;
		}
		&__col {
			&--left {
				width: 70%;
			}
			&--right {
				align-items: center;
				display: flex;
				width: 15em;
				text-align: center;
			}
		}
	}
}
