.common-button{
    &.ShareModalButton {
        padding: 0;
        transform: skew(0);
        height: 5em;
        width: 5em;
        .content {
            transform: skew(0);
                .share-source-image{
                    width: 100%;
                    height: 100%;
                    border-radius: 0.3em;
                }

        }

        &.bounce-animation {
            animation: button-bounce-frames-facebook 150ms;
        }

        @keyframes button-bounce-frames-facebook {
            0% { transform: scale(1); }
            50% { transform: scale(0.9); }
            100% { transform: scale(1); }
        }
    }   
}