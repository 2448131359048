.settings-terms-of-use {
	width: 100%;
	max-width: 36em;
	font-size: 1em;
	font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;

	.list-style-disc li {
		list-style-type: disc;
		margin-left: 1em;
	}

	.fake-focusable {
		opacity: 0;
		width: 0;
		height: 0;
		padding: 1px 0;
		margin: 0;
		.pulsar-component {
			display: none;
		}
	}
	
	.font_5 {
		font-size: 1em;
	}
	p {
		font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;
	}

	.font-weight-normal {
		font-weight: normal;
	}
	.font-weight-bold {
		font-weight: bold;
	}
	.font-size-18 {
		font-size: 1em;
	}
	.font-size-17 {
		font-size: 0.9em;
	}
	.font-size-16 {
		font-size: 0.8em;
	}
	.font-size-12 {
		font-size: 0.6px;
	}
	.text-decoration-underline {
		text-decoration: underline;
	}
	.fake-link {
		color: #14ccf8;
		text-decoration: underline;
	}
	.letter-spacing-normal {
		letter-spacing: normal;
	}
	.mb-05 {
		display: block;
		margin-bottom: 0.5em;
	}
	.mb-1 {
		display: block;
		margin-bottom: 1em;
	}
	.list-style-disc {
		list-style: disc;
		padding-left: 1em;
	}
	.w-100 {
		width: 100%;
	}
}