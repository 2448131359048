@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.tile-user-opponent-component {
	width: 7em;
	margin: 0.5em;
	transition: transform 500ms;

	&.selected {
		z-index: 1;
		transform: scale(1.1);
		.pulsar-component {
			z-index: 2;
			display: block;
		}

		.inner-container {
			background: @panel-background-selected;
			.inner {
				.rank {
					color: #c3c9d5;
				}
			}
		}
	}

	.inner-container {
		z-index: 1;
		overflow: hidden;
		border-radius: 0.1em;
		background: @panel-background;
		border: 0.1em solid #59677c;
		box-shadow: 0.1em 0.1em 0.05em 0.05em rgba(0,0,0,0.5);


		.inner {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0.5em;

			.avatar-container {
				align-self: center;
				position: relative;
				width: 4.7em;
				height: 4.7em;
				border-color: #59677c;
			}

			.username {
				width: 100%;
				margin-top: 0.5em;
				text-align: left;
				min-height: 2em;

				span {
					display: inline-block;
					width: 100%;
					line-height: 1.2;
					color: @font-color-title;
					font-family: 'SourceSansPro-Bold', sans-serif;
					font-size: 0.8em;
					letter-spacing: 0.03em;
					word-break: break-word;
				}
			}

			.rank {
				font-size: 0.7em;
				color: #c3c9d5;
			}
		}
	}
}
