.my-profile-social {
	height: 100%;
	padding-left: 2.8em;

	.top-container {
		display: flex;
		justify-content: flex-end;
		padding-right: 2.8em;
		width: 100%;

		.social-buttons-container {
			flex: 1;
			margin: 0.2em 0;

			.common-button {
				margin-left: 1em;
			}
		}
	}

	.player-search{
		height: calc(100% - 3em);

		.tile-user-component{
			height: 10em;
			width: 9em;
		}
	}
}
