.xbox-crossplay-only-popup-container {
	width: 35em;
}
.xbox-crossplay-only-popup {
	display: flex;
	padding-top: 1em;
	&__col {
		width: 50%;
	}
	&__img {
		height: auto;
		width: 9em;
	}
	&__paragraph {
		color: #A0A0A0;
		font-family: Source Sans Pro;
		font-size: 1em;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 1.2em;
		text-align: left;
	}
	&__btn {
		font-family: Source Sans Pro;
	}
}
