@import '../../../assets/styles/variables';

.instructions {
	align-self: flex-start;
	display: flex;
	height: 100%;

	.content-left {
		position:relative;
		width: 50%;
		max-height: calc(100% - 1em);

		background: #1C3554;
		border-radius: 0 0 0.15em 0.15em;
		box-shadow: 0 0 0.5em 0.15em rgba(0,0,0,0.3);

		&.active {
			background: #214167;
		}
	}

	.side-info {
		position:relative;
		width: 36%;
		max-height: calc(100% - 1em);

		margin-left: 1em;
		background: @panel-background;
		border-radius: 0 0 0.15em 0.15em;
		box-shadow: 0 0 0.5em 0.15em rgba(0,0,0,0.3);

		&.active {
			background: @panel-background-active;
		}
	}
}