.slide-out-in-animation-component {
	&.slide-out-in.slide-out {
		animation: 0.0001s slide-out-in-animation-out;
	}

	&.slide-out-in.slide-in {
		animation: 0.4s slide-out-in-animation-in;
	}

	@keyframes slide-out-in-animation-out {
		0% {
			opacity: 1;
			transform: translateX(0);
		}
		100% {
			opacity: 0;
			transform: translateX(-500px);
		}
	}


	@keyframes slide-out-in-animation-in {
		0% {
			opacity: 0;
			transform: translateX(800px);
		}
		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
}
