@import '../../assets/styles/variables';

#root {
	.main {
		&.atari {
			background: #000000;

			.header {
				.nav-item-component {
					.badge {
						.title {
							color: #E51B24;
						}
					}

					&.atari {
						.nav-image {
							transform: none;
							padding: 0;
						}

						.button-title {
							transform: none;
							opacity: 0;
						}

						&.active {
							.nav-image {
								padding: 0.3em 0.1em 0.2em 0.2em;
							}
						}
					}

					.nav-image {
						.icon-color {
							fill: #D4B1B1;
						}
					}


					.button-title {
						color: white;
					}

					&.selected, &:hover {
						.background {
							border-color: @font-color-outline-yellow;
						}

						.badge {
							.title {
								color: white;
								background: @font-color-outline-yellow;
							}
						}
					}

					&.active {

						.nav-image {
							.icon-color {
								fill: white;
							}
						}
					}

					&.homeIcon {
						.nav-image {
							transform: none;
							padding: 0.65em 0.8em 0.65em 0.55em;
						}

						.button-title {
							transform: none;
							opacity: 0;
						}

						&.active {
							.nav-image {
								padding: 0.2em 0.2em 0.2em 0.1em;
							}
						}
					}
				}
			}

			&.special-background {
				background-image: url(../../assets/images/global/special-background-atari.png);
			}
		}
	}

	&.background_hidden {
		.main {
			&.atari {
				background: transparent;
			}
		}
	}
}
