@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.tournament-result-popup-component {
	position: relative;
	width: 40em;
	padding: 1.2em 1em 1em;

	@keyframes slide-fade-in {
		100% {
			transform: translate(0%, 0%);
			opacity: 1;
		}
	}

	@keyframes slide-fade-in-delayed-bounce {
		0% {
			transform: translateX(100%) scale(1);
			opacity: 0;
		}
		25% {
			transform: translateX(0) scale(1);
			opacity: 1;
		}
		60% {
			transform: translateX(0) scale(1);
			opacity: 1;
		}
		70% {
			transform: scale(1.1);
			opacity: 1;
		}
		80% {
			transform: scale(1);
			opacity: 1;
		}
		90% {
			transform: scale(1.1);
			opacity: 1;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

	.slide-fade-in {
		opacity: 0;
		transform: translateX(100%);
		animation: slide-fade-in 0.5s forwards;
		animation-delay: 0.5s;
	}

	.slide-fade-in-top {
		opacity: 0;
		transform: translateY(-100%);
		animation: slide-fade-in 0.5s forwards;
	}

	.slide-fade-in-delayed {
		opacity: 0;
		transform: translateX(100%);
		animation: slide-fade-in 0.5s forwards;
		animation-delay: 1s;
	}

	.slide-fade-in-delayed-2 {
		opacity: 0;
		transform: translateX(100%);
		animation: slide-fade-in 0.5s forwards;
		animation-delay: 2s;
	}

	&:before {
		.popup-background-mixing;
	}

	.gained-gems {
		text-align: center;
	}

	.content-container{
		display: flex;
		flex-direction: row;

		.image-container{
			width: 50%;
			padding-right: 1em;
			padding-left: 1.4em;

			>img{
				display: block;
				border: 0 solid;
				border-radius: 4em 0 0 0;
				width: 100%;
				height: auto;
			}

			.no-border-radius {
				border-radius: 0;
			}

			>svg{
				width: 100%;
				height: auto;
			}
		}
		.result-container{
			width: 50%;

			>h1{
				font-size: 2.1em;
				margin-bottom: 0.2em;
			}

			.reward-container{
				color: white;
				font-family: 'LatoBold', sans-serif;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			&.RESULT_RANK {
				.reward-container{
					.reward-detail {
						margin: 1.4em auto;

						.leaderboard-position-component{
							font-size: 1.8em;
						}
					}

					.score-label{
						font-size: 1em;
					}
					.score-text{
						font-size: 1.9em;
					}
					.score-content{
						font-size: 1em;
					}
				}
			}

			&.RESULT_GEM {
				.reward-container{
					.reward-detail {
						padding: 2.5em 0;

						.trophy-icon {
							position: relative;
							background: url("../../../assets/images/global/icon-challenge-trophy-gems.png") no-repeat;
							background-size: contain;
							height: 5.5em;
							width: 8.3em;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: flex-end;
							z-index: 1;
							margin: 0 auto;

							&-score {
								font-family: 'LatoBold';
								font-size: 1em;
								padding-bottom: 0.85em;
								color: #624C14;
							}
						}
					}
				}
			}

			&.SESSION_RESULT_RANK {
				position: relative;
				>h3{
					margin-bottom: 0.1em;
				}
				>p{
					line-height: 1.2em;
					margin-bottom: 0;

					height: 5em;
				}

				.reward-container{
					position: absolute;
					bottom: -0.35em;

					.reward-detail {
						.icon {
							height: 3em;
						}

						.position-container {
							margin-top: 0.9em;
							margin-left: 0.5em;
						}
					}

					.score-label{

						padding-bottom: 0.7em;

						span {
							margin: 0 0.3em;
							font-size: 1.5em;
						}
					}
					.score-text{
						font-size: 2.4em;
					}
					.score-content{
						padding-top: 0.3em;
					}
				}
			}

			&.RESULT_ACHIEVEMENT {
				position: relative;
				>h1{
					line-height: 1em;
				}
				>h3{
					margin-bottom: 0;
					line-height: 1.2em;
				}

				.reward-container{
					.reward-detail {
						margin: 1.7em 0;
						.icon {
							display: block;
							margin: 0 auto;
							width: 8em;
							opacity: 0;
							transform: translateX(100%);
							animation: 2s linear 0.5s 1 forwards slide-fade-in-delayed-bounce;
						}
					}
				}

				>p{
					color: white;
					font-size: 0.9em;
					margin: 0;
				}
			}

			&.HOMEPAGE_INFO {
				position: relative;
				>h1{
					line-height: 1em;
				}
				>h3{
					margin-bottom: 0;
					line-height: 1.35em;
					.pro-tip-gem-image{
						height: auto;
						width: 1.1em;
						margin-bottom: -0.3em;
					}
				}
				.pro-tip-container{
					position: absolute;
					width: 100%;
					bottom: 0;
					.pro-tip-title{
						font-weight: bold;
						line-height: 1.1em;
					}
					.pro-tip{
						line-height: 1.1em;
					}
				}
			}
		}
	}

	.buttons-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding-right: 1.5em;
		padding-top: 0.6em;

		.common-button {
			width: 25%;

			&:first-of-type {
				margin-right: 1em;
			}
		}
	}
}