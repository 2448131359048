@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';


.tile-game {
	margin: 0.5em;
	height: 5em;
	width: 5em;
	//transition: transform 400ms ;
	//box-shadow: 0.05em 0.05em 0.05em 0.05em rgba(0,0,0,0.5);
	cursor: pointer;
	background: url(../../../assets//images/global/tile_loading.png) no-repeat;
	background-size: 100%;
	border-radius: 0.7em;

	//Fixes chromium v54(waysun) image issue
	.placeholder {
		min-width: 1px;
		min-height: 1px;
	}

	&.tile__search_shortcut {
		background: none;
	}

	&.selected {
		transform: scale(1.2);

		.pulsar-component {
			display: block;

			.front-border {
				background: url(../../../assets/images/generic-icons/play-icon.png) no-repeat 50% 50%;
				background-size: 50%;
				border-radius: 0.7em;
			}

			.back-border {
				border-radius: 0.7em;
			}
		}
	}

	&.selected-nogame {
		transform: scale(1.2);

		.pulsar-component {
			display: block;
		}
	}

	.tile__img {
		width: 100%;
		height: 100%;

		.game-image {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 0.7em;
		}
	}

	.game-title {
		margin: 0.5em;
		color: @font-color-title;
		font-size: 0.9em;
		font-family: 'Quantify-Bold';
		position: absolute;
		width: 5em;
		text-overflow: ellipsis;
		vertical-align: middle;
		display: flex;
		align-items: center;
		align-content: center;
		height: 5em;
		justify-content: center;
		text-align: center;
	}

	.tile__platform {
		margin-left: 60%;
		margin-top: -40%;
		height: 40%;
		width: 40%;

		.platform-image {
			width: 100%;
			height: 100%;
			border-bottom-right-radius: 0.7em;
		}


	}
	.fade-in-div {
		height: 100%;
		opacity: 0;
		transition: opacity 0.5s ease-in-out; /* Adjust the duration of the fade-in */
	}
	
	/* Class to make the div visible */
	.fade-in-div.loaded {
		opacity: 1;
	}

}