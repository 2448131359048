@import '../../../assets/styles/variables';

section.pre-challenge {
	height: 85%;
	padding-left: 2em;

	.invite-container{
		display: flex;
		align-items: center;
		color: @font-color;
		margin: 0.8em 0 0;

		.common-button {
			margin-left: 1em;
		}
	}

	 .title-box {
		 height: 2em;
	 }

	.player-search {
		height: calc(100% - 5em);
	}

}
