.my-profile {
	height: 85%;
	overflow: hidden;

	.my-profile-title {
		font-size: 2em;
		margin-bottom: 0;
		margin-top: 0.2em;
	}

	.tab-menu-component {
		height: 100%;

		.item-container {
			padding-left: 2.8em;
		}
	}
}