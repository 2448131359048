@import "../../../assets/styles/variables";

.test-connection-item {
	width: 40em;
	display: flex;
	flex-direction: row;

	.left-container {
		color: @emojiBlue;
		font-family: 'Quantify-Bold';
		width: 7em;
		line-height: 2em;
		margin-right: 2em;
		padding: 0.5em 0;
	}

	.right-container {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 30em;
		padding: 0.5em 0;

		.bar {
			height: 1.3em;
			text-align: right;
			padding-right: 1em;
			line-height: 1.3em;
			width: 14em;
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiOrange 20%);

			.value {
				color: black;
				font-size: 0.9em;
			}

			&.POOR {
				//width: 8em;
				animation: test-connection-item-poor-animation 2s;
				animation-fill-mode: forwards;
			}
			&.OK {
				//width: 16em;
				//background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiOrange 20%);
			}
			&.GOOD {
				//width: 24em;
				animation: test-connection-item-good-animation 2s;
				animation-fill-mode: forwards;
			}
			&.EXCELLENT {
				//width: 32em;
				animation: test-connection-item-excellent-animation 2s;
				animation-fill-mode: forwards;
			}

			&.withoutBar {
				text-align: left;
				width: auto;
				background:none;
				.value {
					color:@font-color;
				}
				animation: none;
			}
		}

		.emoji {
			background-color: black;
			margin-left: -0.70em;
			height: 1.7em;
			width: 1.7em;
			border: 0.1em solid black;
			border-radius: 50%;


			>img {
				height: 1.5em;
				width: 1.5em;
			}
		}
		.dots-container {
			position: absolute;
			bottom: 0.7em;
			font-size: 0.4em;
			letter-spacing: 6.3em;

			&.withoutBar {
				display: none;
			}
		}
	}

	@keyframes test-connection-item-poor-animation {
		99%{
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiOrange 20%);
			width: 7em;
		}
		100%{
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiRed 20%);
			width: 7em;
		}
	}
	@keyframes test-connection-item-good-animation {
		99%{
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiOrange 20%);
			width: 21em;
		}
		100%{
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiGreen 20%);
			width: 21em;
		}
	}
	@keyframes test-connection-item-excellent-animation {
		99%{
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiOrange 20%);
			width: 28em;
		}
		100%{
			background: linear-gradient(90deg, rgba(0,0,0,0) 0%, @emojiBlue 20%);
			width: 28em;
		}
	}
}
