.daily-challenges-history {
    position: relative;
    margin: 0 0 0 auto;
    display: flex;
    width: 10em;
    height: 100%;

    &__last-week {
        position: absolute;
        bottom: 4em;
        right: 0.5em;
        color: #7E1FDB;
        font-family: 'Quantify', sans-serif;
    }
    &__last-week-text {
        max-width: 6em;
        text-align: center;
    }
    &__last-week-position {
        font-size: 2em;
        text-align: center;
    }

    .countdown-component {
        font-family: 'Quantify', sans-serif;
        left: auto;
        right: 1em;
        width: 5em;
        height: 5em;
    }
    .countdown-title {
        font-family: 'Quantify', sans-serif;
        font-size: 2em;
    }
}