@import '../../../assets/styles/variables';

.message-mpc-result {
    width: 100%;
	cursor: pointer;

	.mp-challenge {
		&.win .gradient-top-border,
		&.loss .gradient-top-border,
		&.draw .gradient-top-border {
			border-radius: 0.15em 0.15em 0 0;
		}

		&.win {
			.gradient-top-border:after {
				background-image: linear-gradient(to left, #1e88e5, #14ccf8);
			}
			.result-message {
				background-image: linear-gradient(to bottom, #1e88e5, #14ccf8);
			}
		}
		&.loss {
			.gradient-top-border:after {
				background-image: linear-gradient(to left, #d62b2b, #ad2323);
			}
			.result-message {
				background-image: linear-gradient(to top, #ea4c4c, #ad2323);
			}
		}

		&.draw {
			.gradient-top-border:after {
				background: #9098ae;
			}
			.result-message {
				background: #9098ae;
			}
		}

		&.failed {
			.score-label {
				display: none;
			}
		}

		.score-label {
			margin-bottom: 0;
		}

		.content-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 0 1em;

			.left {
				color: @font-color-title;
				text-align: left;

				h3 {
					font-size: 1.5em;
				}
				p {
					margin: 0;
				}

				.number {
					font-family: 'Quantify-Bold';
					font-size: 4em;
					letter-spacing: 0.05em;
				}
			}

			.right {
				display: flex;
				align-items: flex-end;
				width: 6em;

				img.badge {
					width: 100%;
				}

				.avatar-container {
					left: 0.15em;
					width: 6em;
					height: 6em;
				}
			}
		}

		.result-message {
			padding: 0 1em;
			border-radius: 0 0 0.15em 0.15em;
			text-align: left;
			color: @font-color-title;

			p {
				margin: 0;
				padding: 0.2em 0 0.3em;
			}

			img {
				height: 1.5em;
				vertical-align: middle;
			}
		}
	}
}
