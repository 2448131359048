.header {
    display: flex;
    flex-direction: row;
	align-items: center;
	justify-content: space-between;
    position: relative;
    width: 100%;
    height: 15%;
    background: url(../../assets/images/global/antstream-logo-free.png) 1em calc(50%) no-repeat;
    background-size: 2.8em;

    &.free {
        background: url(../../assets/images/global/antstream-logo-free.png) 1em calc(50%) no-repeat;
        background-size: 2.8em;
    }
    &.freetrial {
        background: url(../../assets/images/global/antstream-logo-freetrial.png) 1em calc(50%) no-repeat;
        background-size: 2.8em;
    }
    &.premium{
        background: url(../../assets/images/global/antstream-logo-premium.png) 1em calc(50%) no-repeat;
        background-size: 2.8em;
    }
    &.lifetime {
        background: url(../../assets/images/global/antstream-logo-lifetime.png) 1em calc(50%) no-repeat;
        background-size: 2.8em;
    }
}
