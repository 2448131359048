@import "../../../assets/styles/variables";

.player-profile-header-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	.left-container{
		position: relative;
		z-index: 3;
		max-width: 8em;
		padding-bottom: 0.6em;

		.gem-rank-container{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-right: -0.8em;
			position: relative;
			.rank-text{
				margin-top: 0.6em;
				color: #e8c324;
			}
			.rank-icon{
				margin-top: 0.6em;
				height: 1.5em;
				width: 1.5em;
			}
		}
	}

	.display-name{
		position: absolute;
		z-index: 1;
		right: 0;
		top: 3.3em;
		width: 3.7em;
		background-color: #0072ff;
		border-radius: 0.5em;
		padding: 0.1em;

		.display-name-inner {
			font-family: "Quantify-Bold", sans-serif;
			font-weight: normal;
			font-size: 0.5em;
			color: #ffffff;
			text-align: center;
			word-break: break-word;

			.xbox-platform & {
				font-family: sans-serif;
			}
		}
	}
}
