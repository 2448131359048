.payment-selection {
	width: 100%;
	text-align: center;
	padding: 0 10%;

	position: relative;
	top:50%;
	transform: translateY(-50%);

	.logo {
		height: auto;
		width: 10em;
		margin: 0.5em;
	};

	.offerText{
		line-height: 1.12;
		margin-bottom: 0.5em;
		color: #efefef;
		text-shadow: 0 0 0.25em #14ccf8;
		text-align: left;
		font-size: 1em;
		font-family: 'Quantify-Bold';
		letter-spacing: 0.065em;
		user-select: none;
		text-align: center;
		};

	>h1 {
		line-height: 1;
		text-align: center;
		margin: 0.1em 0;
	}

	>h3 {
		font-size:1.7em;
	}

	>p {
		font-size: 1em;
		margin: 0.5em 0;
	}

	.plans-container {
		margin: 0.8em auto;
	}

	.buttons-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		.common-button {
			width: 10em;
			margin-right: 1em;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}