@import "variables";

html, body {
	touch-action: manipulation;
	-ms-touch-action: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

/* remove this after refactor all ul li usages*/
ol, ul {
	list-style: none;
}

* {
	box-sizing: border-box;
	outline: @font-color-outline-yellow;
	-webkit-tap-highlight-color: transparent;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

*::-webkit-scrollbar {
	visibility: hidden;
	height: 0;
	width: 0;
	background: transparent; /* make scrollbar transparent */
}

//*::-webkit-scrollbar-track {
//	width: 0;
//	background: transparent; /* make scrollbar transparent */
//}
//*::-webkit-scrollbar-thumb {
//	width: 0;
//	background: transparent; /* make scrollbar transparent */
//}


#root {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background: @background;
	position: relative;
	z-index: -2;

	&.background_hidden {
		background: transparent;
	}
}

@supports (font-feature-settings: liga) {
	body {
		-webkit-font-feature-settings: "liga" 0, "clig" 0;
		-moz-font-feature-settings: "liga" 0, "clig" 0;
		font-feature-settings: "liga" 0, "clig" 0;
	}
}

body {
	position: fixed; // necessary to prevent bouncing scroll of whole page in iOS PWA ("Add to Home Screen")
	line-height: 1;
	color: @font-color;
	font-family: "Lato", sans-serif;

	zoom: 1.0;
	overflow: hidden;
	height: 100vh;
	width: 100vw;

	font-size: 13px;
	font-size: 1.667vw;
	@media screen and (min-width: 1023px) {
		font-size: 17px;
		font-size: 1.667vw;
	}
	@media screen and (min-width: 1279px) {
		font-size: 21px;
		font-size: 1.667vw;
	}
	@media screen and (min-width: 1439px) {
		font-size: 24px;
		font-size: 1.667vw;
	}
	@media screen and (min-width: 1919px) {
		font-size: 32px;
		font-size: 1.667vw;
	}

		// This is to handle iPhones with the 'notch'
		&.iPhoneX {
			#root {
				padding: 0.1em;
	
				.homepage-component,
				.daily-challenges,
				.search-component,
				.my-profile,
				.settings-component,
				.achievements-component,
				.tournaments-info-component,
				.tournaments-participants {
					padding: env(safe-area-inset-top) @iphonex-padding-right env(safe-area-inset-bottom) env(safe-area-inset-left);
					margin-top: 0.2em;
				}

				.information-banner {
					margin-right: calc(@iphonex-padding-right + env(safe-area-inset-left));
				}
	
				.game-details-component .content-with-bg-full-screen {
					padding: 0 0 0 env(safe-area-inset-left);
				}

	
				.game-details-info-component .image-container.hasLegalNotices {
					height: 20em;
					width: 20em;
				}
	
				.hero-banner {
					width: calc(100vw - constant(safe-area-inset-left) - constant(safe-area-inset-right));
					width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
				}
	
				//.largeSticker {
				//	right: calc(env(safe-area-inset-right) + env(safe-area-inset-right));
				//	//bottom: calc(env(safe-area-inset-bottom));
				//}
			}
		}

	.base64-container {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -10;
		width: 500px;
		height: 0;
		overflow: hidden;
		pointer-events: none;
	}
}

a {
	display: inline-block;
	color: @font-color-text-shadow;
}

h1 {
	line-height: 1.12;
	margin-bottom: 0.5em;
	color: @font-color-title;
	text-shadow: 0 0 0.25em @font-color-text-shadow;
	text-align: left;
	font-size: 2.25em;
	font-family: 'Quantify-Bold';
	letter-spacing: 0.065em;
	user-select: none;
}

h2 {
	line-height: 1.4;
	margin-bottom: 0.3em;
	color: @font-color-title;
	text-align: left;
	font-size: 1.5em;
	letter-spacing: 0.04em;
	user-select: none;
}

h3 {
	margin-bottom: 0.67em;
	color: @font-color-title;
	font-size: 1em;
	user-select: none;
}

p {
	line-height: 1.5;
	margin-bottom: 1em;
	font-family: 'SourceSansPro-Regular', sans-serif;
	font-size: 1em;
	font-weight: 300;
	user-select: none;
}

p.white,
span.white {
	color: @font-color-title;
}

img {
	font-family: 'SourceSansPro-Regular', sans-serif; /* for alt texts */
}

section {
	animation: fade-in 0.5s ease-in;

	&.fade-out {
		animation: fade-out 0.5s ease-in;
		opacity: 0;
	}
}


.pwa-install-popup-ios {
	color: #000000;
	font-size: initial;

	.left {
		display: flex;
		justify-content: center;
		align-items: center;

		.appIcon {
			width: 100%;
			height: 100% !important;
		}
	}
	.safari-share-icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../assets/images/global/safari-share.png) 50% 50% no-repeat;
		background-size: contain;

	}
}