.iconBlock {
    padding: 0 0.2em;
    color: #efefef;
    font-family: 'Quantify-Bold';
    height: 2.2em;
    text-align: center;
}
.iconLargeText {
    font-size: 1.8em;
}
.iconImage {
    height: 1.6em;
    margin: 0 auto;
}
.iconText {
    text-transform: uppercase;
    font-size: 0.5em;
}