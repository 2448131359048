@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';


.tile-user-component {
	transition: transform 500ms;
	margin: 0.5em;

	&.selected {
		.pulsar-component {
			display: block;
		}

		transform: scale(1.1);

		.user-details {
			background: @panel-background-selected;
		}
	}


	.user-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		width: 100%;
		background: @panel-background;
		border: 0.1em solid #59677c;
		padding: 1em 0.5em;

		.avatar-container {
			width: 4.7em;
			height: 4.7em;
		}

		.username {
			width: 100%;
			margin-top: 0.5em;
			text-align: left;
			overflow: hidden;
			word-break: break-word;

			span {
				display: inline-block;
				width: 100%;
				line-height: 1;

				min-height: 2.7em;

				color: @font-color-title;
				font-family: 'SourceSansPro-Bold', sans-serif;
				letter-spacing: 0.06em;
				font-size: 0.9em;
			}
		}

		.rank {
			overflow: hidden;
			width: 100%;
			text-align: left;
			color: #c3c9d5;
		}
	}
}
