.pwa-popup-android-overlay {
	position: fixed;
	z-index: 998;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.pwa-popup-android {
	position: fixed;
	z-index: 999;
	top: 20px;
	left: 5%;
	width: 90%;
	border-radius: 0.4em;
	margin: auto;
	transform: translatey(0px);
	background-color: #ffffff;
	color: #000000;
	font-size: initial;

	// arrow pointing top-right
	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		bottom: auto;
		top: -5px;
		left: 50%;
		width: 0;
		height: 0;
		border: 20px solid transparent;
		border-bottom-color: #ffffff;
		border-top: 0;
		border-right: 0;
		margin-left: 40%;
		margin-top: -10px;
		margin-bottom: auto;
	}

	.pwa-popup-android-content {
		padding: 15px;
		display: flex;
		justify-content: row;

		.left {
			flex: 1;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			img.appIcon {
				width: 100%;
				height: 100%;
			}
		}

		.right {
			flex: 3;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;

			.chrome-menu-icon {
				display: inline-block;
				width: 20px;
				height: 20px;
				background: url(../../assets/images/global/chrome-menu-icon.png) 50% 50% no-repeat;
				background-size: contain;
			}
		}

	}

}
