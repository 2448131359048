@import '../../../assets/styles/variables';

.tab-menu-item-component {
	position: relative;
	margin: 0 0.3em;
	border-bottom: 0.08em solid #3d485a;
	cursor: pointer;
	letter-spacing: 0.1em;
	padding: 0 0 0.4em;
	
	&-large-text {
		font-size: 1em;
	}
	&-medium-text {
		font-size: 0.8em;
	}
	&-small-text {
		font-size: 0.6em;
	}

	&:first-of-type{
		margin-left: 0;
	}

	.text-container{
		text-align: center;
		display: block;
	}

	&.active {
		padding: 0 0.8em 0.4em;
		.text-container{
			transform: scale(1.2) translateY(-0.1em);
		}
		border-width: 0 0 0.1em 0;
		border-image-source: linear-gradient(to left, #1e88e5, #14ccf8);
		border-image-slice: 1;
		color: @font-color-button;
	}

	&.selected {
		border-bottom: 0.1em solid @font-color-outline-yellow;
		border-image-source: none;
	}
}