@import '../../assets/styles/variables';

.webrtc-game-component {
  background-color: #010b13;

  .how-to-play {
    height: 100vh;
  }

  .in-game-menu-component {
    position: absolute;
    z-index: 101;
    .safe-area-turned-on & {
      top: 0;
      left: 0;
    }
  }

  .control-menu-component {
    position: absolute;
    z-index: 101;
    .safe-area-turned-on & {
      top: 0;
      left: 0;
    }
  }

  .button {
    cursor: pointer;
  }

  .loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black
  }

  .sponsorBanner {
    position: absolute;
    height: 100%;
    box-sizing: border-box;

    //background-size: contain;
    background-size: auto 100%; // Make banners height always be the same height as the game window, it can be cropped if the size doesn’t fit

    background-position-y: center;
    background-repeat: no-repeat;

    &--left {
      background-position-x: right;
    }
    &--right {
      background-position-x: left;
    }
  }

  .remote-video {
    display: block;
    height: 0px;
    width: 0px;
    position:absolute;

    object-fit: fill;
    image-rendering: pixelated;

  }

  .start-video-container {
    position: relative;
    z-index: 102;
    height: 100vh;
    background-color: black;

    #start-video-button {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%) skew(-10deg);
      transform: translate(-50%, -50%) skew(-10deg);

      width: 10em;

      text-align: center;
      color: @font-color-button;
      text-shadow: 0.05em 0.05em 0.05em rgba(0,0,0,0.1);
      text-transform: uppercase;

      background: #005BA5;
      box-shadow: 0.1em 0.1em 1em rgba(0,0,0,.5);
      border: 0.15em solid #EFD97B;
      border-radius: 0.3em;

      cursor: pointer;

      .content {
        transform: skew(10deg);
        padding: 1em;
      }
    }
  }
}
