.tournaments-component {
    height: 85%;
	position: relative;
	min-height: 85vh;

	.safe-area-turned-on & {
		min-height: auto;
	}

	h1 {
		margin-bottom: 0;
		padding-left: .7em;
	}

	.tab-menu-component {
		height: calc(100% - 2em);

		.item-container {
			padding-left: 2em;
		}
	}
}
