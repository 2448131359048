@import '../../../assets/styles/variables';

.how-to-play-controller-mapping {
	display: flex;
	flex-flow: row;
	overflow-y: scroll;
	.left-column {
		max-width: 17em;
		overflow-y: auto;
		padding: 0 1em;
		width: 100%;
	}
	.controls {
		max-width: 16em;
		padding: 0 1em;
		width: 100%;
	}
	.axis-controls {
		padding: 0 1em;
		width: 100%;
		.common-button {
			margin-bottom: 1em;
			font-size: 0.8em;
			//.content {
			//	overflow: hidden;
			//	text-overflow: ellipsis;
			//	white-space: nowrap;
			//}
		}
	}
	.drop-down-component {
		margin-bottom: 1em;
		font-size: 0.8em;
		//.drop-down-content {
		//	overflow: hidden;
		//	text-overflow: ellipsis;
		//	white-space: nowrap;
		//}
	}
	.controls-image-svg {
		top: 0em !important;
	}
	.children {
		position: relative;
		width: 100%;
		height: 14em;
		padding-right: 1em;
	}
}