@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.save-button {
	position: relative;

	width: 6em;
	height: 4.5em;
	margin: 0 0.3em;

	background: @panel-background;
	border: solid 0;
	border-radius: 1em 0 1em 0;

	font-size: 1em;
	user-select: none;
	cursor: pointer;

	overflow: hidden;
	will-change: transform;

	&:first-of-type{
		margin-left: 0;
	}

	.background {
		display: block;
		position: absolute;
		top: 50%;
		left: -1%;
		transform: translateY(-50%);
		height: auto;
		min-height: 100%;
		width: 102%;
		z-index: 0;
		opacity: 0.5;
	}

	.content {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		z-index: 1;

		.title{
			font-size: 1.2em;
			font-family: 'Quantify-Bold';
			color: #C4C4C4;
			-webkit-text-stroke: 0.05em #1C3554;
		}
	}

	.border {
		display: none;
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		border: solid 0.1em @font-color-outline-yellow;
		border-radius: 1em 0 1em 0;
	}

	.premium-overlay {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);

		.premium-benefits {
			position: absolute;
			left: 0;
			top: 0;
			height: 1.3em;
			width: 100%;
			background: @premium-benefit-gradient;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;

			.premium-benefits-text {
				font-size: 0.5em;
				text-shadow: none;
				padding-right: 1.5em;

				background: url(../../../assets/images/generic-icons/premium-diamond.svg) 100% 50% no-repeat;
				background-size: contain;
			}
		}
	}


	&.disabled {
		cursor: default;

		.content {
			align-items: center;
			justify-content: center;
		}
	}

	&.empty {
		.content {
			align-items: center;
			justify-content: center;
		}
	}

	&.selected {
		background: @background-button-secondary-selected;
		transform: scale(1.2);
		.background{
			opacity: 1;
		}
		.border {
			display: block;
		}
		.content {
			.title{
				color: #C4C4C4;
			}
		}
	}

	&.bounce-animation {
		animation: save-button-bounce-frames 150ms;
	}

	@keyframes save-button-bounce-frames {
		0% { transform: scale(1); }
		50% { transform: scale(0.9); }
		100% { transform: scale(1); }
	}
}
