.notifications {
    display: none;
    opacity: 1;
    position: absolute;
    z-index: 1000;
    right: 1em;
    top: 0.5em;
    padding: 0.6em 1em 0.7em;
    background: linear-gradient(135deg, #0087f2 0%, #023177 100%);
    border-radius: 0.15em;
    box-shadow: 0 0.2em 1.5em 0.3em rgba(0,0,0,0.4);
    color: white;
    cursor: pointer;
    transform: skew(-10deg);
    font-family: 'SourceSansPro-Regular', sans-serif;
    line-height: 1.5;

    &.animation {
      -webkit-animation: slide-in-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-in-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .close-button {
      position: absolute;
      top: -1em;
      right: -1.5em;
      width: 1.5em;
      height: 1.5em;
      background: url(../../assets/images/generic-icons/close-icon.svg) 50% 50% no-repeat;
      background-size: contain;
      border-radius: 1em;

      &.selected {
        box-shadow: 0 0 0 0.1em #EFD97B;
      }
    }


/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              transform: skew(-10deg);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              transform: skew(-10deg);
      opacity: 1;
    }
  }
  
  
	&.visible {
		display: block;
	}

	.content {
		transform: skew(10deg);

		h2 {
			margin-bottom: 0;
			font-family: "Lato", sans-serif;
		}
	}
}
