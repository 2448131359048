.payment-box {
    width: 10em;
    max-height: 10em;
    background: #0087F2;
    border-radius: 0.5em;
    color: #FFFFFF;
    text-align: center;

    .title {
        padding-top: 1em;
    }

    .extraTitle {
        padding: 1em 0;
    }

    .title, .extraTitle {
        font-size: 0.65em;
        font-weight: bold;
    }

    .sub-title {
        padding-top: 0.1em;
        font-size: 0.8em;
    }

    .currency {
        padding-top: 0.5em;
        .amount {
            font-family: 'Quantify-Bold', serif;
            padding-top: 0.3em;
            text-align: center;
            font-size: 3em;
            color: #FFFFFF;
            text-shadow: 0 0 0.3em #14CCF8;
        }
        .icon {
            font-size: 2em;
        }
    }
 }