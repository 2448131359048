@import '../../../assets/styles/variables';

.game-details-info-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	width: 100%;
	height: 100%;
	align-items: stretch;
	.content-left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		width: 50%;
		align-items: stretch;
		min-height: 100%;
		animation: 0.25s game-details-drop ease-in-out;

		.content-top {

			.buttons-container {
				display: flex;
				align-content: center;

				&.show-premium-benefits {
					padding-top: 1.3em;
				}

				.common-button {
					height: 3em;
					width: 9em;
					margin-left: 0.3em;

					&.favourite {
						height: 3em;
						width: 2.7em;
						margin-right: 0;
					}

					.react-bodymovin-container {
						margin-left: 30%;
						width: 40%;
					}
				}

				.total-votes {
					overflow: hidden;
					padding-left: 2em;
					line-height: 3em;
				}
			}

			.save-game-text {
				margin-top: 0.5em;
				font-size: 1.5em;
				color: @font-color-title;
				text-shadow: 0 0 0.5em @font-color-text-shadow;
			}
		}

		.content-middle{
			margin: 0.5em 0;
			.save-buttons-container{
				display: flex;

				.save-button.empty .content{
					align-items: flex-end;
					justify-content: flex-start;
				}
			}
		}

		.content-bottom {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			padding-bottom: 1.5em;

			.buttons-container {
				p.format {
					margin-bottom: 0.5em;
				}

				.common-button{
					&:first-of-type {
						margin-left: 0.2em;
					}
				}
			}

			.logo-year-genre {
				display: inline-flex;
				justify-content: space-around;
				align-items: flex-end;
				margin-bottom: 0.2em;
				margin-left: 1em;
				width: 70%;


				.publisher{
					max-width: 11em;
				}

				.publisher,
				.year,
				.genre {
					max-height: 2.2em;
				}

				p {
					margin-bottom: 0;
					letter-spacing: 0.05em;
					font-family: 'SourceSansPro-Bold', sans-serif;
					text-shadow: 0 1px 1px rgba(0,0,0,0.5);
				}

				.multiplayerIcon,
				.germanyAgeIcon,
				.highScoreIcon{
					max-height: 2em;
					height: 2em;
				}
			}
		}
	}

	.image-container {
		position: absolute;
		bottom: 1.5em;
		right: 2.8em;
		background: black;
		border: solid 0.1em #32395d;
		transform-origin: left 50%;
		height: 22em;
		width: 22em;

		.safe-area-turned-on & {
			height: 20em;
			width: 20em;
		}

		.gameDetailsAnimationEnabled & {
			animation: 0.4s game-info-grow ease-out;
		}

		&.hasLegalNotices {
			bottom: 3.5em;
		}
		.legal-notices {
			position: absolute;
			bottom: -3em;
			margin-bottom: 0;
			text-align: right;
			width: 100%;
			font-size: 0.8em;
			max-height: 3em;
		}
		img {
			position: absolute;
			opacity: 0;
			height: 100%;
			width: 100%;
			.gameDetailsAnimationEnabled & {
				transition: opacity 0.6s ease-in-out;
			}

			&.two,
			&.three {
				.gameDetailsAnimationEnabled & {
					animation: 0.1s game-info-screenshots-display ease-in-out 0.8s;
				}
			}
		}

		&.one img.one,
		&.two img.two,
		&.three img.three {
			opacity: 1 !important;
			z-index: 10;
		}
	}

	@keyframes game-info-grow {
		0% {
			opacity: 0;
			transform: scale(0.75);
		}
		80% {
			opacity: 1;
		}
		100% {
			transform: scale(1);
		}
	}

	@keyframes game-details-drop {
		0% {
			opacity: 0;
			transform: translateY(-1.2em);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes game-info-screenshots-display {
		0% {
			display: none;
		}
		100% {
			display: block;
		}
	}
}
