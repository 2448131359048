.error-boundary {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 1em;
    align-items: center;
    justify-content: space-between;
    .error-boundary-hero-image {
        display: flex;
        width: 35em;
        height: 5em;
        .error-boundary-hero-text {
            text-align: center;
            color: white;
            margin-bottom: 0;
            z-index: 2;
        }
    }
    
    .error-boundary-image {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .error-boundary-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .error-boundary-button {
            display: block;
            width: 10em;
            z-index: 2;
        }
        .error-boundary-information {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            color: white;
            z-index: 2;
            .error-boundary-information-row {
                display: flex;
                justify-content: space-around;
                .error-boundary-information-row-key {
                    padding-right: 1em;
                }
            }
        }
    }
}





