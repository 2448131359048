.filterButton{
    display: block;
    width: 35%;
    transform: skew(0);
    font-family: 'LatoBold', sans-serif;
    font-style: normal;
    font-weight: bold;

    box-shadow: none;
    border: none;
    background: none;

    padding: 0.15em 0.1em 0 0.3em;
    text-align:left;

    .content {
        transform: skew(0);
        display: inline-flex;
        align-items: center;
        font-size: 0.9em;
        text-transform: capitalize;
        .button-icon{
            width: 2em;
            height: auto;
            padding-right: 0.5em;
        }
    }

    &.global {
        color: #3D485A;
        &.active{
            color: #1b97bf;
        }
    }

    &.xbox {
        color: #3D485A;
        &.active{
            color: #933B9B;
        }
    }
    
    &.following {
        color: #3D485A;
        &.active{
            color: #047178;
        }
    }

    &.twitch-rivals {
        color: #3D485A;
        &.active{
            color: #6441A4;
        }
    }

    &.kickstarter-button {
        color: #3D485A;
        &.active{
            color: #05CE78;
        }
    }
    &.filter-with-access-button {
        color: #3D485A;
        &.active{
            color: #79B82C;
        }
    }

    &.bounce-animation {
        animation: button-bounce-frames-leaderboard 150ms;
    }

    @keyframes button-bounce-frames-leaderboard {
        0% { transform: scale(1); }
        50% { transform: scale(0.9); }
        100% { transform: scale(1); }
    }
}