.information-banner {
    position: relative;

    .information-banner-image {
        display: block;
        width: 100%;
        height: 100%;

        .safe-area-turned-on & {
            height: 12.4em;
        }
    }

    .countdown-component {
        left: 0.5em;
        top: 0.5em;
    }

    .largeSticker {
        overflow: hidden;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 14%;
        height: 66%;
        background-size: cover;

        .safe-area-turned-on & {
            width: 8.9em;
            height: 8.5em;
        }

        &.stickerTournament {
            background-image: url(../../../../assets/images/hero-carousel/featured_banner-corner_tag-tournament.png);
        }
        &.stickerGame {
            background-image: url(../../../../assets/images/hero-carousel/featured_banner-corner_tag-game.png);
        }
        &.stickerChallenge {
            background-image: url(../../../../assets/images/hero-carousel/featured_banner-corner_tag-challenge.png);
        }

    }
}