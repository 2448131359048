.vertical-list {
    height: 100%;
    flex: 1;
	-webkit-overflow-scrolling: touch;
}

.vertical-list .pager {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
}

.vertical-list .track {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
	-webkit-transform: translate3d(0,0,0);
}

.vertical-list .tile {
    position: relative;
    z-index: 1;
    box-sizing: initial;
    text-align: right;
    transition: transform 0.15s;
	height: auto;
	width: 100%;
}

.vertical-list .avatar-container {
    height: 100%;
}

.vertical-list .tile {
	position: relative;
}